import React from "react";
import DeviceTypeBase from "./DeviceTypeBase";

export default function MokoSmartLW005(props) {
  let { isShare } = props;

  const columns = [
    {
      field: "plug_state",
      title: "Plug State",
      icon: "plug",
      iconSize: "6x",
      truefalse: true,
      trueV: "On",
      falseV: "Off",
      formatter: (value) => (value ? "On" : "Off"),
      aspect: 15,
      metricWidth: 2,
      graphWidth: 12,
      aggregationType: "hourly",
    },
    {
      field: "total_energy",
      title: "Total Energy",
      icon: "battery-full",
      iconSize: "6x",
      formatter: (value) => value,
      trimValue: true,
      trimNumber: 3,
      aspect: 3,
      metricWidth: 3,
      graphWidth: 6,
      aggregationType: "hourly",
      unit: "KWH",
    },
    {
      field: "energy_of_last_hour",
      title: "Total Energy Last Hour",
      icon: "battery-full",
      iconSize: "6x",
      formatter: (value) => value,
      trimValue: true,
      trimNumber: 3,
      aspect: 3,
      metricWidth: 3,
      graphWidth: 6,
      aggregationType: "hourly",
      unit: "KWH",
    },
    {
      field: "instantaneous_active_power",
      title: "Instantaneous Active Power",
      icon: "bolt",
      iconSize: "6x",
      formatter: (value) => value,
      trimValue: true,
      trimNumber: 1,
      aspect: 3,
      metricWidth: 2,
      graphWidth: 6,
      aggregationType: "hourly",
      unit: "W",
    },
    {
      field: "instantaneous_current",
      title: "Instantaneous Current",
      icon: "bolt",
      iconSize: "6x",
      formatter: (value) => value,
      trimValue: true,
      trimNumber: 1,
      aspect: 3,
      metricWidth: 2,
      graphWidth: 6,
      aggregationType: "hourly",
      unit: "A",
    },
  ];

  return (
    <DeviceTypeBase isShare={isShare} columns={columns} showBattery={false} />
  );
}
