import React, { useEffect } from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
  Stack,
} from "@mui/material";
import { CONFIG } from "../../config";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Launch } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import fetchData from "../../API/Fetch";
import { useSelector } from "react-redux";
import updateSnackbarMessage from "../../Functions/updateSnackbarMessage";
import { fetchDashboards } from "../../Functions/fetchDashboards";

export default function DashboardShare(props) {
  const { t } = useTranslation("common");
  const { open, handleClose, dashboard } = props;
  const [checked, setChecked] = React.useState(false);
  const isMobile = useSelector((state) => state.isMobile);

  const selectedOrganisation = useSelector(
    (state) => state.selectedOrganisation
  );

  useEffect(() => {
    if (dashboard && dashboard.unique_share_id) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [dashboard]);

  const updateSharing = async () => {
    fetchData(`/dashboard/${dashboard.id}/share`).then((res) => {
      fetchDashboards(selectedOrganisation.id);
    });
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>{t("devices.manage_sharing_title")}</DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 1 }}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  onChange={updateSharing}
                  name="checkedB"
                  color="primary"
                />
              }
              label={t("devices.enable_sharing")}
            />
          </FormGroup>
          {checked && (
            //icon button
            <Stack
              spacing={2}
              direction="row"
              justifyContent="flex-start"
              sx={{ mt: 1 }}
            >
              {!isMobile && (
                <Typography
                  variant="body1"
                  //on hover set cursor to pointer
                  sx={{ ":hover": { cursor: "pointer" } }}
                  onClick={() => {
                    navigator.clipboard
                      .writeText(
                        CONFIG.dashboardShareUrl + dashboard.unique_share_id
                      )
                      .then(() => {
                        updateSnackbarMessage(
                          CONFIG.dashboardShareUrl +
                            dashboard.unique_share_id +
                            " Copied to clipboard"
                        );
                      });
                  }}
                >
                  {CONFIG.dashboardShareUrl + dashboard.unique_share_id}
                </Typography>
              )}
              <ContentCopyIcon
                variant="body1"
                sx={{ ":hover": { cursor: "pointer" } }}
                onClick={() => {
                  navigator.clipboard
                    .writeText(
                      CONFIG.dashboardShareUrl + dashboard.unique_share_id
                    )
                    .then(() => {
                      updateSnackbarMessage(
                        CONFIG.dashboardShareUrl +
                          dashboard.unique_share_id +
                          " Copied to clipboard"
                      );
                    });
                }}
              />
              {
                // icon for opening a new window and going to the share link
              }
              <Launch
                variant="body1"
                sx={{
                  ":hover": { cursor: "pointer" },
                }}
                onClick={() => {
                  window.open(
                    CONFIG.dashboardShareUrl + dashboard.unique_share_id,
                    "_blank"
                  );
                }}
              />
            </Stack>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={handleClose}>
          {t("common.close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
