import React from "react";
import formatTemperature from "../../Functions/formatTemperature";
import { Thermostat } from "@mui/icons-material";
import { useTheme } from "@emotion/react";
import { Card, Skeleton } from "@mui/material";
import { CardContent } from "@mui/material";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import GaugeComponent from "react-gauge-component";
import AvgAndMax from "../Cards/avgAndMax";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export default function TemperatureGuague(props) {
  let tmp = "C";
  tmp = localStorage.getItem("temperature_unit");

  const deviceHistory = useSelector((state) => state.deviceHistory);

  const [value, setValue] = useState(null);
  const [loaded, setLoaded] = useState(false);

  const theme = useTheme();

  let defaultArchC = [
    {
      limit: 0,
      color: "#0061f2",
    },
    {
      limit: 10,
      color: "#00c1ff",
    },
    {
      limit: 20,
      color: "#5BE12C",
    },
    {
      limit: 30,
      color: "#F5CD19",
    },
    {
      limit: 50,
      //dark red
      color: "#EA4228",
    },
  ];

  let defaultArchF = [
    {
      limit: 0,
      color: "#0061f2",
    },
    {
      limit: 30,
      color: "#00c1ff",
    },
    {
      limit: 50,
      color: "#5BE12C",
    },
    {
      limit: 80,
      color: "#F5CD19",
    },
    {
      limit: 100,
      //dark red
      color: "#EA4228",
    },
  ];

  let defaultArch = tmp === "F" ? defaultArchF : defaultArchC;
  const { title, field = "temperature" } = props;

  useEffect(() => {
    if (!deviceHistory || deviceHistory.length === 0) {
      setLoaded(true);
      return;
    }

    try {
      const jsonData = JSON.parse(deviceHistory[deviceHistory.length - 1].data);
      let value = jsonData[field];

      //if value is an array, return first value
      if (Array.isArray(value)) {
        value = value[0];
      }

      setValue(value);
      setLoaded(true);
    } catch (error) {
      console.error("Error parsing device metric:", error);
    }
  }, [field, deviceHistory]);

  return (
    <Card
      elevation={0}
      sx={{
        height: "100%",
        background:
          theme.palette.mode === "dark"
            ? "linear-gradient(145deg, rgba(40,40,45,0.9) 0%, rgba(35,35,40,0.8) 100%)"
            : "linear-gradient(145deg, rgba(255,255,255,0.9) 0%, rgba(245,245,245,0.8) 100%)",
        backdropFilter: "blur(10px)",
        border: `1px solid ${
          theme.palette.mode === "dark"
            ? "rgba(255,255,255,0.1)"
            : "rgba(0,0,0,0.1)"
        }`,
        borderRadius: "16px",
      }}
    >
      <CardContent>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Thermostat
            sx={{
              color: theme.palette.text.secondary,
              opacity: 0.8,
            }}
          />
          <Typography
            variant="subtitle2"
            sx={{
              color: theme.palette.text.secondary,
              letterSpacing: "0.02em",
              fontWeight: 500,
              flexGrow: 1,
            }}
          >
            {title ? title : "Temperature"}
          </Typography>
          <Typography
            variant="h5"
            sx={{
              fontWeight: 600,
            }}
          >
            {formatTemperature(value)}
          </Typography>
        </Box>

        {loaded === false ? (
          <Skeleton variant="rectangular" height="100%" />
        ) : (
          <>
            <GaugeComponent
              type="radial"
              arc={{
                width: 0.2,
                padding: 0.005,
                cornerRadius: 1,
                //gradient: true,
                subArcs: defaultArch,
              }}
              pointer={{
                color: "#345243",
                length: 0.8,
                width: 15,
                elastic: true,
              }}
              value={value}
              minValue={tmp === "F" ? 0 : -20}
              maxValue={tmp === "F" ? 100 : 50}
              labels={{
                valueLabel: { formatTextValue: (value) => "" },
              }}
            />
            <Box sx={{ mt: "auto" }}>
              <AvgAndMax
                type={field}
                value={field}
                unit={formatTemperature(0, false, true)}
              />
            </Box>
          </>
        )}
      </CardContent>
    </Card>
  );
}
