import GaugeBase from "./GaugeBase";
import { Bolt } from "@mui/icons-material";
import { useTheme } from "@emotion/react";

export default function ConductivityGuague(props) {
  const minValue = 0;
  const maxValue = 100;

  const { value, title } = props;
  const theme = useTheme();

  return (
    <GaugeBase
      title={title ? title : "Conductivity"}
      field={value}
      unit="EC"
      icon={
        <Bolt
          sx={{
            color: theme.palette.text.secondary,
            opacity: 0.8,
          }}
        />
      }
      min={minValue}
      max={maxValue}
    />
  );
}
