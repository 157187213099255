import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import LastSeen from "../Cards/LastSeen";
import BatteryStatus from "../Cards/BatteryStatus";
import LastAlert from "../Cards/LastAlert";
import TemperatureGuague from "../Guages/Temperature";
import DeviceActionLogs from "../Cards/Alerts";
import FrameList from "../FrameList";
import { useSelector } from "react-redux";
import formatTemperature from "../../Functions/formatTemperature";
import GraphBase from "../Graphs/GraphBase";

export default function Temperature(props) {
  let { isShare } = props;
  const [resistanceMode, setResistanceMode] = React.useState("temperature");

  const deviceHistory = useSelector((state) => state.deviceHistory);
  useEffect(() => {
    if (deviceHistory.length > 0) {
      let lastData = deviceHistory[deviceHistory.length - 1];
      let jsonData = lastData.data;
      jsonData = JSON.parse(jsonData);
      if (jsonData.resistance_1) {
        setResistanceMode("resistance");
      }
    }
  }, [deviceHistory]);

  return (
    <>
      <Grid
        container
        spacing={2}
        alignItems={"stretch"}
        alignContent={"stretch"}
        sx={{ mt: 1 }}
      >
        <Grid item xs={12} md={!isShare ? 4 : 6} sx={{ mb: 2 }}>
          <LastSeen />
        </Grid>
        <Grid item xs={12} md={!isShare ? 4 : 6} sx={{ mb: 2 }}>
          <BatteryStatus />
        </Grid>
        {!isShare && (
          <Grid item xs={12} md={4} sx={{ mb: 2 }}>
            <LastAlert />
          </Grid>
        )}

        {resistanceMode === "temperature" && (
          <>
            <Grid item xs={12} md={3} sx={{ mb: 2 }}>
              <TemperatureGuague field="temperature_1" title="Temperature 1" />
            </Grid>
            <Grid item xs={12} md={9} sx={{ mb: 2 }}>
              <GraphBase
                title="Temperature 1"
                value="temperature_1"
                formatter={formatTemperature}
                graphFormatter={formatTemperature}
              />
            </Grid>
            <Grid item xs={12} md={3} sx={{ mb: 2 }}>
              <GraphBase
                title="Temperature 2"
                value="temperature_2"
                formatter={formatTemperature}
                graphFormatter={formatTemperature}
              />
            </Grid>
            <Grid item xs={12} md={9} sx={{ mb: 2 }}>
              <GraphBase
                title="Temperature 3"
                value="temperature_3"
                formatter={formatTemperature}
                graphFormatter={formatTemperature}
              />
            </Grid>
          </>
        )}
        {resistanceMode === "resistance" && (
          <>
            <Grid item xs={12} md={3} sx={{ mb: 2 }}>
              <TemperatureGuague temperature="temperature_1" />
            </Grid>
            <Grid item xs={12} md={9} sx={{ mb: 2 }}>
              <GraphBase
                title="Temperature 1"
                value="temperature_1"
                formatter={formatTemperature}
                graphFormatter={formatTemperature}
              />
            </Grid>
            <Grid item xs={12} md={3} sx={{ mb: 2 }}>
              <GraphBase
                title="Temperature 2"
                value="temperature_2"
                formatter={formatTemperature}
                graphFormatter={formatTemperature}
              />
            </Grid>
            <Grid item xs={12} md={9} sx={{ mb: 2 }}>
              <GraphBase
                title="Temperature 3"
                value="temperature_3"
                formatter={formatTemperature}
                graphFormatter={formatTemperature}
              />
            </Grid>
          </>
        )}

        <Grid item xs={12} md={12} sx={{ mb: 2 }}>
          <FrameList />
        </Grid>

        {!isShare && (
          <>
            <Grid item xs={12} md={12}>
              <DeviceActionLogs />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}
