import React from "react";
import { useSelector } from "react-redux";
import DeviceMetric from "./DeviceMetric";

export default function DoorOpenCard(props) {
  const deviceHistory = useSelector((state) => state.deviceHistory);
  const [open, setOpen] = React.useState(false);

  const { val = "door_open" } = props;

  React.useEffect(() => {
    if (!deviceHistory || deviceHistory.length === 0) {
      return;
    }

    try {
      let lastData = deviceHistory[deviceHistory.length - 1];
      let jsonData = lastData.data;
      jsonData = JSON.parse(jsonData);

      jsonData[val] === 1 ? setOpen(true) : setOpen(false);
    } catch (error) {
      console.log(error);
    }
  }, [deviceHistory, val]);

  return (
    <>
      <DeviceMetric
        title="Door Open?"
        value={open}
        icon={open ? "door-open" : "door-closed"}
        trueFalse={true}
        trueValue="Open"
        falseValue="Closed"
      />
    </>
  );
}
