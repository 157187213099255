import React, { useEffect, useState } from "react";
import GaugeComponent from "react-gauge-component";
import {
  Box,
  Card,
  CardContent,
  Skeleton,
  Typography,
  useTheme,
} from "@mui/material";
import AvgAndMax from "../Cards/avgAndMax";
import { useSelector } from "react-redux";

export default function GaugeBase(props) {
  const theme = useTheme();

  const [value, setValue] = useState(null);
  const [loaded, setLoaded] = useState(false);

  const {
    title,
    field,
    unit,
    icon,
    defaultArch = [
      {
        limit: 20,
        //red
        color: "#EA4228",
      },
      {
        limit: 30,
        // yellow
        color: "#F5CD19",
      },
      {
        limit: 70,
        //green
        color: "#5BE12C",
      },
      {
        limit: 80,
        color: "#F5CD19",
      },
      {
        limit: 100,
        color: "#EA4228",
      },
    ],
    min = 0,
    max = 100,
  } = props;

  const deviceHistory = useSelector((state) => state.deviceHistory);

  useEffect(() => {
    if (!deviceHistory || deviceHistory.length === 0) {
      setLoaded(true);
      return;
    }

    try {
      const jsonData = JSON.parse(deviceHistory[deviceHistory.length - 1].data);
      let value = jsonData[field];

      //if value is an array, return first value
      if (Array.isArray(value)) {
        value = value[0];
      }

      setValue(value || 0);
      setLoaded(true);
    } catch (error) {
      console.error("Error parsing device metric:", error);
    }
  }, [field, deviceHistory]);

  return (
    <Card
      elevation={0}
      sx={{
        height: "100%",
        background:
          theme.palette.mode === "dark"
            ? "linear-gradient(145deg, rgba(40,40,45,0.9) 0%, rgba(35,35,40,0.8) 100%)"
            : "linear-gradient(145deg, rgba(255,255,255,0.9) 0%, rgba(245,245,245,0.8) 100%)",
        backdropFilter: "blur(10px)",
        border: `1px solid ${
          theme.palette.mode === "dark"
            ? "rgba(255,255,255,0.1)"
            : "rgba(0,0,0,0.1)"
        }`,
        borderRadius: "16px",
      }}
    >
      <CardContent>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          {icon}
          <Typography
            variant="subtitle2"
            sx={{
              color: theme.palette.text.secondary,
              letterSpacing: "0.02em",
              fontWeight: 500,
              flexGrow: 1,
            }}
          >
            {title}
          </Typography>
          <Typography
            variant="h5"
            sx={{
              fontWeight: 600,
            }}
          >
            {value !== "N/A" && value !== null ? value + unit : "N/A"}
          </Typography>
        </Box>
        {loaded === false ? (
          <Skeleton variant="rectangular" height="100%" />
        ) : (
          <>
            <GaugeComponent
              type="radial"
              arc={{
                width: 0.2,
                padding: 0.005,
                cornerRadius: 1,
                //gradient: true,
                subArcs: defaultArch,
              }}
              pointer={{
                color: "#345243",
                length: 0.8,
                width: 15,
                elastic: true,
              }}
              value={value}
              minValue={min}
              maxValue={max}
              labels={{
                valueLabel: { formatTextValue: (value) => "" },
              }}
            />
            <Box sx={{ mt: "auto" }}>
              <AvgAndMax type={field} value={field} unit={unit} />
            </Box>
          </>
        )}
      </CardContent>
    </Card>
  );
}
