import { useMemo } from "react";

export const useToolbarProps = ({
  theme,
  pageTitle,
  devices,
  filter,
  setFilter,
  devicesPerPage,
  handleDevicesPerPageChange,
  devicesPerPageOptions,
  setAddDeviceOpen,
  handleRefresh,
  canAddDevice,
  isMobile,
}) => {
  return useMemo(
    () => ({
      theme,
      title: pageTitle,
      subTitle: `${devices?.length || 0} Devices in this Organisation`,
      filter,
      onFilterChange: setFilter,
      devicesPerPage,
      onDevicesPerPageChange: handleDevicesPerPageChange,
      devicesPerPageOptions,
      onAddDevice: () => setAddDeviceOpen(true),
      onRefresh: handleRefresh,
      canAddDevice,
      isMobile,
      showControls: !isMobile && devices?.length > 0,
    }),
    [
      theme,
      pageTitle,
      devices?.length,
      filter,
      devicesPerPage,
      devicesPerPageOptions,
      handleDevicesPerPageChange,
      setFilter,
      setAddDeviceOpen,
      handleRefresh,
      canAddDevice,
      isMobile,
    ]
  );
};
