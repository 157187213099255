import React, { useState } from "react";
import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import DataTableBase from "../DataTableBase";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import getAlertTitle from "../../Functions/getAlertTitle";
import getForwarderTitle from "../../Functions/getForwarderTitle";
import { Check, Clear } from "@mui/icons-material";
import fetchActions from "../../Functions/fetchActions";
import ActionEditDialog from "../Dialogs/actionEdit";
import ActionStatusDialog from "../Dialogs/actionStatus";
import ActionsDeleteDialog from "../Dialogs/actionDelete";
import getGeofenceTitle from "../../Functions/getGeofenceTitle";
import hasPermission from "../../Functions/hasPermission";

export default function ActionsTable() {
  const { t } = useTranslation("common");

  const navigate = useNavigate();

  const theme = useTheme();

  const actions = useSelector((state) => state.actions);
  const userOrganisationRoles = useSelector(
    (state) => state.userOrganisationRoles
  );
  const selectedOrganisation = useSelector(
    (state) => state.selectedOrganisation
  );
  const userDB = useSelector((state) => state.user);

  const [editActionDialogOpen, setEditActionDialogOpen] = useState(false);
  const [selectedActionID, setSelectedActionID] = useState(null);
  const [selectedActions, setSelectedActions] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [actionStatusDialogOpen, setActionStatusDialogOpen] = useState(false);

  const [deleteSelectedActionsDialogOpen, setDeleteSelectedActionsDialogOpen] =
    useState(false);

  //columns for devices for data table
  const columnsActions = [
    {
      name: "id",
      label: t("common.ID"),
      options: {
        display: false,
      },
    },
    {
      name: "name",
      options: {
        label: t("common.name"),
        customBodyRender: (value, tableMeta, meta) => {
          return (
            <Typography
              onClick={() => {
                setSelectedActionID(tableMeta.rowData[0]);
                setEditActionDialogOpen(true);
              }}
              className="cursor-pointer"
              variant="body1"
              color="primary"
            >
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: "action_type",
      label: t("common.action_type"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          // uppercase first letter
          let actionType = value.charAt(0).toUpperCase() + value.slice(1);
          return <Typography variant="body2">{actionType}</Typography>;
        },
      },
    },
    {
      name: "action_type",
      label: t("actions.name"),
      options: {
        customBodyRender: (value, tableMeta) => {
          let title = "";

          if (value === "alert") {
            title = getAlertTitle(tableMeta, t);
          } else if (value === "forward") {
            title = getForwarderTitle(tableMeta, t);
          } else if (value === "geofence") {
            title = getGeofenceTitle(tableMeta, t);
          }

          return <Typography variant="body2">{title}</Typography>;
        },
      },
    },
    {
      name: "alert_type",
      label: t("actions.alert_type"),
      options: {
        display: false,
      },
    },
    {
      name: "above_below",
      label: t("actions.above_below"),
      options: {
        display: false,
      },
    },
    {
      name: "threshold",
      label: t("actions.threshold"),
      options: {
        display: false,
      },
    },
    {
      name: "recipient_type",
      label: t("actions.recipient_type"),
      options: {
        display: false,
      },
    },
    {
      name: "recipient",
      label: t("actions.recipient"),
      options: {
        display: false,
      },
    },
    {
      name: "forwarder_url",
      label: t("actions.forwarder_url"),
      options: {
        display: false,
      },
    },
    {
      name: "out_of_hours",
      label: t("actions.out_of_hours"),
      options: {
        display: false,
      },
    },
    {
      name: "start_time",
      label: t("actions.start_time"),
      options: {
        display: false,
      },
    },
    {
      name: "end_time",
      label: t("actions.end_time"),
      options: {
        display: false,
      },
    },
    {
      name: "active",
      label: t("actions.active"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          if (value === 1) {
            return <Check />;
          } else {
            return <Clear />;
          }
        },
      },
    },
    {
      name: "options",
      label: t("common.options"),
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <Stack direction="row" spacing={2}>
              <Button
                variant="outlined"
                color={theme.palette.mode === "dark" ? "primary" : "secondary"}
                onClick={() => {
                  navigate("/action/" + tableMeta.rowData[0] + "/logs");
                }}
              >
                View Logs
              </Button>
              {
                //check if organisation.owner_id is equal to userDB.id
                (userDB &&
                  userDB.id &&
                  selectedOrganisation.owner_id === userDB.id) ||
                hasPermission(userOrganisationRoles, userDB.id, "edit") ? (
                  <Button
                    variant="outlined"
                    color={
                      theme.palette.mode === "dark" ? "primary" : "secondary"
                    }
                    onClick={() => {
                      setSelectedActionID(tableMeta.rowData[0]);
                      setEditActionDialogOpen(true);
                    }}
                  >
                    Edit
                  </Button>
                ) : null
              }
            </Stack>
          );
        },
      },
    },
    {
      name: "true_false",
      label: t("actions.true_false"),
      options: {
        display: false,
      },
    },
    {
      name: "geofence_action",
      label: t("actions.geofence_action"),
      options: {
        display: false,
      },
    },
    {
      name: "command",
      label: t("actions.command"),
      options: {
        display: false,
      },
    },
    {
      name: "temp_value",
      label: "Temp Value",
      options: {
        display: false,
      },
    },
  ];

  let tableActions = [];

  if (
    (selectedOrganisation &&
      userDB &&
      selectedOrganisation.owner_id === userDB.id) ||
    hasPermission(userOrganisationRoles, userDB.id, "delete")
  ) {
    tableActions.push({
      icon: "Delete",
      name: t("common.delete"),
      action: () => setDeleteSelectedActionsDialogOpen(true),
    });
  }

  if (
    (selectedOrganisation &&
      userDB &&
      selectedOrganisation.owner_id === userDB.id) ||
    hasPermission(userOrganisationRoles, userDB.id, "edit")
  ) {
    tableActions.push({
      icon: "Edit",
      name: t("actions.set_status"),
      action: () => setActionStatusDialogOpen(true),
    });
  }

  return (
    <>
      <Box sx={{ mt: 1 }}>
        <DataTableBase
          columns={columnsActions}
          data={actions}
          title={t("actions.name")}
          id="action-list"
          name="action-list"
          pageSize={10}
          selectedItems={selectedActions}
          setSelectedItems={setSelectedActions}
          selectableRows={
            (selectedOrganisation &&
              userDB &&
              selectedOrganisation.owner_id === userDB.id) ||
            hasPermission(userOrganisationRoles, userDB.id, "delete")
              ? "multiple"
              : "none"
          }
          actions={tableActions}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      </Box>
      <ActionEditDialog
        open={editActionDialogOpen}
        selectedActionID={selectedActionID}
        setOpen={setEditActionDialogOpen}
        handleClose={() => {
          setEditActionDialogOpen(false);
          fetchActions(localStorage.getItem("selectedOrganisationID"));
        }}
      />
      <ActionsDeleteDialog
        open={deleteSelectedActionsDialogOpen}
        selectedActions={selectedActions}
        handleClose={() => {
          setDeleteSelectedActionsDialogOpen(false);
          setSelectedActions([]);
          setSelectedRows([]);

          //set a timer to fetch actions after the dialog closes to ensure the actions are updated
          setTimeout(() => {
            fetchActions(localStorage.getItem("selectedOrganisationID"));
          }, 1000);
        }}
      />
      <ActionStatusDialog
        open={actionStatusDialogOpen}
        selectedActions={selectedActions}
        handleClose={() => {
          setActionStatusDialogOpen(false);
          setSelectedActions([]);
          setSelectedRows([]);
          fetchActions(localStorage.getItem("selectedOrganisationID"));
        }}
      />
    </>
  );
}
