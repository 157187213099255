//dialog for importing a bases from uploaded csv file

import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

import { useTranslation } from "react-i18next";
import updateSnackbarMessage from "../../Functions/updateSnackbarMessage";
import { postData } from "../../API/Post";

export default function BaseImportDialog(props) {
  const { open, handleClose, getBases } = props;
  const [file, setFile] = React.useState(null);
  const { t } = useTranslation("common");

  const handleUpload = async () => {
    //upload file
    // get contents of file in csv and for each line verify and add base
    let successfullyAdded = 0,
      failedToAdd = 0;

    try {
      let fileContents = await file.text();
      let lines = fileContents.split("\n");
      for (let i = 0; i < lines.length; i++) {
        // split line into array of values by comma
        let values = lines[i].split(",");
        // verify that the line has 4 values
        if (values.length === 3) {
          // check value lowercase is not name
          if (values[0].toLowerCase() !== "name") {
            let data = {
              name: values[0],
              address: values[1],
              postcode: values[2],
            };

            try {
              await postData("/base", data);
              successfullyAdded++;
            } catch (error) {
              console.log(error);
              failedToAdd++;
            }
          }
        } else {
          failedToAdd++;
          continue;
        }
      }

      if (props.getBases) {
        await getBases();
      }

      let msg = t("bases.imported_success", { count: successfullyAdded });

      if (failedToAdd > 0) {
        msg += t("bases.imported_error", { count: failedToAdd });
      }

      updateSnackbarMessage(msg);
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogTitle id="form-dialog-title">
        {t("bases.import_title")}
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">{t("bases.import_desc")}</Typography>
        <Typography
          variant="body2"
          sx={{ mt: 1, mb: 2, fontFamily: "monospace" }}
        >
          {t("bases.import_example")}
        </Typography>
        <input
          accept="text/csv"
          id="contained-button-file"
          multiple
          type="file"
          sx={{ mt: 1 }}
          onChange={(e) => {
            setFile(e.target.files[0]);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={handleClose}>
          {t("common.cancel")}
        </Button>
        <Button variant="contained" color="primary" onClick={handleUpload}>
          {t("common.import")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
