import React from "react";
import { Grid } from "@mui/material";
import DeviceActionLogs from "../Cards/Alerts";
import FrameList from "../FrameList";
import { useSelector } from "react-redux";
import TemperatureGuague from "../Guages/Temperature";
import ThreeTemperaturesGraph from "../Graphs/ThreeTemperatures";
import LastSeen from "../Cards/LastSeen";
import LastAlert from "../Cards/LastAlert";
import BatteryStatus from "../Cards/BatteryStatus";
import WorkMode from "../Cards/WorkMode";

export default function Lsn50v2_d20(props) {
  let { isShare } = props;

  return (
    <>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12} md={isShare ? 4 : 3} sx={{ mb: 2 }}>
          <LastSeen />
        </Grid>

        <Grid item xs={12} md={isShare ? 4 : 3} sx={{ mb: 2 }}>
          <WorkMode />
        </Grid>

        <Grid item xs={12} md={isShare ? 4 : 3} sx={{ mb: 2 }}>
          <BatteryStatus />
        </Grid>

        {!isShare && (
          <Grid item xs={12} md={3} sx={{ mb: 2 }}>
            <LastAlert />
          </Grid>
        )}

        <Grid item xs={12} md={4} sx={{ mb: 2 }}>
          <TemperatureGuague title="Temperature Red" field="temp_red" />
        </Grid>

        <Grid item xs={12} md={4} sx={{ mb: 2 }}>
          <TemperatureGuague title="Temperature Black" field="temp_black" />
        </Grid>

        <Grid item xs={12} md={4} sx={{ mb: 2 }}>
          <TemperatureGuague title="Temperature White" field="temp_white" />
        </Grid>

        <Grid item xs={12} sx={{ mb: 2 }}>
          <ThreeTemperaturesGraph />
        </Grid>

        <Grid
          item
          xs={12}
          md={isShare ? 12 : 7}
          xxl={isShare ? 12 : 7}
          sx={{ mb: 2 }}
        >
          <FrameList isShare={isShare} />
        </Grid>

        {!isShare && (
          <Grid item xs={12} md={5} xxl={5} sx={{ mb: 2 }}>
            <DeviceActionLogs />
          </Grid>
        )}
      </Grid>
    </>
  );
}
