import React from "react";
import { Grid } from "@mui/material";
import LastAlert from "../Cards/LastAlert";
import BatteryStatus from "../Cards/BatteryStatus";
import LastSeen from "../Cards/LastSeen";
import DeviceActionLogs from "../Cards/Alerts";
import ButtonPressedCard from "../Cards/ButtonPressed";
import FrameList from "../FrameList";
import { useSelector } from "react-redux";
import GraphBase from "../Graphs/GraphBase";
import DeviceMetric from "../Cards/DeviceMetric";
import formatTemperature from "../../Functions/formatTemperature";

export default function AirQualityTVOC(props) {
  let { isShare } = props;

  const deviceHistory = useSelector((state) => state.deviceHistory);
  //check to see if there is a button_pressed field in the deviceHistory.data json string
  //if there is, then we know this is a button tracker and we can show the button press graph and card
  let buttonPressed = false;

  if (deviceHistory.length > 0) {
    let lastData = deviceHistory[deviceHistory.length - 1];
    let jsonData = lastData.data;
    jsonData = JSON.parse(jsonData);
    //check data contains a button_pressed key (it will be true or false value)
    if (jsonData.button_pressed === 1 || jsonData.button_pressed === 0) {
      buttonPressed = true;
    }
  }

  //if button press is true, set the column to 3, otherwise set it to 4
  let buttonPressColumn = buttonPressed ? 3 : 4;

  if (isShare) {
    buttonPressColumn = buttonPressed ? 4 : 6;
  }

  return (
    <>
      <Grid
        container
        spacing={2}
        alignItems={"stretch"}
        alignContent={"stretch"}
        sx={{ mt: 1 }}
      >
        <Grid item xs={12} md={buttonPressColumn} sx={{ mb: 2 }}>
          <LastSeen />
        </Grid>
        <Grid item xs={12} md={buttonPressColumn} sx={{ mb: 2 }}>
          <BatteryStatus />
        </Grid>

        {buttonPressed && (
          <Grid item xs={6} md={buttonPressColumn} sx={{ mb: 2 }}>
            <ButtonPressedCard alarm={false} />
          </Grid>
        )}
        {!isShare && (
          <Grid item xs={12} md={buttonPressColumn} sx={{ mb: 2 }}>
            <LastAlert />
          </Grid>
        )}
        <Grid item xs={12} md={3} sx={{ mb: 2 }}>
          <DeviceMetric
            title="TVOC"
            value="tvoc"
            unit="ppb"
            icon="bug"
            color="primary"
          />
        </Grid>

        <Grid item xs={12} md={3} sx={{ mb: 2 }}>
          <DeviceMetric
            title="CO2"
            value="co2"
            unit="ppm"
            icon="head-side-mask"
            color="primary"
          />
        </Grid>

        <Grid item xs={12} md={3} sx={{ mb: 2 }}>
          <DeviceMetric
            title="Temperature"
            value="temperature"
            unit={localStorage.getItem("temperature_unit")}
            formatter={formatTemperature}
            icon="thermometer"
            color="primary"
          />
        </Grid>

        <Grid item xs={12} md={3} sx={{ mb: 2 }}>
          <DeviceMetric
            title="Humidity"
            value="humidity"
            unit="%"
            icon="wind"
            color="primary"
          />
        </Grid>
        <Grid item xs={12} md={6} sx={{ mb: 2 }}>
          <GraphBase title="TVOC" value="tvoc" />
        </Grid>
        <Grid item xs={12} md={6} sx={{ mb: 2 }}>
          <GraphBase title="CO2" value="co2" />
        </Grid>
        <Grid item xs={12} md={6} sx={{ mb: 2 }}>
          <GraphBase title="Temperature" value="temperature" />
        </Grid>
        <Grid item xs={12} md={6} sx={{ mb: 2 }}>
          <GraphBase title="Humidity" value="humidity" />
        </Grid>

        <Grid item xs={12} md={!isShare ? 7 : 12} sx={{ mb: 2 }}>
          <FrameList isShare={isShare} />
        </Grid>

        {!isShare && (
          <>
            <Grid item xs={12} md={5} sx={{ mb: 2 }}>
              <DeviceActionLogs />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}
