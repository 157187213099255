import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import CardBase from "./CardBase";

export default function WorkMode() {
  const deviceHistory = useSelector((state) => state.deviceHistory);

  const [value, setValue] = useState("N/A");

  useEffect(() => {
    if (!deviceHistory || !deviceHistory.length) {
      return;
    }

    const last_record = JSON.parse(deviceHistory[0].data);

    setValue(last_record["work_mode"]);
  }, [deviceHistory]);

  return (
    <CardBase title="Work Mode" value={value} unit={""} icon="briefcase" />
  );
}
