import React from "react";
import { useSelector } from "react-redux";
import TemperatureGuague from "./Guages/Temperature";
import { Grid } from "@mui/material";
import GraphBase from "./Graphs/GraphBase";
import formatTemperature from "../Functions/formatTemperature";

export default function SidebarShareMap() {
  const deviceHistory = useSelector((state) => state.deviceHistory);

  let lastDeviceHistory;
  let lastDeviceHistoryData;

  if (deviceHistory.length) {
    lastDeviceHistory = deviceHistory[deviceHistory.length - 1];
    lastDeviceHistoryData = JSON.parse(lastDeviceHistory.data);
  }

  if (!lastDeviceHistory) {
    return null;
  }

  return (
    <div className="sidebar">
      {
        //if the data has temperature, light or sos, show it
        lastDeviceHistoryData.temperature && (
          <Grid container spacing={2} className="infoBox">
            <Grid item xs={12} md={3}>
              <TemperatureGuague
                temperature={lastDeviceHistoryData.temperature}
              />
            </Grid>
            <Grid item xs={12} md={9}>
              <GraphBase
                title="Temperature"
                value="temperature"
                formatter={formatTemperature}
                graphFormatter={formatTemperature}
                unit={formatTemperature(0, false, true)}
              />
            </Grid>
          </Grid>
        )
      }
    </div>
  );
}
