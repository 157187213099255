import React from "react";
import DeviceTypeBase from "./DeviceTypeBase";
import formatTemperature from "../../Functions/formatTemperature";

export default function AirQuality(props) {
  const { isShare } = props;

  let columns = [
    {
      field: "Temperature",
      title: "Temperature",
      icon: "thermometer",
      iconSize: "4x",
      formatter: (value) => formatTemperature(value, false),
      graphValueFormatter: (value) => formatTemperature(value, true),
      aspect: 3.5,
      metricWidth: 3,
      graphWidth: 12,
      unit: formatTemperature(0, false, true),
      textsize: "h3",
    },
    {
      field: "Humidity",
      title: "Humidity",
      icon: "water",
      iconSize: "4x",
      formatter: (value) => `${value}%`,
      graphValueFormatter: (value) => `${value}%`,
      aspect: 3.5,
      metricWidth: 3,
      graphWidth: 12,
      unit: "%",
      textsize: "h3",
    },
    {
      field: "CO2",
      title: "CO2",
      icon: "molecule",
      iconSize: "4x",
      aspect: 3.5,
      metricWidth: 3,
      graphWidth: 12,
      unit: "ppm",
      textsize: "h3",
    },
  ];

  return (
    <DeviceTypeBase
      isShare={isShare}
      columns={columns}
      showBattery={true}
      sideBySide={true}
      showGuagues={true}
    />
  );
}
