import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import DeviceMetric from "./DeviceMetric";

export default function EventStatusCard() {
  const deviceHistory = useSelector((state) => state.deviceHistory);
  const [eventStatus, setEventStatus] = React.useState("No Event");

  useEffect(() => {
    if (!deviceHistory || !deviceHistory || deviceHistory.length === 0) {
      return;
    }

    try {
      let lastData = deviceHistory[deviceHistory.length - 1];
      let jsonData = lastData.data;
      jsonData = JSON.parse(jsonData);

      if (jsonData.event_status) {
        setEventStatus(
          jsonData.event_status ? jsonData.event_status : "No Event"
        );
      }
    } catch (error) {
      console.log(error);
    }
  }, [deviceHistory]);

  return (
    <DeviceMetric
      title="Event Status"
      value={eventStatus}
      unit=""
      icon="bell"
      textsize="h6"
    />
  );
}
