import { createTheme } from "@mui/material";
import { CONFIG } from "../config";

export const createAppTheme = (themeMode, config) =>
  createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
        xxl: 2048,
      },
    },
    palette: {
      mode: themeMode === "dark" ? "dark" : "light",
      gradient:
        themeMode === "dark"
          ? "linear-gradient( to bottom, #191B1F 0%, #191B1F 550px, #25282D 550px, #25282D 150%)"
          : `linear-gradient( to bottom, ${config.primaryColour} 0%, ${config.primaryColour} 550px, #EFEFEF 550px, #EFEFEF 150%)`,
      primary: { main: config.primaryColour },
      secondary: { main: config.secondaryColour },
    },
    typography: { fontFamily: "Inter, sans-serif" },
    components: {
      MuiPaper: {
        styleOverrides: {
          root: ({ theme }) => ({
            background:
              theme.palette.mode === "dark"
                ? "linear-gradient(145deg, rgba(40,40,45,0.9) 0%, rgba(35,35,40,0.8) 100%)"
                : "linear-gradient(145deg, rgba(255,255,255,0.9) 0%, rgba(245,245,245,0.8) 100%)",
            backdropFilter: "blur(10px)",
            border: `1px solid ${
              theme.palette.mode === "dark"
                ? "rgba(255,255,255,0.1)"
                : "rgba(0,0,0,0.1)"
            }`,
            borderRadius: "16px",
            transition:
              "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
            "&:hover": {
              border: `1px solid ${config.primaryColour}`,
              boxShadow:
                theme.palette.mode === "dark"
                  ? "0 8px 20px rgba(0,0,0,0.4)"
                  : "0 8px 20px rgba(0,0,0,0.1)",
            },
          }),
        },
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            "&:last-child": {
              paddingBottom: "16px",
            },
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: ({ theme }) => ({
            background:
              theme.palette.mode === "dark" ? "#111111" : CONFIG.primaryColour,
          }),
        },
      },
    },
  });
