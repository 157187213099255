import React, { useEffect, useState } from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Stack } from "@mui/system";
import { Button, Grid, Tooltip, useTheme } from "@mui/material";
import { Add, Refresh } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { formatDate } from "../Functions/formatDate";
import moment from "moment";
import { useSelector } from "react-redux";
import fetchAssets from "../Functions/fetchAssets";
import updateSnackbarMessage from "../Functions/updateSnackbarMessage";
import PageTitle from "../components/PageTitle";
import setTitle from "../Functions/setTitle";
import hasPermission from "../Functions/hasPermission";
import AssetsTable from "../components/Tables/Assets";
import AssetAddDialog from "../components/Dialogs/assetAdd";

function Assets() {
  const user = useSelector((state) => state.user);
  const isMobile = useSelector((state) => state.isMobile);
  const assets = useSelector((state) => state.assets);
  const selectedOrganisation = useSelector(
    (state) => state.selectedOrganisation
  );
  const userOrganisationRoles = useSelector(
    (state) => state.userOrganisationRoles
  );

  const [addAssetDialogOpen, setAddAssetDialogOpen] = useState(false);

  const handleAddAssetDialogClose = () => {
    setAddAssetDialogOpen(false);
  };

  const theme = useTheme();
  const { t } = useTranslation("common");

  const handleRefreshAssetsClick = () => {
    fetchAssets(localStorage.getItem("selectedOrganisationID"));
    updateSnackbarMessage(
      t("common.refreshed") + " " + formatDate(moment().utc().toISOString())
    );
  };

  const renderAddAssetButton = () => {
    if (
      (selectedOrganisation &&
        user &&
        selectedOrganisation.owner_id === user.id) ||
      hasPermission(userOrganisationRoles, user.id, "add")
    ) {
      return (
        <Tooltip title="Add an asset" placement="top" arrow>
          <Button
            variant="outlined"
            color={theme.palette.mode === "dark" ? "primary" : "secondary"}
            sx={{ mr: 1 }}
            onClick={() => setAddAssetDialogOpen(true)}
          >
            <Add />
          </Button>
        </Tooltip>
      );
    }
    return null;
  };

  useEffect(() => {
    setTitle("Assets", selectedOrganisation);
  }, [selectedOrganisation]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack
            direction="row"
            justifyContent={isMobile ? "flex-start" : "flex-end"}
            sx={{ mt: 1 }}
          >
            <PageTitle
              theme={theme}
              title={
                selectedOrganisation && selectedOrganisation.name
                  ? selectedOrganisation.name + " " + t("assets.name")
                  : t("assets.name")
              }
              subTitle={
                assets && assets.length + " assets in this organisation"
              }
              beta
            />
            {renderAddAssetButton()}
            <Tooltip title="Refresh Assets" placement="top" arrow>
              <Button
                variant="outlined"
                color={theme.palette.mode === "dark" ? "primary" : "secondary"}
                onClick={handleRefreshAssetsClick}
              >
                <Refresh />
              </Button>
            </Tooltip>
          </Stack>
        </Grid>
      </Grid>

      <AssetsTable />

      <AssetAddDialog
        open={addAssetDialogOpen}
        handleClose={handleAddAssetDialogClose}
      />
    </>
  );
}

export default withAuthenticationRequired(Assets);
