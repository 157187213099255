import { BugReport } from "@mui/icons-material";
import { useTheme } from "@emotion/react";
import GaugeBase from "./GaugeBase";

export default function TVOCGuague(props) {
  const { field = "TVOC", title = "TVOC" } = props;
  const defaultArch = [
    {
      limit: 1000,
      // green
      color: "#5BE12C",
    },
    {
      limit: 1500,
      //dark orange
      color: "#F5CD19",
    },
    {
      color: "#EA4228",
    },
  ];

  const theme = useTheme();

  return (
    <GaugeBase
      title={title}
      field={field}
      unit=""
      icon={
        <BugReport
          sx={{
            color: theme.palette.text.secondary,
            opacity: 0.8,
          }}
        />
      }
      defaultArch={defaultArch}
      min={0}
      max={1500}
    />
  );
}
