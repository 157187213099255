import React from "react";
import { Grid } from "@mui/material";
import LastAlert from "../Cards/LastAlert";
import BatteryStatus from "../Cards/BatteryStatus";
import LastSeen from "../Cards/LastSeen";
import DeviceActionLogs from "../Cards/Alerts";
import FrameList from "../FrameList";
import MotionCardNBIOT from "../Cards/MotionNBIOT";
import GraphBase from "../Graphs/GraphBase";
import SignalQuality from "../Cards/SignalQuality";

export default function MotionNBIOT(props) {
  let { isShare } = props;

  return (
    <>
      <Grid
        container
        spacing={2}
        alignItems={"stretch"}
        alignContent={"stretch"}
        sx={{ mt: 1 }}
      >
        <Grid item xs={12} md={isShare ? 4 : 3} sx={{ mb: 2 }}>
          <LastSeen />
        </Grid>
        <Grid item xs={12} md={isShare ? 4 : 3} sx={{ mb: 2 }}>
          <BatteryStatus />
        </Grid>

        <Grid item xs={12} md={isShare ? 4 : 3} sx={{ mb: 2 }}>
          <SignalQuality />
        </Grid>

        {!isShare && (
          <>
            <Grid item xs={12} md={3} sx={{ mb: 2 }}>
              <LastAlert />
            </Grid>
          </>
        )}

        <Grid item xs={12} md={3} sx={{ mb: 2 }}>
          <MotionCardNBIOT />
        </Grid>

        <Grid item xs={12} md={9} sx={{ mb: 2 }}>
          <GraphBase
            title="Motion Detected"
            value="state"
            truefalse={true}
            trueV="Motion Detected"
            falseV="No Motion"
          />
        </Grid>

        <Grid item xs={12} md={12} sx={{ mb: 2 }}>
          <GraphBase title="Distance" value="distance" />
        </Grid>

        <Grid item xs={12} md={!isShare ? 6 : 12} sx={{ mb: 2 }}>
          <FrameList state="Motion" />
        </Grid>

        {!isShare && (
          <Grid item xs={12} md={6} sx={{ mb: 2 }}>
            <DeviceActionLogs />
          </Grid>
        )}
      </Grid>
    </>
  );
}
