import { postData } from "../API/Post";

export default function addWidget(
  widget,
  fields,
  dashboardId,
  dashboardWidgets
) {
  if (!widget || !fields || !dashboardId) {
    return false;
  }

  //we need to work out the x and y position of the widget, we can do this by looking at the other widgets on the dashboard and finding the first available space
  //we can then use this space to place the widget
  //we can also set the width and height of the widget to be 2x2

  let x = 0;
  let y = 0;
  let w = 1;
  let h = 4;

  //if we have a map widget, set the w and height to 4x
  if (widget === 3) {
    w = 8;
    h = 16;
  } else if (widget === 2) {
    w = 4;
    h = 6;
  }

  //work out the order of the widget
  let order = dashboardWidgets.length + 1;

  //loop through dashboard widgets and find the highest x, and add 1 to it
  dashboardWidgets.forEach((widget) => {
    if (widget.y > y) {
      y = widget.y;
    }
  });
  y = y + 4;

  //we want to store fields object as json string with escaped quotes and commas, encode the object
  let safeFields = JSON.stringify(fields);

  let data = {
    dashboard_id: dashboardId,
    widget_id: widget,
    device_id: 1,
    field: safeFields,
    order: order,
    x: x,
    y: y,
    w: w,
    h: h,
  };

  postData(`/dashboard/${dashboardId}/widget`, data);
  return true;
}
