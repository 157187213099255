import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import isTracker from "../Functions/isTracker";
import { useEffect, useState } from "react";
import DevicesMap from "./Maps/Devices";
import ImageWithPins from "./Maps/ImageWithPins";

export default function Hero() {
  const selectedOrganisation = useSelector(
    (state) => state.selectedOrganisation
  );
  const devices = useSelector((state) => state.devices);

  const [heroMode, setHeroMode] = useState("default");
  const [showMap, setShowMap] = useState(false);

  useEffect(() => {
    if (selectedOrganisation && selectedOrganisation.hero) {
      setHeroMode(selectedOrganisation.hero);
    }

    if (devices && devices.length) {
      setShowMap(devices.some((device) => isTracker(device.device_type)));
    } else {
      setShowMap(false);
    }
  }, [selectedOrganisation, devices]);

  return (
    <Grid item xs={12} sx={{ mb: 2 }}>
      {heroMode === "mapiftrackers" && showMap ? <DevicesMap /> : null}
      {heroMode === "map" ? <DevicesMap /> : null}
      {heroMode === "image" ? <ImageWithPins /> : null}
    </Grid>
  );
}
