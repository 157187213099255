import React from "react";
import { Grid } from "@mui/material";
import LastAlert from "../Cards/LastAlert";
import LastSeen from "../Cards/LastSeen";
import DeviceActionLogs from "../Cards/Alerts";
import FrameList from "../FrameList";
import DeviceMetric from "../Cards/DeviceMetric";
import MultiGraphBase from "../Graphs/MultiGraph";
import formatTemperature from "../../Functions/formatTemperature";
import GraphBase from "../Graphs/GraphBase";
import SignalQuality from "../Cards/SignalQuality";

export default function GoIoTwiskaairhumiditypmqos(props) {
  let { isShare } = props;

  return (
    <>
      <Grid
        container
        spacing={2}
        alignItems={"stretch"}
        alignContent={"stretch"}
        sx={{ mt: 1 }}
      >
        <Grid item xs={12} lg={!isShare ? 4 : 12} sx={{ mb: 2 }}>
          <LastSeen />
        </Grid>

        <Grid item xs={12} lg={!isShare ? 4 : 12} sx={{ mb: 2 }}>
          <SignalQuality />
        </Grid>

        {!isShare && (
          <Grid item xs={12} lg={4} sx={{ mb: 2 }}>
            <LastAlert />
          </Grid>
        )}

        <Grid item xs={12} lg={2} sx={{ mb: 2 }}>
          <DeviceMetric
            title="Temperature"
            value="sht_temp"
            icon="thermometer-half"
            iconSize="4x"
            aspect={10}
            metricWidth={3}
            graphWidth={12}
            unit="°C"
            textsize="h4"
            formatter={(value) => {
              return formatTemperature(value, true);
            }}
          />
        </Grid>
        <Grid item xs={12} lg={2} sx={{ mb: 2 }}>
          <DeviceMetric
            title="Humidity"
            value="rh"
            icon="tint"
            iconSize="4x"
            aspect={10}
            metricWidth={3}
            graphWidth={12}
            unit="%"
            textsize="h4"
          />
        </Grid>
        <Grid item xs={12} lg={2} sx={{ mb: 2 }}>
          <DeviceMetric
            title="CO2"
            value="co2"
            icon="cloud"
            iconSize="4x"
            aspect={10}
            metricWidth={3}
            graphWidth={12}
            unit="ppm"
            textsize="h4"
          />
        </Grid>

        <Grid item xs={12} lg={2} sx={{ mb: 2 }}>
          <DeviceMetric
            title="Pressure"
            value="pressure"
            icon="tachometer-alt"
            iconSize="4x"
            aspect={10}
            metricWidth={3}
            graphWidth={12}
            formatter={(value) => {
              return value.toFixed(0);
            }}
            unit="Pa"
            textsize="h4"
          />
        </Grid>

        <Grid item xs={12} lg={2} sx={{ mb: 2 }}>
          <DeviceMetric
            title="Light"
            value="light"
            icon="sun"
            iconSize="4x"
            aspect={10}
            metricWidth={3}
            graphWidth={12}
            unit="Lux"
            textsize="h4"
          />
        </Grid>

        <Grid item xs={12} lg={2} sx={{ mb: 2 }}>
          <DeviceMetric
            title="Sound"
            value="noise"
            icon="volume-up"
            iconSize="4x"
            aspect={10}
            metricWidth={3}
            graphWidth={12}
            unit="dB"
            textsize="h4"
          />
        </Grid>

        <Grid item xs={12} lg={4} sx={{ mb: 2 }}>
          <DeviceMetric
            title="VOC Index"
            value="voc_index"
            icon="smog"
            iconSize="4x"
            aspect={10}
            metricWidth={3}
            graphWidth={12}
            unit=""
            textsize="h4"
          />
        </Grid>

        <Grid item xs={12} lg={2} sx={{ mb: 2 }}>
          <DeviceMetric
            title="PM1.0"
            value="conc_pm1_0"
            icon="smog"
            iconSize="4x"
            aspect={10}
            metricWidth={3}
            graphWidth={12}
            unit="ug/m3"
            textsize="h4"
          />
        </Grid>

        <Grid item xs={12} lg={2} sx={{ mb: 2 }}>
          <DeviceMetric
            title="PM2.5"
            value="conc_pm2_5"
            icon="smog"
            iconSize="4x"
            aspect={10}
            metricWidth={3}
            graphWidth={12}
            unit="ug/m3"
            textsize="h4"
          />
        </Grid>
        <Grid item xs={12} lg={2} sx={{ mb: 2 }}>
          <DeviceMetric
            title="PM4.0"
            value="conc_pm4_0"
            icon="smog"
            iconSize="4x"
            aspect={10}
            metricWidth={3}
            graphWidth={12}
            unit="ug/m3"
            textsize="h4"
          />
        </Grid>

        <Grid item xs={12} lg={2} sx={{ mb: 2 }}>
          <DeviceMetric
            title="PM10"
            value="conc_pm10"
            icon="smog"
            iconSize="4x"
            aspect={10}
            metricWidth={3}
            graphWidth={12}
            unit="ug/m3"
            textsize="h4"
          />
        </Grid>

        <Grid item xs={12} lg={6} sx={{ mb: 2 }}>
          <GraphBase
            title="Temperature"
            value="sht_temp"
            formatter={formatTemperature}
            graphFormatter={formatTemperature}
            unit={formatTemperature(0, false, true)}
          />
        </Grid>

        <Grid item xs={12} lg={6} sx={{ mb: 2 }}>
          <MultiGraphBase
            values={[
              {
                title: "Humidity",
                value: "rh",
              },
            ]}
            title="Humidity"
            unit="%"
            aspect={5}
          />
        </Grid>

        <Grid item xs={12} lg={6} sx={{ mb: 2 }}>
          <MultiGraphBase
            values={[
              {
                title: "CO2",
                value: "co2",
              },
            ]}
            title="CO2"
            unit="ppm"
            aspect={5}
          />
        </Grid>

        <Grid item xs={12} lg={6} sx={{ mb: 2 }}>
          <MultiGraphBase
            values={[
              {
                title: "Pressure",
                value: "pressure",
              },
            ]}
            title="Pressure"
            unit="Pa"
            aspect={5}
          />
        </Grid>

        <Grid item xs={12} lg={6} sx={{ mb: 2 }}>
          <MultiGraphBase
            values={[
              {
                title: "Lux",
                value: "light",
              },
            ]}
            title="Light"
            unit="Lux"
            aspect={5}
          />
        </Grid>

        <Grid item xs={12} lg={6} sx={{ mb: 2 }}>
          <MultiGraphBase
            values={[
              {
                title: "Sound",
                value: "noise",
              },
            ]}
            title="Sound"
            unit="dB"
            aspect={5}
          />
        </Grid>

        <Grid item xs={12} lg={6} sx={{ mb: 2 }}>
          <MultiGraphBase
            values={[
              {
                title: "VOC Index",
                value: "voc_index",
              },
            ]}
            title="VOC Index"
            unit=""
            aspect={5}
          />
        </Grid>

        <Grid item xs={12} lg={6} sx={{ mb: 2 }}>
          <MultiGraphBase
            values={[
              {
                title: "PM10 Number Concentration",
                value: "conc_pm10",
              },

              {
                title: "PM4.0 Number Concentration",
                value: "conc_pm4_0",
              },

              {
                title: "PM2.5 Number Concentration",
                value: "conc_pm2_5",
              },
              {
                title: "PM1.0 Number Concentration",
                value: "conc_pm1_0",
              },
            ]}
            title="PM Concentration"
            unit="ug/m3"
            aspect={5}
          />
        </Grid>

        <Grid item xs={12} lg={6} sx={{ mb: 2 }}>
          <MultiGraphBase
            values={[
              {
                title: "PM10 Mass Concentration",
                value: "mass_conc_pm10",
              },

              {
                title: "PM4.0 Mass Concentration",
                value: "mass_conc_pm4_0",
              },

              {
                title: "PM2.5 Mass Concentration",
                value: "mass_conc_pm2_5",
              },
              {
                title: "PM1.0 Mass Concentration",
                value: "mass_conc_pm1_0",
              },
            ]}
            title="PM Mass Concentration"
            unit="ug/m3"
            aspect={5}
          />
        </Grid>

        <Grid item xs={12} lg={12} sx={{ mb: 2 }}>
          <FrameList isShare={isShare} />
        </Grid>

        {!isShare && (
          <Grid item xs={12} lg={12}>
            <DeviceActionLogs />
          </Grid>
        )}
      </Grid>
    </>
  );
}
