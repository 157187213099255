import GaugeBase from "./GaugeBase";
import { Science } from "@mui/icons-material";
import { useTheme } from "@emotion/react";

export default function PHGuague(props) {
  const theme = useTheme();

  let defaultArch = [
    {
      limit: 0,
      color: "#FF0000",
    },
    {
      limit: 1,
      color: "#FF3300",
    },
    {
      limit: 2,
      color: "#FF6600",
    },
    {
      limit: 3,
      color: "#FF9900",
    },
    {
      limit: 4,
      color: "#FFCC00",
    },
    {
      limit: 5,
      color: "#FFFF00",
    },
    {
      limit: 6,
      color: "#99FF00",
    },
    {
      limit: 7,
      color: "#00FF00",
    },
    {
      limit: 8,
      color: "#00FF99",
    },
    {
      limit: 9,
      color: "#00FFFF",
    },
    {
      limit: 10,
      color: "#3399FF",
    },
    {
      limit: 11,
      color: "#3366FF",
    },
    {
      limit: 12,
      color: "#3333FF",
    },
    {
      limit: 13,
      color: "#6600FF",
    },
    {
      limit: 14,
      color: "#9900FF",
    },
  ];

  const { value, title } = props;

  return (
    <GaugeBase
      title={title}
      field={value}
      icon={
        <Science
          sx={{
            color: theme.palette.text.secondary,
            opacity: 0.8,
          }}
        />
      }
      defaultArch={defaultArch}
      min={0}
      max={14}
      unit="PH"
    />
  );
}
