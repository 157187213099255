//copy of device page with no auth and using the share api to fetch the data

import React, { useCallback, useEffect, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import { CONFIG } from "../config";
import axios from "axios";
import { useParams } from "react-router-dom";
import NoDeviceFoundDialog from "../components/Dialogs/NoDeviceFound";
import DateTimePickerDialog from "../components/Dialogs/DateTimePicker";
import moment from "moment";
import addHistoryMarkers from "../Functions/addHistoryMarkers";
import addCurrentLocation from "../Functions/addCurrentLocation";
import addPolyline from "../Functions/addPolyline";
import { useSelector } from "react-redux";
import { Container, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import { minMaxLngLat } from "../Functions/minMaxLngLat";
import { store } from "../store";
import isTracker from "../Functions/isTracker";
import setTitle from "../Functions/setTitle";
import SidebarShareMap from "../components/sidebarShareMap";
import { DEVICE_TYPE_COMPONENTS } from "../constants/deviceTypes";

export default function Share() {
  const mapRef = useRef(null);
  const [NoDeviceFound, setNoDeviceFound] = useState(false);
  const [openDateTimePicker, setOpenDateTimePicker] = useState(false);
  const [deviceData, setDeviceData] = useState({}); // eslint-disable-line no-unused-vars
  const isMobile = window.innerWidth <= 500;
  const [nonTracker, setNonTracker] = useState(false);
  const [deviceType, setDeviceType] = useState(0);
  const params = useParams();
  const deviceHistory = useSelector((state) => state.deviceHistory);
  const dateRange = useSelector((state) => state.dateRange);

  const [hasDetails, setHasDetails] = useState(false);

  const theme = useTheme();

  const updateHistoryInterval = useRef(null);

  const getDeviceHistory = useCallback(
    async (startDate, endDate) => {
      if (startDate === undefined || endDate === undefined) {
        startDate = moment().local().subtract(1, "day").toISOString();
        endDate = moment().local().endOf("day").toISOString();

        startDate = moment(startDate).unix();
        endDate = moment(endDate).unix();
      }

      try {
        //axois get with auth0 token header
        const response = await axios.get(
          CONFIG.API_URL + `/device/share/${params.id}/${startDate}/${endDate}`
        );

        let history = response.data;

        //sort history by time_created ordest to newest
        history.sort((a, b) => {
          return new Date(a.time_created) - new Date(b.time_created);
        });

        if (isTracker(deviceType)) {
          history = history.filter((point) => {
            let data = point.data;
            data = JSON.parse(data);

            if (data.latitude === null || data.longitude === null) {
              return false;
            }

            if (data.latitude === 0 || data.longitude === 0) {
              return false;
            }

            return true;
          });
        }
        store.dispatch({
          type: "deviceHistory/update",
          payload: history,
        });

        return history;
      } catch (e) {
        if (!e.response) {
          console.log(e);
          return [];
        }
        //if 404 show no device found dialog
        if (e.response.status === 404) {
          setNoDeviceFound(true);
        }
        console.log(e);
        return [];
      }
    },
    [deviceType, params.id]
  );

  const createMap = useCallback(async () => {
    if (mapRef.current) {
      return;
    }
    let history = await getDeviceHistory();
    let deviceProfile = await axios.get(
      CONFIG.API_URL + `/device/share/profile/${params.id}`
    );

    if (deviceProfile.data) {
      if (deviceProfile.data.profile) {
        deviceProfile = deviceProfile.data.profile;

        if (typeof deviceProfile === "string") {
          deviceProfile = JSON.parse(deviceProfile);
        }
      }
    }

    let coordinates = [];

    history.forEach((point) => {
      let data = point.data;
      data = JSON.parse(data);

      coordinates.push(minMaxLngLat([data.longitude, data.latitude]));
    });

    mapRef.current = new mapboxgl.Map({
      container: "map-share",
      style: CONFIG.mapStyle,
    });

    //add controls
    mapRef.current.addControl(new mapboxgl.NavigationControl(), "top-left");
    //add fullscreen
    mapRef.current.addControl(new mapboxgl.FullscreenControl(), "top-left");
    //add scale bottom right
    mapRef.current.addControl(new mapboxgl.ScaleControl(), "bottom-right");

    class DateTimePickerToggle {
      onAdd(map) {
        this._map = map;

        this._btn = document.createElement("button");
        this._btn.className = "mapboxgl-ctrl-icon mapboxgl-ctrl-datePicker";
        this._btn.title = "Select Time Period";
        this._btn.type = "button";
        this._btn["aria-label"] = "Set Date/Time Range";
        this._btn.onclick = function () {
          setOpenDateTimePicker(!openDateTimePicker);
        };

        this._container = document.createElement("div");
        this._container.className = "mapboxgl-ctrl-group mapboxgl-ctrl";
        this._container.appendChild(this._btn);

        return this._container;
      }

      onRemove() {
        this._container.parentNode.removeChild(this._container);
        this._map = undefined;
      }
    }

    const dateTimePickerToggle = new DateTimePickerToggle();

    mapRef.current.addControl(dateTimePickerToggle, "top-left");

    mapRef.current.on("load", () => {
      if (history.length > 0) {
        addPolyline(mapRef.current, history, deviceProfile);
        addHistoryMarkers(mapRef.current, history);
        addCurrentLocation(mapRef.current, history);

        let bounds = new mapboxgl.LngLatBounds();
        coordinates.forEach((point) => {
          bounds.extend(point);
        });

        //fit the map to the bounds
        mapRef.current.fitBounds(bounds, {
          padding: isMobile ? 50 : 20,
          maxZoom: 17,
          essential: true,
          duration: 0,
          animated: false,
        });
      }
    });
  }, [getDeviceHistory, isMobile, openDateTimePicker, params.id]);

  const updateHistorySource = useCallback(async () => {
    //get the history
    setOpenDateTimePicker(false);

    let history = await getDeviceHistory(
      dateRange.startDate,
      dateRange.endDate
    );

    if (history.length === 0) {
      return;
    }

    addPolyline(mapRef.current, history);
    addHistoryMarkers(mapRef.current, history);
    addCurrentLocation(mapRef.current, history);

    let bounds = new mapboxgl.LngLatBounds();

    history.forEach((point) => {
      let data = point.data;
      data = JSON.parse(data);

      bounds.extend(minMaxLngLat([data.longitude, data.latitude]));
    });

    //fit the map to the bounds
    mapRef.current.fitBounds(bounds, {
      padding: isMobile ? 50 : 200,
      maxZoom: 15,
    });
  }, [getDeviceHistory, dateRange, isMobile]);

  async function checkIfContainsDetails(history) {
    if (history.length === 0) {
      setHasDetails(false);
      return;
    }
    //check if data contains temperature, temp, or temp_internal
    let containsDetails = false;

    let lastData = history[history.length - 1];
    let lastDataJson = JSON.parse(lastData.data);

    if (
      lastDataJson.temperature ||
      lastDataJson.temp ||
      lastDataJson.temp_internal
    ) {
      containsDetails = true;
    }

    setHasDetails(containsDetails);
  }

  const getDevice = useCallback(async () => {
    try {
      //axois get with auth0 token header
      const response = await axios.get(
        CONFIG.API_URL + `/device/share/info/${params.id}`
      );

      setDeviceData(response.data);

      let resDeviceProfile = await axios.get(
        CONFIG.API_URL + `/device/share/profile/${params.id}`
      );

      if (resDeviceProfile.data) {
        if (resDeviceProfile.data.profile) {
          resDeviceProfile = resDeviceProfile.data.profile;

          if (typeof resDeviceProfile === "string") {
            resDeviceProfile = JSON.parse(resDeviceProfile);
          }

          store.dispatch({
            type: "deviceProfile/update",
            payload: resDeviceProfile,
          });
        }
      }

      setTitle(
        response.data && response.data.name
          ? response.data.name
          : "Share Device"
      );

      setDeviceType(response.data.device_type);

      //if device_type = 1, 4, 7 then create map
      if (isTracker(response.data.device_type)) {
        createMap();
      } else {
        setNonTracker(true);
        getDeviceHistory();
      }
    } catch (e) {
      if (!e.response) {
        console.log(e);
        return [];
      }
      //if 404 show no device found dialog
      if (e.response.status === 404) {
        setNoDeviceFound(true);
      }
      console.log(e);
      return [];
    }
  }, [getDeviceHistory, params.id, createMap]);

  //use effect to fetch the data
  useEffect(() => {
    let mounted = true;

    if (mounted) {
      getDevice();

      //start the interval to update the history every 60 seconds
      updateHistoryInterval.current = setInterval(() => {
        getDeviceHistory();
      }, 60000);
    }

    return () => {
      mounted = false;
      clearInterval(updateHistoryInterval.current);
    };
  }, [getDevice, getDeviceHistory]);

  useEffect(() => {
    if (mapRef.current) {
      updateHistorySource();
    }
  }, [dateRange, updateHistorySource]);

  useEffect(() => {
    if (isTracker(deviceType)) {
      checkIfContainsDetails(deviceHistory);
    }
  }, [deviceHistory, deviceType]);

  if (nonTracker === false) {
    return (
      <Container
        maxWidth="false"
        disableGutters
        sx={{
          backgroundColor:
            theme.palette.mode === "dark" ? "#121212" : "#EFEFEF",
          minHeight: "100vh",
          padding: hasDetails ? 1 : 0,
        }}
      >
        <div
          id="map-share"
          className={hasDetails ? "map-details" : "map-nodetails"}
        />
        <SidebarShareMap />
        <NoDeviceFoundDialog open={NoDeviceFound} />
        <DateTimePickerDialog
          open={openDateTimePicker}
          onClose={() => setOpenDateTimePicker(false)}
          trackerStartDate={dateRange.startDate}
          trackerEndDate={dateRange.endDate}
        />
      </Container>
    );
  }

  const DeviceComponent = DEVICE_TYPE_COMPONENTS[deviceType];

  return (
    <Container
      sx={{
        backgroundColor: theme.palette.mode === "dark" ? "#121212" : "#EFEFEF",
        minHeight: "100vh",
        px: 1,
      }}
      maxWidth="false"
      disableGutters
    >
      <Typography
        variant="h4"
        sx={{ color: theme.palette.mode === "dark" ? "#fff" : "#000", pt: 1 }}
      >
        {deviceData.name}
      </Typography>
      {DeviceComponent && <DeviceComponent isShare />}
    </Container>
  );
}
