import React from "react";

import DeviceMetric from "./DeviceMetric";
import { useSelector } from "react-redux";

const RISK_COLORS = {
  "Low Risk": "green",
  "Moderate Risk": "#FFA500",
  "High Risk": "red",
};

export default function MoldIndex() {
  const deviceHistory = useSelector((state) => state.deviceHistory);
  const themeMode = useSelector((state) => state.themeMode);
  const isMobile = useSelector((state) => state.isMobile);

  const defaultColor = themeMode === "light" ? "black" : "white";

  const getMoldIndex = () => {
    if (!deviceHistory?.length) return "";

    try {
      const lastData = deviceHistory[deviceHistory.length - 1];
      const { mold_index } = JSON.parse(lastData.data);
      return mold_index ?? "Low Risk";
    } catch (error) {
      console.error("Error parsing mold index:", error);
      return "Low Risk";
    }
  };

  const moldIndex = getMoldIndex();
  const colour = RISK_COLORS[moldIndex] || defaultColor;

  return (
    <DeviceMetric
      title="Mold Risk"
      value="mold_index"
      icon="virus"
      iconSize={isMobile ? "3x" : "6x"}
      aspect={10}
      metricWidth={3}
      graphWidth={12}
      unit=""
      textsize="h3"
      valueColor={colour}
    />
  );
}
