import { useState, useEffect, useCallback } from "react";
import { fetchDevice } from "../Functions/fetchDevice";
import { fetchDeviceHistory } from "../Functions/fetchDeviceHistory";
import { fetchDeviceAlerts } from "../Functions/fetchDeviceAlerts";
import { fetchDeviceProfile } from "../Functions/fetchDeviceProfile";

export const useDeviceData = (deviceId, dateRange, refreshInterval) => {
  const [loading, setLoading] = useState(true);

  const fetchData = useCallback(async () => {
    try {
      await Promise.all([
        fetchDevice(deviceId),
        fetchDeviceHistory(deviceId, dateRange.startDate, dateRange.endDate),
        fetchDeviceAlerts(deviceId, dateRange.startDate, dateRange.endDate),
        fetchDeviceProfile(deviceId),
      ]);
    } catch (error) {
      console.error("Error fetching device data:", error);
    }
  }, [deviceId, dateRange]);

  useEffect(() => {
    let mounted = true;
    const intervalId = setInterval(() => {
      if (mounted) fetchData();
    }, refreshInterval * 60 * 1000);

    fetchData().finally(() => {
      if (mounted) setLoading(false);
    });

    return () => {
      mounted = false;
      clearInterval(intervalId);
      fetchDevice();
      fetchDeviceHistory();
    };
  }, [fetchData, refreshInterval]);

  return { loading };
};
