import React from "react";
import {
  Box,
  Card,
  CardContent,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useSelector } from "react-redux";

export default function WaterLeakDetectedCard(props) {
  const deviceHistory = useSelector((state) => state.deviceHistory);
  const [leak, setLeak] = React.useState(false);

  const theme = useTheme();

  React.useEffect(() => {
    if (!deviceHistory || deviceHistory.length === 0) {
      return;
    }

    try {
      let lastData = deviceHistory[deviceHistory.length - 1];

      let jsonData = lastData.data;
      jsonData = JSON.parse(jsonData);

      if (props.field) {
        //get the value of the field in the json data
        jsonData = jsonData[props.field];

        if (props.string) {
          //NoLeak Medium or Large
          let formatted_text = "";
          if (jsonData === "NoLeak") {
            formatted_text = "No Leak";
          } else {
            formatted_text = jsonData;
          }

          setLeak(formatted_text);
        } else {
          if (jsonData === "Leak") {
            setLeak(true);
          } else {
            setLeak(false);
          }
        }
      } else {
        jsonData.water_leak === 1 ? setLeak(true) : setLeak(false);
      }
    } catch (error) {
      console.log(error);
    }
  }, [deviceHistory, props.field, props.string]);

  return (
    <Card>
      <CardContent>
        <Box sx={{ mb: 2 }}>
          <Typography
            variant="subtitle"
            sx={{
              // if light theme then color is black, else dark grey
              color: theme.palette.mode === "light" ? "#000" : "#616161",
            }}
          >
            Leak Detected?
          </Typography>
        </Box>
        <Tooltip
          title={
            props.string
              ? `Leak Status: ${leak}`
              : leak
              ? `Leak Detected: Yes`
              : `Leak Detected: No`
          }
        >
          <Typography
            variant="p"
            sx={{ fontSize: "1.2rem" }}
            color={
              props.string
                ? leak === "No Leak"
                  ? "green"
                  : "error"
                : leak
                ? "error"
                : "primary"
            }
            className="cursor-pointer"
          >
            {props.string ? `${leak}` : leak ? "Yes" : "No"}
          </Typography>
        </Tooltip>
      </CardContent>
    </Card>
  );
}
