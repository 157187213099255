import { formatDate } from "../../Functions/formatDate";
import DeviceMetric from "./DeviceMetric";

export default function MotionCard(props) {
  const { showTime } = props;

  return (
    <>
      <DeviceMetric
        title="Motion Detected?"
        value="motion"
        trueFalse={true}
        trueValue="Motion Detected"
        falseValue="No Motion"
        icon="person-walking"
        height="48%"
      />
      {showTime && (
        <DeviceMetric
          title="Last Motion Detected"
          value="time"
          formatter={formatDate}
          icon="clock"
          height="50%"
        />
      )}
    </>
  );
}
