import { Box, Grid, LinearProgress, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import convertMvToPercent from "../Functions/convertMvToPercent";
import { useTheme } from "@emotion/react";

export default function DeviceInfoBatteryLevel(props) {
  const { device } = props;
  const { t } = useTranslation("common");
  const theme = useTheme();

  let level = 0;

  let last_data = device.last_data;

  if (last_data) {
    //parse the data
    let data = JSON.parse(last_data);
    level = data.voltageMv;
  }

  return (
    <Box sx={{ display: "flex" }}>
      <Typography
        sx={{
          minWidth: "110px",
          color: theme.palette.text.secondary,
          letterSpacing: "0.02em",
          fontSize: "0.875rem",
        }}
      >
        <strong>{t("devices.battery_level")}:</strong>
      </Typography>

      {isNaN(level) ? (
        <Typography>{level}</Typography>
      ) : (
        <Grid
          container
          direction="row"
          justifyContent="right"
          //vertial align center
          alignItems="center"
        >
          <Grid item xs={12} md={8}>
            <LinearProgress
              variant="determinate"
              value={convertMvToPercent(
                device.batteryMv,
                device.device_brand,
                false
              )}
              color={
                convertMvToPercent(level, device.device_brand, false) < 20
                  ? "error"
                  : convertMvToPercent(level, device.device_brand, false) < 60
                  ? "warning"
                  : "primary"
              }
              max={100}
              min={0}
              sx={{ height: 6, borderRadius: "12px", mr: 2 }}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <Typography variant="body2" align="center">
              {convertMvToPercent(level, device.device_brand)}
            </Typography>
          </Grid>
        </Grid>
      )}
    </Box>
  );
}
