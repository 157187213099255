import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { fetchDevices } from "../Functions/fetchDevices";
import { formatDate } from "../Functions/formatDate";
import updateSnackbarMessage from "../Functions/updateSnackbarMessage";

export const useDeviceActions = (setAddDeviceOpen) => {
  const { t } = useTranslation("common");

  const handleRefresh = useCallback(() => {
    fetchDevices(localStorage.getItem("selectedOrganisationID"));
    updateSnackbarMessage(
      `${t("common.refreshed")} ${formatDate(moment().toISOString())}`
    );
  }, [t]);

  const handleDeviceDialogClose = useCallback(() => {
    setAddDeviceOpen(false);
    fetchDevices(localStorage.getItem("selectedOrganisationID"));
  }, [setAddDeviceOpen]);

  return {
    handleRefresh,
    handleDeviceDialogClose,
  };
};
