import { useSelector } from "react-redux";
import DeviceMetric from "./DeviceMetric";

export default function DistanceCard() {
  const deviceHistory = useSelector((state) => state.deviceHistory);

  let row =
    deviceHistory && deviceHistory.length > 0
      ? deviceHistory[deviceHistory.length - 1].data
      : null;

  if (row) {
    row = JSON.parse(row);
  }

  const distance = row && row.distance ? row.distance : "N/A";

  return (
    <>
      <DeviceMetric title="Distance" value={distance} unit="mm" icon="ruler" />
    </>
  );
}
