import { Grid } from "@mui/material";
import React from "react";
import LastSeen from "../../Cards/LastSeen";
import BatteryStatus from "../../Cards/BatteryStatus";
import LastAlert from "../../Cards/LastAlert";
import TemperatureGuague from "../../Guages/Temperature";
import DeviceActionLogs from "../../Cards/Alerts";
import FrameList from "../../FrameList";
import SoilMoistureGuague from "../../Guages/SoilMoisture";
import ConductivityGuague from "../../Guages/Conductivity";
import GraphBase from "../../Graphs/GraphBase";

export default function DraginoLES01(props) {
  let { isShare } = props;

  return (
    <>
      <Grid
        container
        spacing={2}
        alignItems={"stretch"}
        alignContent={"stretch"}
        sx={{ mt: 1 }}
      >
        <Grid item xs={12} md={!isShare ? 4 : 6} sx={{ mb: 2 }}>
          <LastSeen />
        </Grid>
        <Grid item xs={12} md={!isShare ? 4 : 6} sx={{ mb: 2 }}>
          <BatteryStatus />
        </Grid>
        {!isShare && (
          <Grid item xs={12} md={4} sx={{ mb: 2 }}>
            <LastAlert />
          </Grid>
        )}

        <Grid item xs={12} md={3} sx={{ mb: 2 }}>
          <TemperatureGuague field="soil_temperature" />
        </Grid>
        <Grid item xs={12} md={9} sx={{ mb: 2 }}>
          <GraphBase
            value="soil_temperature"
            title="Soil Temperature"
            unit={
              localStorage.getItem("temperature_unit") === "F" ? "°F" : "°C"
            }
            type="temperature"
            aspect={3.5}
          />
        </Grid>

        <Grid item xs={12} md={3} sx={{ mb: 2 }}>
          <SoilMoistureGuague field="soil_moisture" />
        </Grid>
        <Grid item xs={12} md={9} sx={{ mb: 2 }}>
          <GraphBase
            value="soil_moisture"
            title="Soil Moisture"
            unit=""
            aspect={3.5}
          />
        </Grid>

        <Grid item xs={12} md={3} sx={{ mb: 2 }}>
          <ConductivityGuague value="soil_conductivity" />
        </Grid>

        <Grid item xs={12} md={9} sx={{ mb: 2 }}>
          <GraphBase
            value="soil_conductivity"
            title="Soil Conductivity"
            unit=""
            aspect={3.5}
          />
        </Grid>

        <Grid item xs={12} md={isShare ? 12 : 8} sx={{ mb: 2 }}>
          <FrameList />
        </Grid>

        {!isShare && (
          <>
            <Grid item xs={12} md={4}>
              <DeviceActionLogs />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}
