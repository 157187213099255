import { useState, useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";

export const useUrlStates = () => {
  const location = useLocation();
  const [isShare, setIsShare] = useState(() =>
    window.location.href.includes("share")
  );

  const handleRouteChange = useCallback(() => {
    const currentPath = location.pathname;
    setIsShare(currentPath.includes("share"));
  }, [location.pathname]);

  useEffect(() => {
    handleRouteChange();
  }, [handleRouteChange, location]);

  return { isShare };
};
