import { useTheme } from "@emotion/react";
import { FirstPage, PlayArrow, PunchClock, Stop } from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  Slider,
  Stack,
  Typography,
} from "@mui/material";
import React, { useMemo, useCallback } from "react";
import { formatDate } from "../../Functions/formatDate";
import cleanAddress from "../../Functions/cleanAddress";
import { useSelector } from "react-redux";
import { updateCurrent } from "../../Functions/updateCurrent";
import { addCurrent } from "../../Functions/addCurrent";

export default function TimelineCard() {
  const theme = useTheme();
  const deviceHistory = useSelector((state) => state.deviceHistory);
  const current = useSelector((state) => state.current);
  const interval = React.useRef(null);

  const currentLocation = useMemo(() => {
    if (!deviceHistory[current]) return "No data";
    return `${formatDate(deviceHistory[current].time_created)} - ${cleanAddress(
      deviceHistory[current]
    )}`;
  }, [deviceHistory, current]);

  const startPlayback = useCallback(() => {
    interval.current = setInterval(() => {
      addCurrent();
      console.log("adding 1 to current");
    }, 1000);
  }, []);

  const stopPlayback = useCallback(() => {
    clearInterval(interval.current);
  }, []);

  const restartPlayback = useCallback(() => {
    updateCurrent(0);
    clearInterval(interval.current);
    startPlayback();
  }, [startPlayback]);

  const handleSliderChange = useCallback((_, value) => {
    updateCurrent(value);
  }, []);

  React.useEffect(() => {
    if (current === deviceHistory.length - 1) {
      clearInterval(interval.current);
    }

    if (current > deviceHistory.length) {
      updateCurrent(deviceHistory.length);
      clearInterval(interval.current);
    }
  }, [current, deviceHistory]);

  const sliderMarks = useMemo(
    () => deviceHistory.map((_, index) => ({ value: index })),
    [deviceHistory]
  );

  const commonIconStyle = useMemo(
    () => ({
      color: theme.palette.success.main,
      "&:hover": {
        color: theme.palette.secondary.main,
        cursor: "pointer",
      },
    }),
    [theme.palette.success.main, theme.palette.secondary.main]
  );

  return (
    <Card
      elevation={0}
      sx={{
        height: "100%",
        background:
          theme.palette.mode === "dark"
            ? "linear-gradient(145deg, rgba(40,40,45,0.9) 0%, rgba(35,35,40,0.8) 100%)"
            : "linear-gradient(145deg, rgba(255,255,255,0.9) 0%, rgba(245,245,245,0.8) 100%)",
        backdropFilter: "blur(10px)",
        border: `1px solid ${
          theme.palette.mode === "dark"
            ? "rgba(255,255,255,0.1)"
            : "rgba(0,0,0,0.1)"
        }`,
        borderRadius: "16px",
        transition: "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
      }}
    >
      <CardContent>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            mb: 1,
          }}
        >
          <PunchClock
            sx={{
              color: theme.palette.text.secondary,
              opacity: 0.8,
            }}
          />
          <Typography
            variant="subtitle2"
            sx={{
              color: theme.palette.text.secondary,
              letterSpacing: "0.02em",
              fontWeight: 500,
              flexGrow: 1,
            }}
          >
            Timeline
          </Typography>

          <Typography
            variant="body2"
            sx={{ pt: 1, color: theme.palette.text.secondary }}
          >
            {currentLocation}
          </Typography>
        </Box>
        <Slider
          min={1}
          max={deviceHistory.length - 1 || 0}
          step={1}
          onChange={handleSliderChange}
          value={current || 0}
          marks={sliderMarks}
          sx={{
            color: theme.palette.success.main,
            "& .MuiSlider-mark": {
              backgroundColor: theme.palette.success.main,
            },
          }}
        />
        <Stack
          direction="row"
          spacing={5}
          justifyContent="center"
          alignItems="stretch"
        >
          <PlayArrow onClick={startPlayback} sx={commonIconStyle} />
          <Stop onClick={stopPlayback} sx={commonIconStyle} />
          <FirstPage onClick={restartPlayback} sx={commonIconStyle} />
        </Stack>
      </CardContent>
    </Card>
  );
}
