import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import DeviceMetric from "./DeviceMetric";

export default function OpenCount(props) {
  const [lastCount, setLastCount] = React.useState(0);

  const { val = "count", title = "Times Opened" } = props;

  const deviceHistory = useSelector((state) => state.deviceHistory);

  useEffect(() => {
    if (deviceHistory.length > 0) {
      let lastData = deviceHistory[deviceHistory.length - 1];
      let jsonData = lastData.data;
      jsonData = JSON.parse(jsonData);
      setLastCount(jsonData[val]);
    }
  }, [deviceHistory, val]);

  return <DeviceMetric title={title} value={lastCount} icon={"door-open"} />;
}
