import React, { memo } from "react";
import { Box } from "@mui/material";
import DeviceMap from "./Maps/Device";
import DeviceInfo from "./Cards/DeviceInfo";
import isTracker from "../Functions/isTracker";

const DeviceContent = memo(({ device, isMobile, DeviceComponent }) => {
  const isTrackerDevice = device.device_type && isTracker(device.device_type);

  return (
    <>
      {isTrackerDevice && (
        <Box sx={{ mb: 2, mt: 1 }} className="topbox">
          <DeviceMap />
          {!isMobile && <DeviceInfo />}
        </Box>
      )}
      {DeviceComponent && <DeviceComponent />}
    </>
  );
});

export default DeviceContent;
