import React from "react";
import { Grid } from "@mui/material";
import DeviceActionLogs from "../Cards/Alerts";
import LastAlert from "../Cards/LastAlert";
import BatteryStatus from "../Cards/BatteryStatus";
import LastSeen from "../Cards/LastSeen";
import FrameList from "../FrameList";
import WaterTotalVolume from "../Cards/WaterTotalVolume";
import TemperatureGuague from "../Guages/Temperature";
import WaterLeakDetectedCard from "../Cards/WaterLeak";
import WaterMinMaxAmbinetGraph from "../Graphs/WaterMinMaxAmbient";
import TotalVolumeGuage from "../Guages/totalVolume";

export default function WaterFlow(props) {
  let { isShare } = props;

  return (
    <>
      <Grid
        container
        spacing={2}
        alignItems={"stretch"}
        alignContent={"stretch"}
        sx={{ mt: 1 }}
      >
        <Grid item xs={12} md={isShare ? 6 : 3} sx={{ mb: 2 }}>
          <LastSeen />
        </Grid>
        <Grid item xs={12} md={3} sx={{ mb: 2 }}>
          <WaterLeakDetectedCard field={"leak_status"} string />
        </Grid>
        <Grid item xs={12} md={isShare ? 6 : 3} sx={{ mb: 2 }}>
          <BatteryStatus field={"battery_status"} string />
        </Grid>

        {!isShare && (
          <>
            <Grid item xs={12} md={3} sx={{ mb: 2 }}>
              <LastAlert />
            </Grid>
          </>
        )}

        <Grid item xs={12} md={4} sx={{ mb: 2 }}>
          <TemperatureGuague field="ambient_temp" title="Ambient Temperature" />
        </Grid>
        <Grid item xs={12} md={4} sx={{ mb: 2 }}>
          <TemperatureGuague
            field="water_temp_min"
            title="Water Temperature Min"
          />
        </Grid>

        <Grid item xs={12} md={4} sx={{ mb: 2 }}>
          <TemperatureGuague
            field="water_temp_max"
            title="Water Temperature Max"
          />
        </Grid>

        <Grid item xs={12} md={3} sx={{ mb: 2 }}>
          <TotalVolumeGuage />
        </Grid>
        <Grid item xs={12} md={9} sx={{ mb: 2 }}>
          <WaterTotalVolume field="total_volume" />
        </Grid>

        <Grid item xs={12} md={12} sx={{ mb: 2 }}>
          <WaterMinMaxAmbinetGraph />
        </Grid>

        <Grid item xs={12} md={!isShare ? 8 : 12} sx={{ mb: 2 }}>
          <FrameList />
        </Grid>

        {!isShare && (
          <Grid item xs={12} md={4} sx={{ mb: 2 }}>
            <DeviceActionLogs />
          </Grid>
        )}
      </Grid>
    </>
  );
}
