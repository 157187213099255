import { useTheme } from "@emotion/react";
import { Box, Card, CardContent, Typography } from "@mui/material";
import React, { useEffect } from "react";
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
  Area,
  ReferenceArea,
} from "recharts";
import { useSelector } from "react-redux";
import { formatDate, formatDateOnly } from "../../Functions/formatDate";
import { Equalizer } from "@mui/icons-material";

export default function MoldIndexGraph(props) {
  const [moldIndexHistory, setMoldIndexHistory] = React.useState([]);

  const deviceHistory = useSelector((state) => state.deviceHistory);
  const isMobile = useSelector((state) => state.isMobile);

  //we want to mock this data in device history for now when the page loads, so call store.dispatch with the mock data below

  const theme = useTheme();
  const { field } = props;

  useEffect(() => {
    try {
      let tempHistory = [];
      deviceHistory.forEach((dataObject) => {
        let jsonData = dataObject.data;
        jsonData = JSON.parse(jsonData);
        let time_created = dataObject.time_created;
        let moldIndex;

        if (field) {
          moldIndex = jsonData[field];
        } else {
          moldIndex = jsonData.mold_index;
        }

        tempHistory.push({
          Date: time_created,
          MoldIndex: moldIndex,
        });
      });
      setMoldIndexHistory(tempHistory);
    } catch (error) {}
  }, [deviceHistory, field]);

  return (
    <Card
      elevation={0}
      sx={{
        height: "100%",
        background:
          theme.palette.mode === "dark"
            ? "linear-gradient(145deg, rgba(40,40,45,0.9) 0%, rgba(35,35,40,0.8) 100%)"
            : "linear-gradient(145deg, rgba(255,255,255,0.9) 0%, rgba(245,245,245,0.8) 100%)",
        backdropFilter: "blur(10px)",
        border: `1px solid ${
          theme.palette.mode === "dark"
            ? "rgba(255,255,255,0.1)"
            : "rgba(0,0,0,0.1)"
        }`,
        borderRadius: "16px",
      }}
    >
      <CardContent>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            mb: 2,
          }}
        >
          <Equalizer
            sx={{
              color: theme.palette.text.secondary,
              opacity: 0.8,
            }}
          />
          <Typography
            variant="subtitle2"
            sx={{
              color: theme.palette.text.secondary,
              letterSpacing: "0.02em",
              fontWeight: 500,
            }}
          >
            Mold Risk History
          </Typography>
        </Box>
        <ResponsiveContainer
          width="98%"
          aspect={!isMobile ? 6 : 2}
          sx={{ ml: 2 }}
        >
          <AreaChart
            data={moldIndexHistory}
            margin={{ top: 0, right: 30, left: 0, bottom: 0 }}
          >
            <CartesianGrid strokeDasharray="3 3" />

            <XAxis
              dataKey="Date"
              type={"category"}
              tickFormatter={(value) => {
                return formatDateOnly(value);
              }}
            />
            <YAxis
              dataKey="MoldIndex"
              type="category"
              yAxisId="1"
              domain={["Low Risk", "Moderate Risk", "High Risk"]}
              width={120}
            />
            <Tooltip
              contentStyle={{
                backgroundColor: theme.palette.background.default,
              }}
              cursor={false}
              labelFormatter={(label) => {
                return formatDate(label);
              }}
              formatter={(value, name) => {
                return [value, "Mold Index"];
              }}
            />

            <ReferenceArea
              yAxisId="1"
              y1={"Low Risk"}
              y2={"Moderate Risk"}
              strokeOpacity={0.1}
              fill="green"
              fillOpacity={0.17}
            />
            <ReferenceArea
              yAxisId="1"
              y1={"Moderate Risk"}
              y2={"High Risk"}
              fill="red"
              fillOpacity={0.17}
            />
            <Area
              yAxisId="1"
              dataKey="MoldIndex"
              stroke={theme.palette.primary.main}
              animationDuration={300}
              fill={theme.palette.primary.main}
              strokeWidth={2}
              dominantBaseline={"middle"}
            />
          </AreaChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
}
