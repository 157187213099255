import { useTheme } from "@emotion/react";
import { Box, Card, CardContent, Typography } from "@mui/material";
import React, { useEffect, useMemo } from "react";
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";
import { useSelector } from "react-redux";
import formatTemperature from "../../Functions/formatTemperature";
import formatSpeed from "../../Functions/formatSpeed";
import moment from "moment";
import { formatDate, formatDateOnly } from "../../Functions/formatDate";
import { Equalizer } from "@mui/icons-material";

const aggregateDataByHour = (data, value, trueFalse, trueV, falseV) => {
  // Group data by hour using a Map
  const hourlyGroups = new Map();

  data.forEach((item) => {
    // Get the start of the hour for the current date
    const hourKey = moment(item.Date).startOf("hour").format();

    if (!hourlyGroups.has(hourKey)) {
      hourlyGroups.set(hourKey, []);
    }

    hourlyGroups.get(hourKey).push(item[value]);
  });

  // Calculate most common value or average for each hour
  const aggregatedData = Array.from(hourlyGroups, ([date, values]) => {
    if (trueFalse) {
      // For boolean values, count occurrences and use most frequent
      const trueCount = values.filter(
        (val) => val === true || val === trueV
      ).length;
      const falseCount = values.filter(
        (val) => val === false || val === falseV
      ).length;
      const mostCommonValue = trueCount >= falseCount ? trueV : falseV;

      return {
        Date: date,
        [value]: mostCommonValue,
      };
    } else {
      // For numeric data, calculate average and round to 2 decimal places
      const avgValue =
        values.reduce((sum, val) => sum + (Number(val) || 0), 0) /
        values.length;

      return {
        Date: date,
        [value]: Number.isNaN(avgValue)
          ? values[0]
          : Number(avgValue.toFixed(2)),
      };
    }
  });

  // Sort by date
  return aggregatedData.sort(
    (a, b) => moment(a.Date).valueOf() - moment(b.Date).valueOf()
  );
};

export default function GraphBase({
  value,
  title,
  unit = "",
  min,
  max,
  type,
  trimValue = false,
  truefalse,
  trueV = "True",
  falseV = "False",
  trimNumber = 1,
  aspect = 5,
  aggregationType = "raw", // Add this prop with default 'raw'
  data = null,
}) {
  const [history, setHistory] = React.useState([]);

  const isMobile = useSelector((state) => state.isMobile);

  const processedHistory = useMemo(() => {
    if (!history.length) return [];

    return aggregationType === "hourly"
      ? aggregateDataByHour(history, value, truefalse, trueV, falseV)
      : history;
  }, [history, value, aggregationType, falseV, trueV, truefalse]);

  const deviceHistory = useSelector((state) => state.deviceHistory);
  const theme = useTheme();

  useEffect(() => {
    if (!deviceHistory || deviceHistory.length === 0) {
      return;
    }

    try {
      let tempHistory = [];
      deviceHistory.forEach((dataObject) => {
        let jsonData = JSON.parse(dataObject.data);
        let valueTemp = jsonData[value];

        if (Array.isArray(valueTemp)) {
          valueTemp = valueTemp[0];
        }

        if (valueTemp !== undefined && valueTemp !== null && valueTemp !== "") {
          if (trimValue && valueTemp !== null) {
            // Convert to string if not already and trim the specified number of characters from the end
            valueTemp = String(valueTemp).slice(0, -trimNumber);
            // Convert back to number if the original value was numeric
            if (!isNaN(valueTemp)) {
              valueTemp = Number(valueTemp);
            }
          }

          if (type === "temperature") {
            valueTemp = formatTemperature(valueTemp, true);
          }

          if (value === "speedKmph") {
            valueTemp = formatSpeed(valueTemp, false);
          }

          if (truefalse) {
            valueTemp = valueTemp ? trueV : falseV;
          }

          tempHistory.push({
            Date: dataObject.time_created,
            [value]: valueTemp,
          });
        }
      });

      setHistory(tempHistory);
    } catch (error) {
      console.error("Error processing device history:", error);
    }
  }, [
    deviceHistory,
    value,
    type,
    trimValue,
    truefalse,
    min,
    max,
    trueV,
    falseV,
    trimNumber,
    data,
  ]);

  const formatTooltipName = useMemo(
    () => (name) => {
      return title.replace(/_/g, " ").replace(/\b\w/g, (l) => l.toUpperCase());
    },
    [title]
  );

  return (
    <Card
      elevation={0}
      sx={{
        height: "100%",
        background:
          theme.palette.mode === "dark"
            ? "linear-gradient(145deg, rgba(40,40,45,0.9) 0%, rgba(35,35,40,0.8) 100%)"
            : "linear-gradient(145deg, rgba(255,255,255,0.9) 0%, rgba(245,245,245,0.8) 100%)",
        backdropFilter: "blur(10px)",
        border: `1px solid ${
          theme.palette.mode === "dark"
            ? "rgba(255,255,255,0.1)"
            : "rgba(0,0,0,0.1)"
        }`,
        borderRadius: "16px",
      }}
    >
      <CardContent>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            mb: 2,
          }}
        >
          <Equalizer
            sx={{
              color: theme.palette.text.secondary,
              opacity: 0.8,
            }}
          />
          <Typography
            variant="subtitle2"
            sx={{
              color: theme.palette.text.secondary,
              letterSpacing: "0.02em",
              fontWeight: 500,
            }}
          >
            {title + " History"}
          </Typography>
        </Box>
        <ResponsiveContainer width="100%" aspect={!isMobile ? aspect : 2}>
          <AreaChart
            data={processedHistory}
            margin={{ top: 0, right: 30, left: 0, bottom: 0 }}
          >
            <CartesianGrid
              strokeDasharray="3 3"
              stroke={
                theme.palette.mode === "dark"
                  ? "rgba(255,255,255,0.1)"
                  : "rgba(0,0,0,0.1)"
              }
            />
            <defs>
              <linearGradient id="Temperature" x1="0" y1="0" x2="0" y2="1">
                <stop
                  offset="5%"
                  stopColor={theme.palette.primary.main}
                  stopOpacity={0.3}
                />
                <stop
                  offset="95%"
                  stopColor={theme.palette.primary.main}
                  stopOpacity={0.05}
                />
              </linearGradient>
            </defs>
            <XAxis
              dataKey="Date"
              type="category"
              stroke={theme.palette.text.secondary}
              fontSize="0.75rem"
              tickFormatter={(value) => {
                return formatDateOnly(value);
              }}
            />
            <YAxis
              dataKey={value}
              type={truefalse ? "category" : "number"}
              domain={truefalse ? [falseV, trueV] : [min, max]}
              yAxisId="1"
              stroke={theme.palette.text.secondary}
              fontSize="0.75rem"
              tickFormatter={(value) => {
                if (type === "temperature") {
                  return value + formatTemperature(0, false, true);
                }
                if (value === "speedKmph") {
                  return formatSpeed(value, false);
                }
                return value + unit;
              }}
            />
            <Tooltip
              contentStyle={{
                backgroundColor:
                  theme.palette.mode === "dark"
                    ? "rgba(40,40,45,0.95)"
                    : "rgba(255,255,255,0.95)",
                borderRadius: "8px",
                border: `1px solid ${
                  theme.palette.mode === "dark"
                    ? "rgba(255,255,255,0.1)"
                    : "rgba(0,0,0,0.1)"
                }`,
                boxShadow:
                  theme.palette.mode === "dark"
                    ? "0 4px 12px rgba(0,0,0,0.4)"
                    : "0 4px 12px rgba(0,0,0,0.1)",
              }}
              cursor={false}
              formatter={(value, name) => [
                type === "temperature"
                  ? value + formatTemperature(0, false, true)
                  : value + unit,
                formatTooltipName(name),
              ]}
              labelFormatter={(label) => {
                return formatDate(label);
              }}
            />
            <Area
              yAxisId="1"
              dataKey={value}
              stroke={theme.palette.primary.main}
              strokeWidth={2}
              fill="url(#Temperature)"
              animationDuration={300}
              dominantBaseline="middle"
            />
          </AreaChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
}
