//get and display all alerts for a device
import React from "react";
import DataTableBase from "../DataTableBase";
import { formatDate } from "../../Functions/formatDate";
import { Card, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export default function DeviceActionLogs() {
  const { t } = useTranslation("common");

  const theme = useTheme();

  const deviceAlerts = useSelector((state) => state.deviceAlerts);

  const alertTableColumns = [
    {
      name: "id",
      label: t("common.id"),
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },

    {
      name: "time_created",
      label: t("common.received"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return <Typography>{formatDate(value, "LLL")}</Typography>;
        },
      },
    },
    //message, time_created
    {
      name: "message",
      label: t("actions.singular"),
      options: {
        filter: true,
        customBodyRender: (value) => {
          return <Typography>{value}</Typography>;
        },
      },
    },
  ];

  return (
    <Card
      elevation={0}
      sx={{
        background:
          theme.palette.mode === "dark"
            ? "linear-gradient(145deg, rgba(40,40,45,0.9) 0%, rgba(35,35,40,0.8) 100%)"
            : "linear-gradient(145deg, rgba(255,255,255,0.9) 0%, rgba(245,245,245,0.8) 100%)",
        backdropFilter: "blur(10px)",
        border: `1px solid ${
          theme.palette.mode === "dark"
            ? "rgba(255,255,255,0.1)"
            : "rgba(0,0,0,0.1)"
        }`,
        borderRadius: "16px",
      }}
    >
      <DataTableBase
        title={t("actions.log_name")}
        data={deviceAlerts}
        columns={alertTableColumns}
        sortOrder={"time_created"}
        sortDirection="desc"
        rowsPerPage={10}
      />
    </Card>
  );
}
