import React, { useEffect, useState } from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import {
  Box,
  Button,
  Grid,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import DataTableBase from "../components/DataTableBase";
import GeofenceDeleteDialog from "../components/Dialogs/GeofenceDelete";
import { Add, Check, Clear, Refresh } from "@mui/icons-material";
import GeofenceStatusDialog from "../components/Dialogs/GeofenceStatus";
import GeofenceImportDialog from "../components/Dialogs/GeofenceImport";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../Functions/formatDate";
import moment from "moment";
import updateSnackbarMessage from "../Functions/updateSnackbarMessage";
import { useSelector } from "react-redux";
import PageTitle from "../components/PageTitle";
import fetchGeofences from "../Functions/fetchGeofences";
import setTitle from "../Functions/setTitle";
import hasPermission from "../Functions/hasPermission";

function Geofences() {
  const [selectedGeofences, setSelectedGeofences] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [deleteGeofenceDialogOpen, setDeleteGeofenceDialogOpen] =
    useState(false);
  const [setStatusDialogOpen, setSetStatusDialogOpen] = useState(false);
  const [importGeofenceDialogOpen, setImportGeofenceDialogOpen] =
    useState(false);
  const theme = useTheme();
  const { t } = useTranslation("common");

  const navigate = useNavigate();

  const user = useSelector((state) => state.user);
  const geofences = useSelector((state) => state.geofences);
  const isMobile = useSelector((state) => state.isMobile);
  const selectedOrganisation = useSelector(
    (state) => state.selectedOrganisation
  );

  const userOrganisationRoles = useSelector(
    (state) => state.userOrganisationRoles
  );

  const columns = [
    {
      name: "id",
      label: t("common.id"),
      options: {
        filter: true,
        display: false,
      },
    },
    {
      name: "name",
      options: {
        label: t("common.name"),
        customBodyRender: (value, tableMeta, meta) => {
          return (
            <Typography
              onClick={() => {
                navigate(`/geofence/${tableMeta.rowData[0]}`);
              }}
              className="cursor-pointer"
              variant="body1"
              color="primary"
            >
              {value}
            </Typography>
          );
        },
      },
    },
    //latitude, longitude and radius
    {
      name: "latitude",
      label: t("geofences.latitude"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography variant="body1" className="cursor-pointer">
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: "longitude",
      label: t("geofences.longitude"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography variant="body1" className="cursor-pointer">
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: "radius",
      label: t("geofences.radius"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography variant="body1" className="cursor-pointer">
              {value}m
            </Typography>
          );
        },
      },
    },
    {
      name: "alert_type",
      label: t("geofences.alert_type"),
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <Typography variant="body1">
              {t(`geofences.${value.toLowerCase()}`)}
            </Typography>
          );
        },
      },
    },
    {
      name: "active",
      label: t("actions.active"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          if (value === 1) {
            return <Check />;
          } else {
            return <Clear />;
          }
        },
      },
    },
  ];

  // create object of two actions, one for deleting and one for setting forwarder
  let tableActions = [];

  //check user permissions and add actions

  if (
    (selectedOrganisation &&
      user &&
      selectedOrganisation.owner_id === user.id) ||
    hasPermission(userOrganisationRoles, user.id, "delete")
  ) {
    tableActions.push({
      name: t("geofences.delete_selected"),
      icon: "Delete",
      action: () => setDeleteGeofenceDialogOpen(true),
    });
  }

  if (
    (selectedOrganisation &&
      user &&
      selectedOrganisation.owner_id === user.id) ||
    hasPermission(userOrganisationRoles, user.id, "edit")
  ) {
    tableActions.push({
      name: t("geofences.set_status"),
      icon: "Status",
      action: () => setSetStatusDialogOpen(true),
    });

    //push to columns
    columns.push({
      name: "options",
      label: t("common.options"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <Button
              variant="outlined"
              color={theme.palette.mode === "dark" ? "primary" : "secondary"}
              onClick={() => {
                navigate(`/geofence/${tableMeta.rowData[0]}`);
              }}
            >
              {t("common.edit")}
            </Button>
          );
        },
      },
    });
  }

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setTitle(`${t("geofences.name")}`, selectedOrganisation);

      fetchGeofences(localStorage.getItem("selectedOrganisationID"));
    }
    return () => {
      isMounted = false;
    };
  }, [selectedOrganisation, t]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack
            direction="row"
            justifyContent={isMobile ? "flex-start" : "flex-end"}
            sx={{ mt: 1 }}
          >
            <PageTitle
              title={
                selectedOrganisation && selectedOrganisation.name
                  ? selectedOrganisation.name + " " + t("geofences.name")
                  : null
              }
              theme={theme}
              subTitle={
                geofences
                  ? geofences.length + " geofences in this organisation"
                  : null
              }
            />

            {
              // if user is not the owner of the organisation, don't show the add device button
              (selectedOrganisation &&
                user &&
                selectedOrganisation.owner_id === user.id) ||
              hasPermission(userOrganisationRoles, user.id, "add") ? (
                <Tooltip title="Add a Geofence" placement="top" arrow>
                  <Button
                    variant="outlined"
                    color={
                      theme.palette.mode === "dark" ? "primary" : "secondary"
                    }
                    onClick={() => {
                      navigate("/geofence/add");
                    }}
                    sx={{ mr: 1 }}
                  >
                    <Add />
                  </Button>
                </Tooltip>
              ) : null
            }
            <Tooltip title="Refresh Geofences" placement="top" arrow>
              <Button
                variant="outlined"
                color={theme.palette.mode === "dark" ? "primary" : "secondary"}
                onClick={() => {
                  updateSnackbarMessage(
                    t("common.refreshed") + " " + formatDate(moment())
                  );
                }}
              >
                <Refresh />
              </Button>
            </Tooltip>
          </Stack>
        </Grid>
      </Grid>
      <Box sx={{ mt: 1 }}>
        <DataTableBase
          columns={columns}
          data={geofences ? geofences : []}
          selectableRows={
            (selectedOrganisation &&
              user &&
              selectedOrganisation.owner_id === user.id) ||
            hasPermission(userOrganisationRoles, user.id, "delete")
              ? "multiple"
              : "none"
          }
          title={t("geofences.name")}
          selectedItems={selectedGeofences}
          setSelectedItems={setSelectedGeofences}
          actions={tableActions}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      </Box>
      <GeofenceImportDialog
        open={importGeofenceDialogOpen}
        handleClose={() => {
          setImportGeofenceDialogOpen(false);
          setSelectedGeofences([]);
          setSelectedRows([]);
          fetchGeofences(localStorage.getItem("selectedOrganisationID"));
        }}
      />
      <GeofenceDeleteDialog
        open={deleteGeofenceDialogOpen}
        handleClose={() => {
          setDeleteGeofenceDialogOpen(false);
          setSelectedGeofences([]);
          setSelectedRows([]);
          fetchGeofences(localStorage.getItem("selectedOrganisationID"));
        }}
        selectedGeofences={selectedGeofences}
      />
      <GeofenceStatusDialog
        open={setStatusDialogOpen}
        handleClose={() => {
          setSetStatusDialogOpen(false);
          setSelectedGeofences([]);
          setSelectedRows([]);
          fetchGeofences(localStorage.getItem("selectedOrganisationID"));
        }}
        selectedGeofences={selectedGeofences}
      />
    </>
  );
}

export default withAuthenticationRequired(Geofences);
