// data table geofence for x-data-grid

import React from "react";
import MUIDataTable from "mui-datatables";
import { useState } from "react";
import { IconButton, Stack, Tooltip } from "@mui/material";
import { Add, CheckBox, Delete, Forward } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

export default function DataTableBase(props) {
  const [rowsPerPage, setRowsPerPage] = useState(props.rowsPerPage || 10);
  const { t } = useTranslation("common");

  const options = {
    responsive: "standard",
    print: false,
    download: false,
    sortOrder: {
      name: props.sortOrder || "name",
      direction: props.sortDirection || "asc",
    },
    selectableRows: props.selectableRows || "none",
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: [5, 10, 25, 50, 100],
    rowsSelected: props.selectedRows || [],
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
      // if props.actions and props.actions.length > 0 then render buttons for each action
      if (props.actions && props.actions.length > 0) {
        return (
          <Stack direction="row" spacing={2} sx={{ mr: 2 }}>
            {props.actions.map((action) => {
              return (
                <Tooltip title={action.name} key={action.name}>
                  <IconButton
                    onClick={() => {
                      action.action();
                    }}
                  >
                    {action.icon === "Delete" && <Delete />}
                    {action.icon === "Forward" && <Forward />}
                    {action.icon === "Status" && <CheckBox />}
                    {action.icon === "Add" && <Add />}
                  </IconButton>
                </Tooltip>
              );
            })}
          </Stack>
        );
      } else {
        return <div />;
      }
    },
    onTableChange: (action, newTableState) => {
      switch (action) {
        case "changeRowsPerPage":
          setRowsPerPage(newTableState.rowsPerPage);
          break;
        case "propsUpdate":
        default:
          break;
      }
    },
    onRowSelectionChange: (rowsSelectedData, allRows, rowsSelected) => {
      let selectedRowID = [];
      if (rowsSelected.length > 0) {
        rowsSelected.forEach((row) => {
          if (props.data[row] && props.data[row].id) {
            selectedRowID.push(props.data[row].id);
          }
        });
      }
      props.setSelectedItems(selectedRowID);
      props.setSelectedRows(rowsSelected);
    },
    textLabels: {
      body: {
        noMatch: t("common.no_records"),
        toolTip: t("common.sort"),
      },
      pagination: {
        next: t("common.next"),
        previous: t("common.previous"),
        rowsPerPage: t("common.rows_per_page"),
        displayRows: t("common.display_rows"),
      },
      toolbar: {
        search: t("common.search"),
        downloadCsv: t("common.download_csv"),
        viewColumns: t("common.view_columns"),
        filterTable: t("common.filter_table"),
      },
      filter: {
        title: t("common.title_filters"),
        reset: t("common.reset"),
      },
      viewColumns: {
        title: t("common.view_columns"),
      },
      selectedRows: {
        delete: t("common.delete"),
      },
    },
  };

  return <MUIDataTable {...props} options={options} />;
}
