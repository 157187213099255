import React from "react";
import { Grid } from "@mui/material";
import TemperatureGuague from "../../Guages/Temperature";
import LastAlert from "../../Cards/LastAlert";
import BatteryStatus from "../../Cards/BatteryStatus";
import LastSeen from "../../Cards/LastSeen";
import DeviceActionLogs from "../../Cards/Alerts";
import FrameList from "../../FrameList";
import formatTemperature from "../../../Functions/formatTemperature";
import GraphBase from "../../Graphs/GraphBase";

export default function DraginoLSN50V2D20(props) {
  let { isShare } = props;

  return (
    <>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12} md={!isShare ? 4 : 6} sx={{ mb: 2 }}>
          <LastSeen />
        </Grid>
        <Grid item xs={12} md={!isShare ? 4 : 6} sx={{ mb: 2 }}>
          <BatteryStatus />
        </Grid>

        {!isShare && (
          <Grid item xs={12} md={4} sx={{ mb: 2 }}>
            <LastAlert />
          </Grid>
        )}

        <Grid item xs={12} md={3} sx={{ mb: 2 }}>
          <TemperatureGuague useIsXL={false} />
        </Grid>
        <Grid item xs={12} md={9} sx={{ mb: 2 }}>
          <GraphBase
            title="Temperature"
            value="temperature"
            formatter={formatTemperature}
            graphFormatter={formatTemperature}
            aspect={3.5}
          />
        </Grid>

        <Grid
          item
          xs={12}
          md={isShare ? 12 : 7}
          xxl={isShare ? 12 : 8}
          sx={{ mb: 2 }}
        >
          <FrameList isShare={isShare} />
        </Grid>

        {!isShare && (
          <Grid item xs={12} md={5} xxl={4} sx={{ mb: 2 }}>
            <DeviceActionLogs />
          </Grid>
        )}
      </Grid>
    </>
  );
}
