import {
  FormControl,
  Button,
  Stack,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import updateDataRange from "../../Functions/updateDateRange";
import { CalendarToday } from "@mui/icons-material";
import updateSnackbarMessage from "../../Functions/updateSnackbarMessage";
import { CONFIG } from "../../config";

export default function DateTimePickerDialog(props) {
  const { t } = useTranslation("common");
  const { onClose, open } = props;

  const dateRange = useSelector((state) => state.dateRange);

  const [startDate, setStartDate] = useState(moment.unix(dateRange.startDate));
  const [endDate, setEndDate] = useState(moment.unix(dateRange.endDate));

  let dateFormat =
    localStorage.getItem("date_format") !== null
      ? localStorage.getItem("date_format")
      : CONFIG.defaultDateFormat;

  const handleClose = () => {
    onClose();
  };

  function SetDateTime() {
    //convert start and end date from local time to utc
    startDate.utc();
    endDate.utc();
    //start date should be beging of day, end date should be end of day
    startDate.startOf("day");
    endDate.endOf("day");

    updateDataRange(startDate, endDate);

    updateSnackbarMessage(
      "Date range updated to: " +
        startDate.format("HH:mm " + dateFormat) +
        " - " +
        endDate.format("HH:mm " + dateFormat)
    );
    onClose();
  }

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      className="DateTimeDialog"
      maxWidth="md"
    >
      <DialogTitle>{t("common.select_date")}</DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} sx={{ mt: 2 }}>
            <Stack spacing={2} direction="column">
              {
                //predifined date ranges for quick selection, today, this week, this month, last month
              }
              <Button
                variant="outlined"
                startIcon={<CalendarToday />}
                //make text left align
                style={{ textAlign: "left" }}
                onClick={() => {
                  updateDataRange(
                    moment().utc().startOf("day").unix(),
                    moment().utc().endOf("day").unix()
                  );
                  updateSnackbarMessage("Date range updated to: Today");
                  onClose();
                }}
              >
                {t("common.today")}
              </Button>
              <Button
                variant="outlined"
                startIcon={<CalendarToday />}
                style={{ textAlign: "left" }}
                onClick={() => {
                  updateDataRange(
                    moment().utc().subtract(1, "days").startOf("day").unix(),
                    moment().utc().subtract(1, "days").endOf("day").unix()
                  );
                  updateSnackbarMessage("Date range updated to: Yesterday");
                  onClose();
                }}
              >
                {t("common.yesterday")}
              </Button>
              <Button
                variant="outlined"
                startIcon={<CalendarToday />}
                style={{ textAlign: "left" }}
                onClick={() => {
                  updateDataRange(
                    moment().utc().startOf("week").startOf("day").unix(),
                    moment().utc().endOf("week").endOf("day").unix()
                  );
                  updateSnackbarMessage("Date range updated to: This week");
                  onClose();
                }}
              >
                {t("common.this_week")}
              </Button>
              <Button
                variant="outlined"
                startIcon={<CalendarToday />}
                style={{ textAlign: "left" }}
                onClick={() => {
                  updateDataRange(
                    //work our monday of last week to sunday
                    moment()
                      .utc()
                      .subtract(1, "weeks")
                      .startOf("week")
                      .startOf("day")
                      .unix(),
                    moment()
                      .utc()
                      .subtract(1, "weeks")
                      .endOf("week")
                      .endOf("day")
                      .unix()
                  );
                  updateSnackbarMessage("Date range updated to: Last week");
                  onClose();
                }}
              >
                {t("common.last_week")}
              </Button>

              <Button
                variant="outlined"
                startIcon={<CalendarToday />}
                style={{ textAlign: "left" }}
                onClick={() => {
                  updateDataRange(
                    moment().utc().startOf("month").startOf("day").unix(),
                    moment().utc().endOf("month").endOf("day").unix()
                  );
                  updateSnackbarMessage("Date range updated to: This month");
                  onClose();
                }}
              >
                {t("common.this_month")}
              </Button>
              <Button
                variant="outlined"
                startIcon={<CalendarToday />}
                style={{ textAlign: "left" }}
                onClick={() => {
                  updateDataRange(
                    moment()
                      .utc()
                      .subtract(1, "months")
                      .startOf("month")
                      .startOf("day")
                      .unix(),
                    moment()
                      .utc()
                      .subtract(1, "months")
                      .endOf("month")
                      .endOf("day")
                      .unix()
                  );
                  updateSnackbarMessage("Date range updated to: Last month");
                  onClose();
                }}
              >
                {t("common.last_month")}
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={8} sx={{ mt: 2, mb: 4 }}>
            <Stack spacing={3} direction="column">
              <FormControl fullWidth>
                <DatePicker
                  label="Start Date"
                  maxDate={moment()}
                  value={startDate}
                  onChange={(newValue) => setStartDate(newValue)}
                  //format="YYYY-MM-DD"
                  format={dateFormat}
                />
              </FormControl>
              <FormControl fullWidth>
                <DatePicker
                  label="End Date"
                  maxDate={moment()}
                  value={endDate}
                  onChange={(newValue) => setEndDate(newValue)}
                  format={dateFormat}
                />
              </FormControl>
            </Stack>
          </Grid>
          <Grid item xs={12} sx={{ mt: 2 }}>
            <Stack spacing={2} direction="row" className="mt-5">
              <Button
                variant="outlined"
                color="error"
                onClick={handleClose}
                style={{ marginLeft: "auto" }}
              >
                {t("common.cancel")}
              </Button>
              <Button variant="outlined" onClick={SetDateTime}>
                {t("common.set_date_time")}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
