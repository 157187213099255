import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import CardBase from "./CardBase";

export default function OccupancyCount() {
  const deviceHistory = useSelector((state) => state.deviceHistory);
  const [lastCount, setLastCount] = React.useState(0);

  useEffect(() => {
    if (deviceHistory.length > 0) {
      let lastData = deviceHistory[deviceHistory.length - 1];
      let jsonData = lastData.data;
      jsonData = JSON.parse(jsonData);
      setLastCount(jsonData.count);
    }
  }, [deviceHistory]);

  return (
    <CardBase
      title={"Occupancy Count"}
      value={lastCount}
      unit={""}
      icon={"users"}
    />
  );
}
