import { useState, useEffect, useMemo } from "react";

export const useDevicesPagination = (devices, isXL) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [devicesPerPage, setDevicesPerPage] = useState(() => {
    const stored = localStorage.getItem("devicesPerPage");
    return stored ? stored : isXL ? 9 : 10;
  });
  const [filter, setFilter] = useState("");
  const [totalPages, setTotalPages] = useState(1);

  const filteredDevices = useMemo(() => {
    if (!devices?.length) return [];
    return devices.filter((device) => {
      if (!filter) return true;
      const searchTerm = filter.toLowerCase();
      return (
        device.name.toLowerCase().includes(searchTerm) ||
        device.dev_eui.toLowerCase().includes(searchTerm)
      );
    });
  }, [devices, filter]);

  useEffect(() => {
    if (!filteredDevices.length) {
      setTotalPages(1);
      return;
    }
    const newTotalPages = Math.ceil(filteredDevices.length / devicesPerPage);
    setTotalPages(newTotalPages);
    setCurrentPage(1);
  }, [devicesPerPage, filteredDevices.length]);

  const paginatedDevices = useMemo(() => {
    const start = (currentPage - 1) * devicesPerPage;
    const end = start + devicesPerPage;
    return filteredDevices.slice(start, end);
  }, [filteredDevices, currentPage, devicesPerPage]);

  const handleDevicesPerPageChange = (value) => {
    setDevicesPerPage(value);
    localStorage.setItem("devicesPerPage", value);
  };

  return {
    currentPage,
    setCurrentPage,
    devicesPerPage,
    handleDevicesPerPageChange,
    filter,
    setFilter,
    totalPages,
    filteredAndPaginatedDevices: paginatedDevices, // Changed this line
  };
};
