export const widgets = [
  {
    id: 2,
    name: "Icon Card",
    fields: [
      {
        id: 0,
        name: "Title",
        type: "text",
      },

      {
        id: 1,
        name: "Icon",
        type: "Icon",
      },
      {
        id: 2,
        name: "icon_size",
        type: "size",
        default: "6x",
      },
      {
        id: 4,
        name: "Value_Field",
        type: "Value",
      },
      {
        id: 5,
        name: "Formatter",
        type: "Formatter",
        default: "none",
      },
      {
        id: 6,
        name: "Unit",
        type: "text",
        default: "",
      },
      {
        id: 7,
        name: "text_size",
        type: "size",
        default: "6x",
      },
    ],
  },
  {
    id: 3,
    name: "Map",
    fields: [
      {
        id: 0,
        name: "MapType",
        type: "mapSelect",
      },
      {
        id: 1,
        name: "selectedDevices",
        type: "selectedDevices",
      },
    ],
  },
  {
    id: 5,
    name: "Title",
    fields: [
      {
        id: 0,
        name: "Title",
        type: "text",
      },
      {
        id: 1,
        name: "Alignment",
        type: "alignment",
      },
      {
        id: 2,
        name: "Font Size",
        type: "number",
      },
    ],
  },
  {
    id: 6,
    name: "Device Card",
    fields: [
      {
        id: 1,
        name: "Device",
        type: "Value",
      },
    ],
  },
  {
    id: 7,
    name: "Boolean",
    fields: [
      {
        id: 0,
        name: "Title",
        type: "text",
      },
      {
        id: 1,
        name: "Boolean Field",
        type: "Value",
      },
      {
        id: 2,
        name: "True Color",
        type: "color",
      },
      {
        id: 3,
        name: "False Color",
        type: "color",
      },
    ],
  },
  {
    id: 8,
    name: "Break",
    fields: [],
  },
  {
    id: 9,
    name: "Fluid Level",
    fields: [
      {
        id: 0,
        name: "Title",
        type: "text",
      },
      {
        id: 1,
        name: "Value Field",
        type: "Value",
      },
      {
        id: 2,
        name: "Full",
        type: "number",
      },
      {
        id: 3,
        name: "Empty",
        type: "number",
      },
      {
        id: 4,
        name: "Size",
        type: "size",
      },
    ],
  },
  {
    id: 10,
    name: "Graph",
    fields: [
      { id: 0, name: "Title", type: "text" },
      {
        id: 1,
        name: "Values",
        type: "Values",
      },
      {
        id: 2,
        name: "DaysOfHistory",
        type: "number",
      },
    ],
  },
  {
    id: 11,
    name: "Downlink Switch",
    fields: [
      {
        id: 0,
        name: "Title",
        type: "text",
      },
      {
        id: 1,
        name: "statusField",
        type: "Value",
      },
      {
        id: 2,
        name: "actionTrue",
        type: "downlink",
      },
      {
        id: 3,
        name: "actionFalse",
        type: "downlink",
      },
      {
        id: 4,
        name: "size",
        type: "size",
      },
    ],
  },
  {
    id: 12,
    name: "IFrame",
    fields: [
      {
        id: 0,
        name: "Title",
        type: "text",
      },
      {
        id: 1,
        name: "URL",
        type: "text",
      },
    ],
  },
  {
    id: 13,
    name: "Image",
    fields: [
      {
        id: 0,
        name: "Title",
        type: "text",
      },
      {
        id: 1,
        name: "URL",
        type: "text",
      },
      {
        id: 2,
        name: "Fit",
        type: "fit",
      },
    ],
  },
];
