import React, { useEffect, useMemo } from "react";
import convertMvToPercent from "../../Functions/convertMvToPercent";
import { useTheme } from "@emotion/react";
import { useSelector } from "react-redux";
import convertPercentToColour from "../../Functions/convertPercentToColour";
import CardBase from "./CardBase";

export default function BatteryStatus({ string, field }) {
  const [batteryMv, setBatteryMv] = React.useState(0);
  const deviceHistory = useSelector((state) => state.deviceHistory);
  const device = useSelector((state) => state.device);
  const theme = useTheme();

  useEffect(() => {
    if (deviceHistory.length > 0) {
      const lastData = deviceHistory[deviceHistory.length - 1];
      const jsonData = JSON.parse(lastData.data);
      const bat = field
        ? jsonData[field]
        : jsonData.voltageMv || device.batteryMv;
      setBatteryMv(bat);
    } else {
      setBatteryMv(device.batteryMv);
    }
  }, [deviceHistory, field, device.batteryMv]);

  const batteryDisplay = useMemo(() => {
    if (string) return batteryMv;
    return convertMvToPercent(batteryMv, device.device_brand);
  }, [batteryMv, string, device.device_brand]);

  const batteryColor = useMemo(() => {
    if (string)
      return batteryMv === "OK"
        ? theme.palette.success.main
        : theme.palette.error.main;
    return convertPercentToColour(
      convertMvToPercent(batteryMv, device.device_brand)
    );
  }, [batteryMv, string, device.device_brand, theme]);

  return (
    <CardBase
      title={string ? "Battery Status" : "Battery Level"}
      value={batteryDisplay}
      unit={string ? "" : "%"}
      color={batteryColor}
      icon={"car-battery"}
    />
  );
}
