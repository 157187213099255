import { useTheme } from "@emotion/react";
import { Lightbulb } from "@mui/icons-material";
import GaugeBase from "./GaugeBase";

export default function LightGuague(props) {
  const theme = useTheme();

  let defaultArch = [
    {
      limit: 20,
      // almost black but not quite blue
      color: "#000019",
    },
    {
      limit: 40,
      // almost black but not quite blue
      color: "#000066",
    },
    {
      limit: 60,
      //dark blue
      color: "#0000FF",
    },
    {
      limit: 80,
      //light blue
      color: "#00FFFF",
    },
    {
      limit: 100,
      color: "#FFF",
    },
  ];

  return (
    <GaugeBase
      title={"Light"}
      field={"light"}
      icon={
        <Lightbulb
          sx={{
            color: theme.palette.text.secondary,
            opacity: 0.8,
          }}
        />
      }
      defaultArch={defaultArch}
      unit=""
      min={0}
      max={100}
    />
  );
}
