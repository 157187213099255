import { useSelector } from "react-redux";

export const useDevicesSelectors = () => {
  const selectedOrganisation = useSelector(
    (state) => state.selectedOrganisation
  );
  const devices = useSelector((state) => state.devices);
  const user = useSelector((state) => state.user);
  const userOrganisationRoles = useSelector(
    (state) => state.userOrganisationRoles
  );
  const isMobile = useSelector((state) => state.isMobile);

  return {
    selectedOrganisation,
    devices,
    user,
    userOrganisationRoles,
    isMobile,
  };
};
