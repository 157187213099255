import { useTheme } from "@emotion/react";
import React, { useEffect } from "react";
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";
import { useSelector } from "react-redux";
import { formatDate, formatDateOnly } from "../../Functions/formatDate";
import { Box, Card, CardContent, Typography } from "@mui/material";
import { Equalizer } from "@mui/icons-material";

export default function ButtonPressRakGraph(props) {
  const [buttonPressHistory, setButtonPressHistory] = React.useState([]);

  const deviceHistory = useSelector((state) => state.deviceHistory);
  const deviceProfile = useSelector((state) => state.deviceProfile);
  const isMobile = useSelector((state) => state.isMobile);

  let button1Label = "Button 1";
  let button2Label = "Button 2";
  let button3Label = "Button 3";
  let button4Label = "Button 4";

  if (deviceProfile.length) {
    //json decode deviceProfile

    try {
      let deviceProfileJSON = JSON.parse(deviceProfile);

      if (deviceProfileJSON.Rak4Button) {
        if (deviceProfileJSON.Rak4Button.button1) {
          button1Label = deviceProfileJSON.Rak4Button.button1;
        }
        if (deviceProfileJSON.Rak4Button.button2) {
          button2Label = deviceProfileJSON.Rak4Button.button2;
        }
        if (deviceProfileJSON.Rak4Button.button3) {
          button3Label = deviceProfileJSON.Rak4Button.button3;
        }
        if (deviceProfileJSON.Rak4Button.button4) {
          button4Label = deviceProfileJSON.Rak4Button.button4;
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  const theme = useTheme();

  useEffect(() => {
    if (deviceHistory.length === 0) {
      return;
    }

    try {
      //history will be a collection of button numbers, and will only show in history if the button was pressed
      let tempHistory = [];

      deviceHistory.forEach((dataObject) => {
        let jsonData = dataObject.data;
        jsonData = JSON.parse(jsonData);
        let time_created = dataObject.time_created;

        let button1 = 0;
        let button2 = 0;
        let button3 = 0;
        let button4 = 0;

        if (jsonData.button1) {
          button1 = 1;
        }

        if (jsonData.button2) {
          button2 = 1;
        }

        if (jsonData.button3) {
          button3 = 1;
        }

        if (jsonData.button4) {
          button4 = 1;
        }

        tempHistory.push({
          Date: time_created,
          Button1: button1 === 1 ? "Yes" : "No",
          Button2: button2 === 1 ? "Yes" : "No",
          Button3: button3 === 1 ? "Yes" : "No",
          Button4: button4 === 1 ? "Yes" : "No",
        });

        setButtonPressHistory(tempHistory);
      });
    } catch (error) {}
  }, [deviceHistory]);

  return (
    <Card
      elevation={0}
      sx={{
        height: "100%",
        background:
          theme.palette.mode === "dark"
            ? "linear-gradient(145deg, rgba(40,40,45,0.9) 0%, rgba(35,35,40,0.8) 100%)"
            : "linear-gradient(145deg, rgba(255,255,255,0.9) 0%, rgba(245,245,245,0.8) 100%)",
        backdropFilter: "blur(10px)",
        border: `1px solid ${
          theme.palette.mode === "dark"
            ? "rgba(255,255,255,0.1)"
            : "rgba(0,0,0,0.1)"
        }`,
        borderRadius: "16px",
      }}
    >
      <CardContent>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            mb: 2,
          }}
        >
          <Equalizer
            sx={{
              color: theme.palette.text.secondary,
              opacity: 0.8,
            }}
          />
          <Typography
            variant="subtitle2"
            sx={{
              color: theme.palette.text.secondary,
              letterSpacing: "0.02em",
              fontWeight: 500,
            }}
          >
            {"Button Press History"}
          </Typography>
        </Box>
        <ResponsiveContainer aspect={!isMobile ? 5 : 2}>
          <AreaChart
            data={buttonPressHistory}
            margin={{ top: 0, right: 30, left: -15, bottom: 0 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <defs>
              <linearGradient id="ButtonPress1" x1="0" y1="0" x2="0" y2="1">
                <stop
                  offset="5%"
                  stopColor={theme.palette.primary.main}
                  stopOpacity={0.8}
                />
                <stop
                  offset="95%"
                  stopColor={theme.palette.primary.main}
                  stopOpacity={0}
                />
              </linearGradient>
              <linearGradient id="ButtonPress2" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={"blue"} stopOpacity={0.8} />

                <stop offset="95%" stopColor={"blue"} stopOpacity={0} />
              </linearGradient>
              <linearGradient id="ButtonPress3" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={"red"} stopOpacity={0.8} />
                <stop offset="95%" stopColor={"red"} stopOpacity={0} />
              </linearGradient>
              <linearGradient id="ButtonPress4" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={"orange"} stopOpacity={0.8} />
                <stop offset="95%" stopColor={"orange"} stopOpacity={0} />
              </linearGradient>
            </defs>
            <XAxis
              dataKey="Date"
              type={"category"} //format date to be more readable
              tickFormatter={(value) => {
                return formatDateOnly(value);
              }}
            />
            <YAxis type="category" yAxisId="1" />
            <Tooltip
              contentStyle={{
                backgroundColor: theme.palette.background.default,
              }}
              cursor={false}
              labelFormatter={(value) => {
                return formatDate(value);
              }}
              formatter={(value, name) => {
                if (name === "Button1") {
                  name = button1Label;
                }
                if (name === "Button2") {
                  name = button2Label;
                }
                if (name === "Button3") {
                  name = button3Label;
                }
                if (name === "Button4") {
                  name = button4Label;
                }

                return [value, name];
              }}
            />
            <Area
              yAxisId="1"
              dataKey="Button1"
              stroke={theme.palette.primary.main}
              animationDuration={300}
              fill="url(#ButtonPress1)"
              strokeWidth={2}
            />
            <Area
              yAxisId="1"
              dataKey="Button2"
              stroke={"blue"}
              animationDuration={300}
              fill="url(#ButtonPress2)"
              strokeWidth={2}
            />
            <Area
              yAxisId="1"
              dataKey="Button3"
              stroke={"red"}
              animationDuration={300}
              fill="url(#ButtonPress3)"
              strokeWidth={2}
            />
            <Area
              yAxisId="1"
              dataKey="Button4"
              stroke={"orange"}
              animationDuration={300}
              fill="url(#ButtonPress4)"
              strokeWidth={2}
            />
          </AreaChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
}
