import fetchData from "../API/Fetch";
import { store } from "../store";

export const fetchDeviceHistory = (deviceID, startDate, endDate) => {
  //format dates to unix timestamp

  if (!deviceID) {
    store.dispatch({ type: "deviceHistory/update", payload: [] });
  }

  //check for undefined params
  if (
    deviceID === undefined ||
    startDate === undefined ||
    endDate === undefined
  ) {
    store.dispatch({ type: "deviceHistory/update", payload: [] });
    return;
  }

  fetchData(`/frame/${deviceID}/${startDate}/${endDate}`).then((response) => {
    if (response) {
      let data = response;

      //sort history by time_created ordest to newest
      data.sort((a, b) => {
        return new Date(a.time_created) - new Date(b.time_created);
      });

      //update device history in redux store
      store.dispatch({
        type: "deviceHistory/update",
        payload: data,
      });

      return data;
    } else {
      store.dispatch({ type: "deviceHistory/update", payload: [] });
    }
  });
};
