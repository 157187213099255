import DeviceMetric from "./DeviceMetric";

export default function MotionCardNBIOT() {
  return (
    <>
      <DeviceMetric
        title="Motion Detected?"
        value="motion"
        trueFalse={true}
        trueValue="Motion Detected"
        falseValue="No Motion"
        icon="person-walking"
        height="48%"
      />
      <DeviceMetric
        title="Distance"
        value="distance"
        icon="ruler"
        height="50%"
      />
    </>
  );
}
