import React from "react";

import FrameList from "../FrameList";
import DeviceActionLogs from "../Cards/Alerts";
import { Grid } from "@mui/material";
import TimelineCard from "../Cards/timeline";
import DeviceInfo from "../Cards/DeviceInfo";
import IsLostCard from "../Cards/isLost";
import { useSelector } from "react-redux";

export default function PetTracker(props) {
  const isMobile = useSelector((state) => state.isMobile);
  const { isShare } = props;

  return (
    <>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        {!isMobile ? (
          <>
            <Grid item xs={12} md={3} sx={{ mb: 2 }}>
              <IsLostCard />
            </Grid>
            <Grid item xs={12} md={9} sx={{ mb: 2 }}>
              <TimelineCard />
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12} md={9} sx={{ mb: 2 }}>
              <TimelineCard />
            </Grid>
            <Grid item xs={12} md={3} sx={{ mb: 2 }}>
              <IsLostCard />
            </Grid>
          </>
        )}

        {isMobile && (
          <Grid item xs={12} md={12} sx={{ mb: 2 }}>
            <DeviceInfo />
          </Grid>
        )}

        <Grid item xs={12} md={!isShare ? 7 : 12} sx={{ mb: 2 }}>
          <FrameList />
        </Grid>
        {!isShare && (
          <Grid item xs={12} md={5} sx={{ mb: 2 }}>
            <DeviceActionLogs />
          </Grid>
        )}
      </Grid>
    </>
  );
}
