import { Box, Card, CardContent, Tooltip, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useTheme } from "@emotion/react";
import { useSelector } from "react-redux";
import { SignalCellularAltRounded } from "@mui/icons-material";

export default function SignalQuality(props) {
  const [signal_quality, setSignalQuality] = React.useState("N/A");
  const theme = useTheme();

  const { key = "rssi" } = props;

  const deviceHistory = useSelector((state) => state.deviceHistory);

  useEffect(() => {
    if (deviceHistory && deviceHistory.length > 0) {
      let jsonData = deviceHistory[deviceHistory.length - 1].data;
      jsonData = JSON.parse(jsonData);
      setSignalQuality(jsonData[key]);
    }
  }, [deviceHistory, key]);

  return (
    <Card
      elevation={0}
      sx={{
        background:
          theme.palette.mode === "dark"
            ? "linear-gradient(145deg, rgba(40,40,45,0.9) 0%, rgba(35,35,40,0.8) 100%)"
            : "linear-gradient(145deg, rgba(255,255,255,0.9) 0%, rgba(245,245,245,0.8) 100%)",
        backdropFilter: "blur(10px)",
        border: `1px solid ${
          theme.palette.mode === "dark"
            ? "rgba(255,255,255,0.1)"
            : "rgba(0,0,0,0.1)"
        }`,
        borderRadius: "16px",
        transition: "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
      }}
    >
      <CardContent sx={{ padding: 2 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <SignalCellularAltRounded
            sx={{
              color: theme.palette.text.secondary,
              opacity: 0.8,
            }}
          />
          <Typography
            variant="subtitle2"
            sx={{
              color: theme.palette.text.secondary,
              letterSpacing: "0.02em",
              fontWeight: 500,
              flexGrow: 1,
            }}
          >
            Signal Quality
          </Typography>

          <Tooltip title={`Signal Quality: ${signal_quality} RSSI`}>
            <Typography
              sx={{
                fontWeight: 600,
              }}
            >
              {signal_quality === "N/A" ? "N/A" : `${signal_quality} RSSI`}
            </Typography>
          </Tooltip>
        </Box>
      </CardContent>
    </Card>
  );
}
