import React from "react";

import FrameList from "../FrameList";
import DeviceActionLogs from "../Cards/Alerts";
import { Grid } from "@mui/material";
import TemperatureGuague from "../Guages/Temperature";
import GraphBase from "../Graphs/GraphBase";
import formatTemperature from "../../Functions/formatTemperature";

export default function StickerTracker(props) {
  const { isShare } = props;

  return (
    <Grid container spacing={2} sx={{ mt: 1 }}>
      <Grid item xs={12} md={3} xxl={3} sx={{ mb: 2 }}>
        <TemperatureGuague />
      </Grid>

      <Grid item xs={12} md={9} xxl={9} sx={{ mb: 2 }}>
        <GraphBase
          title="Temperature"
          value="temperature"
          formatter={formatTemperature}
          graphValueFormatter={formatTemperature}
          aspect={3.5}
        />
      </Grid>
      <Grid item xs={12} md={!isShare ? 6 : 12} sx={{ mb: 2 }}>
        <FrameList />
      </Grid>
      {!isShare && (
        <Grid item xs={12} md={6} sx={{ mb: 2 }}>
          <DeviceActionLogs />
        </Grid>
      )}
    </Grid>
  );
}
