import { useEffect, useRef } from 'react';
import refreshData from "../Functions/refreshData";
import checkBilling from "../Functions/checkBilling";

const REFRESH_INTERVAL = 120000;

export const useDataManagement = (user, selectedOrganisation, isShare, getAccessTokenSilently) => {
  const interval = useRef(null);

  useEffect(() => {
    if (!user) return;

    interval.current = setInterval(() => {
      refreshData(user.email, user.sub);
    }, REFRESH_INTERVAL);

    return () => clearInterval(interval.current);
  }, [user, selectedOrganisation]);

  useEffect(() => {
    if (!user) return;

    const initializeData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        localStorage.setItem("access_token", accessToken);
        refreshData(user.email, user.sub);
        if (!isShare) checkBilling();
      } catch (e) {
        console.log(e.message);
      }
    };

    initializeData();
  }, [user, isShare, getAccessTokenSilently]);
};