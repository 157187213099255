import updateSnackbarMessage from "../../Functions/updateSnackbarMessage";
import { updatePitch } from "../../Functions/updatePitch";

export class PitchToggle {
  constructor({ bearing = 0, pitch = 75, minpitchzoom = null }) {
    this._bearing = bearing;
    this._pitch = pitch;
    this._minpitchzoom = minpitchzoom;
  }

  onAdd(map) {
    this._map = map;
    this._btn = document.createElement("button");
    this._setupButton();
    
    this._container = document.createElement("div");
    this._container.className = "mapboxgl-ctrl-group mapboxgl-ctrl";
    this._container.appendChild(this._btn);

    return this._container;
  }

  _setupButton() {
    const isPitch0 = localStorage.getItem("pitch") === "0";
    this._btn.className = `mapboxgl-ctrl-icon mapboxgl-ctrl-pitchtoggle-${isPitch0 ? '3d' : '2d'}`;
    this._btn.title = `Toggle Pitch (${isPitch0 ? '3D' : '2D'})`;
    this._btn.type = "button";
    this._btn["aria-label"] = "Toggle Pitch";
    this._btn.onclick = this._handleClick.bind(this);
  }

  _handleClick() {
    const isPitch0 = this._map.getPitch() === 0;
    const newPitch = isPitch0 ? 60 : 0;
    
    updateSnackbarMessage(`${isPitch0 ? '3D' : '2D'} View Enabled`);
    updatePitch(newPitch);
    localStorage.setItem("pitch", newPitch);

    this._map.easeTo({ pitch: newPitch, bearing: 0 });
    this._btn.className = `mapboxgl-ctrl-icon mapboxgl-ctrl-pitchtoggle-${isPitch0 ? '2d' : '3d'}`;
  }

  onRemove() {
    this._container.parentNode.removeChild(this._container);
    this._map = undefined;
  }
}
