import { Grid } from "@mui/material";
import React from "react";
import LastSeen from "../../Cards/LastSeen";
import BatteryStatus from "../../Cards/BatteryStatus";
import LastAlert from "../../Cards/LastAlert";
import TemperatureGuague from "../../Guages/Temperature";
import DeviceActionLogs from "../../Cards/Alerts";
import FrameList from "../../FrameList";
import GraphBase from "../../Graphs/GraphBase";
import HumidityGuague from "../../Guages/Humidity";

export default function DraginoLSN50v2S31(props) {
  let { isShare } = props;

  return (
    <>
      <Grid
        container
        spacing={2}
        alignItems={"stretch"}
        alignContent={"stretch"}
        sx={{ mt: 1 }}
      >
        <Grid item xs={12} md={!isShare ? 4 : 6} sx={{ mb: 2 }}>
          <LastSeen />
        </Grid>
        <Grid item xs={12} md={!isShare ? 4 : 6} sx={{ mb: 2 }}>
          <BatteryStatus />
        </Grid>
        {!isShare && (
          <Grid item xs={12} md={4} sx={{ mb: 2 }}>
            <LastAlert />
          </Grid>
        )}

        <Grid item xs={12} md={3} sx={{ mb: 2 }}>
          <TemperatureGuague field="temperature" />
        </Grid>
        <Grid item xs={12} md={9} sx={{ mb: 2 }}>
          <GraphBase
            value="temperature"
            title="Temperature"
            unit={
              localStorage.getItem("temperature_unit") === "F" ? "°F" : "°C"
            }
            type="temperature"
            aspect={3.5}
          />
        </Grid>

        <Grid item xs={12} md={3} sx={{ mb: 2 }}>
          <HumidityGuague field="humidity" />
        </Grid>
        <Grid item xs={12} md={9} sx={{ mb: 2 }}>
          <GraphBase value="humidity" title="Humidity" unit="%" aspect={3.5} />
        </Grid>

        <Grid item xs={12} md={isShare ? 12 : 6} sx={{ mb: 2 }}>
          <FrameList />
        </Grid>

        {!isShare && (
          <>
            <Grid item xs={12} md={6}>
              <DeviceActionLogs />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}
