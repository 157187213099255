import { useCallback } from "react";
import { updateThemeMode } from "../Functions/updateThemeMode";
import { CONFIG } from "../config";

export const useThemeManagement = () => {
  const handleThemeChange = useCallback((mode = null) => {
    if (mode !== null) {
      updateThemeMode(mode);
      localStorage.setItem("theme", mode);
      return;
    }

    const currentTheme = localStorage.getItem("theme") || CONFIG.defaultTheme;
    const newTheme = currentTheme === "light" ? "dark" : "light";
    updateThemeMode(newTheme);
    localStorage.setItem("theme", newTheme);
  }, []);

  return { handleThemeChange };
};
