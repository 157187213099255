import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import LastAlert from "../Cards/LastAlert";
import BatteryStatus from "../Cards/BatteryStatus";
import LastSeen from "../Cards/LastSeen";
import DeviceActionLogs from "../Cards/Alerts";

import FrameList from "../FrameList";
import MoldIndex from "../Cards/MoldIndex";
import MoldIndexGraph from "../Graphs/MoldIndexGraph";
import DeviceMetric from "../Cards/DeviceMetric";
import { useSelector } from "react-redux";
import GraphBase from "../Graphs/GraphBase";
import formatTemperature from "../../Functions/formatTemperature";

export default function IrisMold(props) {
  let { isShare } = props;

  const [showMoldIndex, setShowMoldIndex] = React.useState(false);
  const [showMotion, setShowMotion] = React.useState(false);

  const deviceProfile = useSelector((state) => state.deviceProfile);

  useEffect(() => {
    if (deviceProfile) {
      //it might need json decoding, check
      if (typeof deviceProfile === "string") {
        let deviceProfileDecoded = JSON.parse(deviceProfile);

        if (!deviceProfileDecoded.fiveInOne) return;

        if (deviceProfileDecoded.fiveInOne.showMoldIndex) {
          setShowMoldIndex(true);
        } else {
          setShowMoldIndex(false);
        }

        if (deviceProfileDecoded.fiveInOne.showMotion) {
          setShowMotion(true);
        } else {
          setShowMotion(false);
        }
      }
    }
  }, [deviceProfile]);

  return (
    <>
      <Grid
        container
        spacing={2}
        alignItems={"stretch"}
        alignContent={"stretch"}
        sx={{ mt: 2 }}
      >
        <Grid item xs={12} md={isShare ? 6 : 4} sx={{ mt: 2 }}>
          <LastSeen />
        </Grid>
        <Grid item xs={12} md={isShare ? 6 : 4} sx={{ mt: 2 }}>
          <BatteryStatus field="batteryMv" />
        </Grid>

        {!isShare && (
          <>
            <Grid item xs={12} md={4} sx={{ mt: 2 }}>
              <LastAlert />
            </Grid>
          </>
        )}

        {showMoldIndex && (
          <>
            <Grid item xs={12} md={4} sx={{ mt: 2 }}>
              <MoldIndex />
            </Grid>

            <Grid item xs={12} md={8} sx={{ mt: 2 }}>
              <MoldIndexGraph />
            </Grid>
          </>
        )}

        <Grid item xs={12} md={showMotion ? 3 : 4} sx={{ mt: 2 }}>
          <DeviceMetric
            title="Temperature"
            value="temperature"
            icon="thermometer-half"
            iconSize="6x"
            aspect={10}
            metricWidth={3}
            graphWidth={12}
            unit="°C"
            textsize="h3"
          />
        </Grid>

        <Grid item xs={12} md={showMotion ? 3 : 4} sx={{ mt: 2 }}>
          <DeviceMetric
            title="Humidity"
            value="humidity"
            icon="tint"
            iconSize="6x"
            aspect={10}
            metricWidth={3}
            graphWidth={12}
            unit="%"
            textsize="h3"
          />
        </Grid>

        <Grid item xs={12} md={showMotion ? 3 : 4} sx={{ mt: 2 }}>
          <DeviceMetric
            title="Co2"
            value="co2"
            icon="cloud"
            iconSize="6x"
            aspect={10}
            metricWidth={3}
            graphWidth={12}
            unit="ppm"
            textsize="h3"
          />
        </Grid>

        {showMotion && (
          <Grid item xs={12} md={3} sx={{ mt: 2 }}>
            <DeviceMetric
              title="Motion Detected?"
              value="movement"
              icon="eye"
              iconSize="6x"
              aspect={10}
              metricWidth={3}
              graphWidth={12}
              unit=" Times"
              textsize="h3"
            />
          </Grid>
        )}

        <Grid item xs={12} md={showMotion ? 6 : 4} sx={{ mt: 2 }}>
          <GraphBase
            title={"Temperature"}
            value={"temperature"}
            formatter={formatTemperature}
            graphValueFormatter={formatTemperature}
            aspect={3}
          />
        </Grid>

        <Grid item xs={12} md={showMotion ? 6 : 4} sx={{ mt: 2 }}>
          <GraphBase
            title={"Humidity"}
            value={"humidity"}
            formatter={(value) => `${value}%`}
            graphValueFormatter={(value) => `${value}%`}
            aspect={3}
          />
        </Grid>

        <Grid item xs={12} md={showMotion ? 6 : 4} sx={{ mt: 2 }}>
          <GraphBase
            title={"C02"}
            value={"co2"}
            formatter={(value) => `${value}ppm`}
            graphValueFormatter={(value) => `${value}ppm`}
            aspect={3}
          />
        </Grid>

        {showMotion && (
          <Grid item xs={12} md={6} sx={{ mt: 2 }}>
            <GraphBase
              title={"Motion Detected"}
              value={"movement"}
              formatter={(value) => `${value} Times`}
              graphValueFormatter={(value) => `${value} Times`}
              aspect={3}
            />
          </Grid>
        )}

        <Grid item xs={12} md={!isShare ? 6 : 12} sx={{ mt: 2 }}>
          <FrameList state="Motion" />
        </Grid>

        {!isShare && (
          <Grid item xs={12} md={6} sx={{ mt: 2 }}>
            <DeviceActionLogs />
          </Grid>
        )}
      </Grid>
    </>
  );
}
