import React, { useEffect, useState } from "react";
import { formatDate } from "../Functions/formatDate";
import DataTableBase from "./DataTableBase";
import DeleteFramesDialog from "./Dialogs/DeleteFramesDialog";
import { useSelector } from "react-redux";
import removeSelectedFrames from "../Functions/removeSelectedFrames";
import { useTheme } from "@emotion/react";
import { Card } from "@mui/material";
import formatTemperature from "../Functions/formatTemperature";
import formatSpeed from "../Functions/formatSpeed";

const noShowColumns = [
  "longitude",
  "latitude",
  "hotspots",
  "wifi_macs",
  "dont_log_frame",
  "sn",
  "ver",
  "msg",
  "time",
  "debug",
  "low_batt",
  "type",
  "tac",
  "ci",
  "low_temp",
  "high_temp",
  "low_RH",
  "high_RH",
  "temperature_internal",
  "rsrp",
  "rsrq",
  "sinr",
  "event",
];

export default function FrameList(props) {
  const theme = useTheme();
  const sharingShown = props.isShare ? true : false;

  const [deleteFramesDialogOpen, setDeleteFramesDialogOpen] = useState(false);

  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const deviceHistory = useSelector((state) => state.deviceHistory);
  const [deviceHistoryFormatted, setDeviceHistoryFormatted] = useState([]);

  const user = useSelector((state) => state.user);
  const selectedOrganisation = useSelector(
    (state) => state.selectedOrganisation
  );

  const [columns, setColumns] = useState([]);

  //get the keys from the data and create the columns
  useEffect(() => {
    //deviceHistory.data is json, decode the first 10 records and get the keys
    if (deviceHistory && deviceHistory.length) {
      //task: json decode data, create a new array containing time_created and any keys from the json decoded data
      let mappedData = deviceHistory.map((item) => {
        let decodedData = JSON.parse(item.data);
        let keys = Object.keys(decodedData);
        let newData = {
          time_created: formatDate(item.time_created),
        };
        keys.forEach((key) => {
          newData[key] = decodedData[key];
        });
        return newData;
      });

      setDeviceHistoryFormatted(mappedData);

      //get the keys from the first 10 records
      let keys = Object.keys(mappedData[mappedData.length - 1]);
      //remove the keys that are in the noShowColumns array
      keys = keys.filter((key) => {
        return !noShowColumns.includes(key);
      });

      //create the columns
      let newColumns = keys.map((key) => {
        //lets rename some common values like Temp to Temperature and RH to Humidity
        let label = key;

        if (label === "temp") {
          label = "Temperature";
        }

        if (label === "RH") {
          label = "Humidity";
        }

        return {
          name: key,
          label: label
            .replace(/_/g, " ")
            .replace(/\b\w/g, (char) => char.toUpperCase()),
          options: {
            filter: true,
            sort: true,
            customBodyRender: (value, tableMeta, updateValue) => {
              //if the value is an array, return the first value
              if (Array.isArray(value)) {
                value = value[0];
              }

              if (key === "temperature" || key === "temp") {
                return formatTemperature(value);
              }
              if (key === "speedKmph") {
                return formatSpeed(value);
              }
              if (key === "humidity" || key === "RH") {
                return value + "%";
              }
              if (
                key === "moisture" ||
                key === "a_moisture" ||
                key === "b_moisture"
              ) {
                return value + "%";
              }

              if (key === "plug_state") {
                return value ? "On" : "Off";
              }

              return value;
            },
          },
        };
      });

      setColumns(newColumns);
    }
  }, [deviceHistory]);

  return (
    <>
      <Card
        elevation={0}
        sx={{
          background:
            theme.palette.mode === "dark"
              ? "linear-gradient(145deg, rgba(40,40,45,0.9) 0%, rgba(35,35,40,0.8) 100%)"
              : "linear-gradient(145deg, rgba(255,255,255,0.9) 0%, rgba(245,245,245,0.8) 100%)",
          backdropFilter: "blur(10px)",
          border: `1px solid ${
            theme.palette.mode === "dark"
              ? "rgba(255,255,255,0.1)"
              : "rgba(0,0,0,0.1)"
          }`,
          borderRadius: "16px",
        }}
      >
        <DataTableBase
          title={"Frame History"}
          columns={columns}
          data={deviceHistoryFormatted}
          rowsPerPage={10}
          sortOrder="time_created"
          sortDirection="desc"
          selectableRows={
            user &&
            selectedOrganisation &&
            user.id === selectedOrganisation.owner_id
              ? sharingShown
                ? "none"
                : "multiple"
              : "none"
          }
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          actions={
            sharingShown
              ? []
              : [
                  {
                    name: "Delete",
                    icon: "Delete",
                    action: () => {
                      setDeleteFramesDialogOpen(true);
                    },
                  },
                ]
          }
        />
      </Card>
      <DeleteFramesDialog
        open={deleteFramesDialogOpen}
        selectedRows={selectedRows}
        handleClose={() => {
          removeSelectedFrames(selectedRows);
          setSelectedRows([]);
          setDeleteFramesDialogOpen(false);
        }}
      />
    </>
  );
}
