import React from "react";
import { Grid } from "@mui/material";
import LastAlert from "../../Cards/LastAlert";
import BatteryStatus from "../../Cards/BatteryStatus";
import LastSeen from "../../Cards/LastSeen";
import DeviceActionLogs from "../../Cards/Alerts";
import DoorOpenCard from "../../Cards/DoorOpen";
import OpenCount from "../../Cards/DoorCount";
import FrameList from "../../FrameList";
import MultiGraphBase from "../../Graphs/MultiGraph";

export default function DraginoLDS03A(props) {
  let { isShare } = props;

  return (
    <>
      <Grid
        container
        spacing={2}
        alignItems={"stretch"}
        alignContent={"stretch"}
        sx={{ mt: 1 }}
      >
        <Grid item xs={12} md={!isShare ? 4 : 6} sx={{ mb: 2 }}>
          <LastSeen />
        </Grid>
        <Grid item xs={12} md={!isShare ? 4 : 6} sx={{ mb: 2 }}>
          <BatteryStatus />
        </Grid>
        {!isShare && (
          <Grid item xs={12} md={4} sx={{ mb: 2 }}>
            <LastAlert />
          </Grid>
        )}

        <Grid item xs={12} md={3} sx={{ mb: 2 }}>
          <DoorOpenCard val="door_open" title="Door 1 Open?" />
        </Grid>
        <Grid item xs={12} md={3} sx={{ mb: 2 }}>
          <OpenCount val="count" title="Door 1 Times Opened" />
        </Grid>

        <Grid item xs={12} md={3} sx={{ mb: 2 }}>
          <DoorOpenCard val="door_open2" title="Door 2 Open?" />
        </Grid>
        <Grid item xs={12} md={3} sx={{ mb: 2 }}>
          <OpenCount val="count2" title="Door 2 Times Opened" />
        </Grid>

        <Grid item xs={12} md={12} sx={{ mb: 2 }}>
          <MultiGraphBase
            title="Door Open"
            values={["door_open", "door_open2"]}
            value_titles={["Door 1", "Door 2"]}
            truefalse={true}
            trueV="Open"
            falseV="Closed"
            aspect={4}
          />
        </Grid>

        <Grid item xs={12} md={!isShare ? 7 : 12} sx={{ mb: 2 }}>
          <FrameList isShare={isShare} />
        </Grid>

        {!isShare && (
          <>
            <Grid item xs={12} md={5} sx={{ mb: 2 }}>
              <DeviceActionLogs />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}
