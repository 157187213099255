import GaugeBase from "./GaugeBase";
import { WaterDrop } from "@mui/icons-material";
import { useTheme } from "@emotion/react";

export default function TotalVolumeGuage(props) {
  const theme = useTheme();

  let defaultArch = [
    {
      limit: 0,

      color: "#0061f2",
    },
    {
      //blue
      limit: 10,
      color: "#0061f2",
    },
    {
      //green
      limit: 30,
      color: "#00FF00",
    },
    {
      //red
      limit: 50,
      color: "#FF0000",
    },
  ];

  return (
    <GaugeBase
      title="Total Volume"
      field="total_volume"
      icon={
        <WaterDrop
          sx={{
            color: theme.palette.text.secondary,
            opacity: 0.8,
          }}
        />
      }
      defaultArch={defaultArch}
      min={0}
      max={defaultArch ? defaultArch[defaultArch.length - 1].limit : 100}
      unit="%"
    />
  );
}
