import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import PageTitle from "../components/PageTitle";
import DataTableBase from "../components/DataTableBase";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Grid, Typography, useTheme } from "@mui/material";
import fetchData from "../API/Fetch";
import { formatDate } from "../Functions/formatDate";

export default function ActionLogs() {
  const { id } = useParams();

  const [selectedAction, setSelectedAction] = useState({});
  const [actionLogs, setActionLogs] = useState([]);

  const theme = useTheme();

  const devices = useSelector((state) => state.devices);
  const actions = useSelector((state) => state.actions);
  const selectedOrganisationID = localStorage.getItem("selectedOrganisationID");

  const { t } = useTranslation("common");

  /*
   action_id: int = None
    device_id: int = None
    time_created: datetime = None
    message: str = None
    action_alert_status: bool = None
    action_alert_end: datetime = None
    openclose: bool = None
    openclosetime: int: None
    true_false: bool = None
    */

  const columns = useMemo(
    () => [
      {
        name: "id",
        label: t("common.id"),
        options: {
          filter: false,
          display: false,
        },
      },

      {
        name: "time_created",
        label: t("common.time_created"),
        options: {
          filter: true,
          display: true,
          customBodyRender: (value, tableMeta, meta) => {
            return <span>{value ? formatDate(value) : ""}</span>;
          },
        },
      },
      {
        name: "device_id",
        label: t("common.device"),
        options: {
          filter: true,
          display: true,
          customBodyRender: (value, tableMeta, meta) => {
            let device = devices.find((device) => device.id === value);

            return (
              <Typography
                variant="body1"
                onClick={() => {
                  window.location.href = "/device/" + value;
                }}
                sx={{
                  color: theme.palette.primary.main,
                  cursor: "pointer",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
              >
                {device ? device.name : ""}
              </Typography>
            );
          },
        },
      },
      {
        name: "message",
        label: t("common.message"),
        options: {
          filter: true,
          display: true,
        },
      },
      {
        name: "action_alert_status",
        label: t("common.action_alert_status"),
        options: {
          filter: true,
          display: true,
          customBodyRender: (value, tableMeta, meta) => {
            //if false, get action_alert_end say Closed - date, or Open
            return (
              <span>
                {value === false
                  ? "Closed - " +
                    (tableMeta.rowData[5]
                      ? formatDate(tableMeta.rowData[5])
                      : "")
                  : "Open"}
              </span>
            );
          },
        },
      },

      {
        name: "action_alert_end",
        label: t("common.action_alert_end"),
        options: {
          filter: true,
          display: false,
        },
      },
    ],
    [devices, t, theme.palette.primary.main]
  );

  useEffect(() => {
    //find params.id action from actions
    const action = actions.find((action) => action.id === parseInt(id));

    setSelectedAction(action);

    fetchData(`/action/${selectedOrganisationID}/${id}/logs`)
      .then((data) => {
        setActionLogs(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [actions, id, devices, selectedOrganisationID]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sx={{ mb: 2 }}>
        <PageTitle
          title={
            "Action: " +
            (selectedAction && selectedAction.name
              ? selectedAction.name
              : "Action Logs")
          }
          theme={theme}
        />
      </Grid>
      <Grid item xs={12} sx={{ mb: 2 }}>
        <DataTableBase
          title="Action Logs"
          columns={columns}
          data={actionLogs}
        />
      </Grid>
    </Grid>
  );
}
