import * as React from "react";
import Map, {
  FullscreenControl,
  NavigationControl,
  ScaleControl,
  Marker,
  Popup,
} from "react-map-gl";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
import { CONFIG } from "../../config";

import { formatDate } from "../../Functions/formatDate";
import { useNavigate } from "react-router-dom";
import { PitchToggle } from "./PitchToggle";
import { useMapControls } from "../../hooks/useMapControls";

const HOURS_48 = 172800000;

export default function DevicesMap() {
  const devices = useSelector((state) => state.devices);
  const organisation = useSelector((state) => state.selectedOrganisation);
  const pitch = useSelector((state) => state.pitch);
  const navigate = useNavigate();

  const mapRef = React.useRef(null);
  const [showPopup, setShowPopup] = React.useState(false);
  const [selectedDevice, setSelectedDevice] = React.useState(null);

  const { filteredDevices, fitToHistory, initialViewState } = useMapControls(
    devices,
    organisation,
    mapRef
  );

  const handleMapLoad = React.useCallback(
    (e) => {
      mapRef.current = e.target;

      if (
        organisation.hero === "map" &&
        organisation.centerandzoom !== "false"
      ) {
        mapRef.current.flyTo({
          center: organisation.center.split(",").map(Number),
          zoom: organisation.zoom,
          essential: true,
          animate: false,
        });
      } else {
        fitToHistory();
      }

      e.target.addControl(new PitchToggle({ pitch }), "top-left");
    },
    [organisation, fitToHistory, pitch]
  );

  const flyToDevice = React.useCallback((device) => {
    mapRef.current?.flyTo({
      center: [device.lng, device.lat],
      zoom: 15,
      essential: true,
    });
    setSelectedDevice(device);
    setShowPopup(true);
  }, []);

  const isDeviceOutdated = React.useCallback(
    (timeUpdated) => new Date(timeUpdated) < new Date(Date.now() - HOURS_48),
    []
  );

  return (
    <Map
      mapboxApiAccessToken={CONFIG.mapboxAccessToken}
      mapStyle={"mapbox://styles/bfgneil/cle2sqoh8003s01ogafzo3xsl"}
      onLoad={handleMapLoad}
      initialViewState={initialViewState}
      id="map-panel"
    >
      <NavigationControl position="top-left" />
      <FullscreenControl position="top-left" />
      <ScaleControl position="bottom-right" />

      {filteredDevices.map((device) => (
        <Marker
          key={device.id}
          longitude={device.lng}
          latitude={device.lat}
          color={isDeviceOutdated(device.time_updated) ? "red" : "green"}
          onClick={() => flyToDevice(device)}
        />
      ))}

      {showPopup && selectedDevice && (
        <Popup
          anchor="top"
          latitude={selectedDevice.lat}
          longitude={selectedDevice.lng}
          onClose={() => setShowPopup(false)}
          closeOnClick={false}
        >
          <Typography variant="h6">{selectedDevice.name}</Typography>
          <p>
            <strong>Last Seen: </strong>
            {formatDate(selectedDevice.time_updated)}
            <br />
            <strong>Last Location: </strong> {selectedDevice.last_location}
          </p>
          <Button
            variant="contained"
            onClick={() => navigate(`/device/${selectedDevice.id}`)}
          >
            View Device
          </Button>
        </Popup>
      )}
    </Map>
  );
}
