import { Box, Card, CardContent, Tooltip, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { formatDate } from "../../Functions/formatDate";
import { useTheme } from "@emotion/react";
import { useSelector } from "react-redux";
import { AccessTime } from "@mui/icons-material";

export default function LastSeen() {
  const theme = useTheme();
  const device = useSelector((state) => state.device);
  const deviceHistory = useSelector((state) => state.deviceHistory);

  const last_heard = useMemo(() => {
    if (device.time_updated) return device.time_updated;
    if (deviceHistory.length > 0) {
      return deviceHistory[deviceHistory.length - 1].time_created;
    }
    return "N/A";
  }, [device.time_updated, deviceHistory]);

  const isStale = useMemo(() => {
    if (last_heard === "N/A") return false;
    return new Date() - new Date(last_heard) > 172800000;
  }, [last_heard]);

  return (
    <Card
      elevation={0}
      sx={{
        background:
          theme.palette.mode === "dark"
            ? "linear-gradient(145deg, rgba(40,40,45,0.9) 0%, rgba(35,35,40,0.8) 100%)"
            : "linear-gradient(145deg, rgba(255,255,255,0.9) 0%, rgba(245,245,245,0.8) 100%)",
        backdropFilter: "blur(10px)",
        border: `1px solid ${
          theme.palette.mode === "dark"
            ? "rgba(255,255,255,0.1)"
            : "rgba(0,0,0,0.1)"
        }`,
        borderRadius: "16px",
        transition: "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
      }}
    >
      <CardContent sx={{ padding: 2 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <AccessTime
            sx={{
              color: theme.palette.text.secondary,
              opacity: 0.8,
            }}
          />
          <Typography
            variant="subtitle2"
            sx={{
              color: theme.palette.text.secondary,
              letterSpacing: "0.02em",
              fontWeight: 500,
              flexGrow: 1,
            }}
          >
            Last Heard
          </Typography>

          <Tooltip
            title={`Last Heard: ${
              last_heard !== "N/A" ? formatDate(last_heard) : "N/A"
            }`}
          >
            <Typography
              sx={{
                fontWeight: 600,
                color:
                  last_heard !== "N/A"
                    ? isStale
                      ? theme.palette.error.main
                      : theme.palette.success.main
                    : theme.palette.text.primary,
                transition: "color 0.2s ease-in-out",
              }}
            >
              {last_heard && last_heard.length
                ? formatDate(last_heard) === "Invalid date"
                  ? "N/A"
                  : formatDate(last_heard)
                : "N/A"}
            </Typography>
          </Tooltip>
        </Box>
      </CardContent>
    </Card>
  );
}
