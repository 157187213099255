import React from "react";

import FrameList from "../FrameList";
import DeviceActionLogs from "../Cards/Alerts";
import { Grid } from "@mui/material";
import TimelineCard from "../Cards/timeline";
import DeviceInfo from "../Cards/DeviceInfo";
import EventCard from "../Cards/Event";
import { useSelector } from "react-redux";

export default function TrackerWithEvent(props) {
  const isMobile = useSelector((state) => state.isMobile);
  const { isShare } = props;

  return (
    <>
      {
        //history and graphs
      }
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12} md={3} sx={{ mb: 2 }}>
          <EventCard />
        </Grid>
        <Grid item xs={12} md={9} sx={{ mb: 2 }}>
          <TimelineCard />
        </Grid>

        {isMobile && (
          <Grid item xs={12} md={12} sx={{ mb: 2 }}>
            <DeviceInfo />
          </Grid>
        )}

        <Grid item xs={12} md={!isShare ? 8 : 12} sx={{ mb: 2 }}>
          <FrameList />
        </Grid>
        {!isShare && (
          <Grid item xs={12} md={4}>
            <DeviceActionLogs />
          </Grid>
        )}
      </Grid>
    </>
  );
}
