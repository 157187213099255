import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import TemperatureGuague from "../Guages/Temperature";
import DeviceActionLogs from "../Cards/Alerts";
import HumidityGuague from "../Guages/Humidity";
import LastAlert from "../Cards/LastAlert";
import BatteryStatus from "../Cards/BatteryStatus";
import LastSeen from "../Cards/LastSeen";
import ButtonPressedCard from "../Cards/ButtonPressed";
import FrameList from "../FrameList";
import { useSelector } from "react-redux";
import GraphBase from "../Graphs/GraphBase";
import formatTemperature from "../../Functions/formatTemperature";
import MoldIndex from "../Cards/MoldIndex";
import MoldIndexGraph from "../Graphs/MoldIndexGraph";

export default function TempHumidity(props) {
  let { isShare } = props;

  const [showMoldIndex, setShowMoldIndex] = React.useState(false);
  const deviceProfile = useSelector((state) => state.deviceProfile);
  const deviceHistory = useSelector((state) => state.deviceHistory);
  //check to see if there is a button_pressed field in the deviceHistory.data json string
  //if there is, then we know this is a button tracker and we can show the button press graph and card
  let buttonPressed = false;

  console.log("deviceProfile", deviceProfile);

  if (deviceHistory.length > 0) {
    let lastData = deviceHistory[deviceHistory.length - 1];
    let jsonData = lastData.data;
    jsonData = JSON.parse(jsonData);
    //check data contains a button_pressed key (it will be true or false value)
    if (jsonData.button_pressed === 1 || jsonData.button_pressed === 0) {
      buttonPressed = true;
    }
  }

  //if button press is true, set the column to 3, otherwise set it to 4
  let buttonPressColumn = buttonPressed ? 3 : 4;

  if (isShare) {
    buttonPressColumn = buttonPressed ? 4 : 6;
  }

  useEffect(() => {
    if (deviceProfile) {
      if (typeof deviceProfile === "string") {
        let deviceProfileDecoded = JSON.parse(deviceProfile);

        if (!deviceProfileDecoded.moldIndex) return;

        if (deviceProfileDecoded.moldIndex.showMoldIndex) {
          setShowMoldIndex(true);
        } else {
          setShowMoldIndex(false);
        }
      }
    }
  }, [deviceProfile]);

  return (
    <>
      <Grid
        container
        spacing={2}
        alignItems={"stretch"}
        alignContent={"stretch"}
        sx={{ mt: 1 }}
      >
        <Grid item xs={12} md={buttonPressColumn} sx={{ mb: 2 }}>
          <LastSeen />
        </Grid>
        <Grid item xs={12} md={buttonPressColumn} sx={{ mb: 2 }}>
          <BatteryStatus />
        </Grid>

        {buttonPressed && (
          <Grid item xs={12} md={buttonPressColumn} sx={{ mb: 2 }}>
            <ButtonPressedCard alarm={false} />
          </Grid>
        )}

        {!isShare && (
          <Grid item xs={12} md={buttonPressColumn} sx={{ mb: 2 }}>
            <LastAlert />
          </Grid>
        )}
        {showMoldIndex && (
          <>
            <Grid item xs={12} md={3} sx={{ mb: 2 }}>
              <MoldIndex />
            </Grid>

            <Grid item xs={12} md={9} sx={{ mb: 2 }}>
              <MoldIndexGraph />
            </Grid>
          </>
        )}

        <Grid item xs={12} md={3} sx={{ mb: 2 }}>
          <TemperatureGuague />
        </Grid>
        <Grid item xs={12} md={9} sx={{ mb: 2 }}>
          <GraphBase
            title="Temperature"
            value="temperature"
            formatter={formatTemperature}
            graphFormatter={formatTemperature}
            unit={formatTemperature(0, false, true)}
            aspect={3.5}
          />
        </Grid>
        <Grid item xs={12} md={3} sx={{ mb: 2 }}>
          <HumidityGuague field="humidity" />
        </Grid>
        <Grid item xs={12} md={9} sx={{ mb: 2 }}>
          <GraphBase title="Humidity" value="humidity" unit="%" aspect={3.5} />
        </Grid>

        <Grid item xs={12} md={!isShare ? 8 : 12} sx={{ mb: 2 }}>
          <FrameList />
        </Grid>

        {!isShare && (
          <>
            <Grid item xs={12} md={4}>
              <DeviceActionLogs />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}
