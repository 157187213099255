import React from "react";
import {
  Box,
  Card,
  CardContent,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function CardBase(props) {
  const { value, title, icon = "star", height, color } = props;
  const theme = useTheme();

  return (
    <Card
      elevation={0}
      sx={{
        height: height ? height : "100%",
        background:
          theme.palette.mode === "dark"
            ? "linear-gradient(145deg, rgba(40,40,45,0.9) 0%, rgba(35,35,40,0.8) 100%)"
            : "linear-gradient(145deg, rgba(255,255,255,0.9) 0%, rgba(245,245,245,0.8) 100%)",
        backdropFilter: "blur(10px)",
        border: `1px solid ${
          theme.palette.mode === "dark"
            ? "rgba(255,255,255,0.1)"
            : "rgba(0,0,0,0.1)"
        }`,
        borderRadius: "16px",
        transition: "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
        mb: height ? 1 : 0,
      }}
    >
      <CardContent>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <FontAwesomeIcon
            icon={icon}
            size="lg"
            color={theme.palette.text.secondary}
          />
          <Typography
            variant="subtitle2"
            sx={{
              color: theme.palette.text.secondary,
              letterSpacing: "0.02em",
              fontWeight: 500,
              flexGrow: 1,
            }}
          >
            {title}
          </Typography>

          <Tooltip title={`${title}: ${value}`}>
            <Typography
              sx={{
                fontWeight: 600,
                color: color ? color : theme.palette.text.primary,
              }}
            >
              {value}
            </Typography>
          </Tooltip>
        </Box>
      </CardContent>
    </Card>
  );
}
