import {
  Box,
  Card,
  CardHeader,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { useSelector } from "react-redux";
import { useMemo } from "react";

library.add(fas);

const processValue = (rawValue, options) => {
  const {
    trimValue,
    valueToTrim,
    formatter,
    trueFalse,
    trueValue,
    falseValue,
  } = options;

  if (!rawValue && rawValue !== 0) return "N/A";

  let processedValue = Array.isArray(rawValue) ? rawValue[0] : rawValue;

  if (
    trimValue &&
    typeof processedValue === "string" &&
    processedValue.includes(valueToTrim)
  ) {
    processedValue = processedValue.replace(valueToTrim, "");
  }

  if (formatter) {
    processedValue = formatter(processedValue);
  }

  if (trueFalse) {
    return processedValue === 1 ? trueValue : falseValue;
  }

  return processedValue;
};

export default function DeviceMetric({
  title,
  value,
  unit,
  icon,
  iconSize = "2x",
  formatter = null,
  trimValue = false,
  valueToTrim = "",
  textsize = "h2",
  valueColor = "",
  trueFalse = false,
  trueValue = "True",
  falseValue = "False",
  height,
}) {
  const theme = useTheme();
  const deviceHistory = useSelector((state) => state.deviceHistory);

  const displayValue = useMemo(() => {
    if (!deviceHistory?.length) return "N/A";

    try {
      const lastRecord = deviceHistory[deviceHistory.length - 1];
      const data = JSON.parse(lastRecord.data);

      return processValue(data[value], {
        trimValue,
        valueToTrim,
        formatter,
        trueFalse,
        trueValue,
        falseValue,
      });
    } catch (error) {
      console.error("Error processing device metric:", error);
      return "N/A";
    }
  }, [
    deviceHistory,
    value,
    trimValue,
    valueToTrim,
    formatter,
    trueFalse,
    trueValue,
    falseValue,
  ]);

  const colorStyle = useMemo(
    () => ({
      color: valueColor || theme.palette.primary.main,
    }),
    [valueColor, theme.palette.primary.main]
  );

  return (
    <Card
      elevation={0}
      sx={{
        display: "flex",
        flexDirection: "column",
        height: height ? height : "100%",
        background:
          theme.palette.mode === "dark"
            ? "linear-gradient(145deg, rgba(40,40,45,0.9) 0%, rgba(35,35,40,0.8) 100%)"
            : "linear-gradient(145deg, rgba(255,255,255,0.9) 0%, rgba(245,245,245,0.8) 100%)",
        backdropFilter: "blur(10px)",
        border: `1px solid ${
          theme.palette.mode === "dark"
            ? "rgba(255,255,255,0.1)"
            : "rgba(0,0,0,0.1)"
        }`,
        borderRadius: "16px",
        marginBottom: height ? 1 : 0,
      }}
    >
      <CardHeader
        title={title}
        sx={{
          ".MuiCardHeader-title": {
            fontSize: "0.95rem",
            fontWeight: 500,
            color: theme.palette.text.secondary,
            letterSpacing: "0.02em",
          },
          pb: 0,
        }}
      />
      <Stack
        spacing={2}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          p: 3,
          pt: 2,
          flex: "1 1 auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            p: 1.5,
            borderRadius: "12px",
            background:
              theme.palette.mode === "dark"
                ? "rgba(255,255,255,0.05)"
                : "rgba(0,0,0,0.03)",
          }}
        >
          <FontAwesomeIcon
            icon={icon}
            size={iconSize}
            style={{
              ...colorStyle,
              opacity: 0.9,
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "baseline",
            justifyContent: "flex-end",
            flex: 1,
          }}
        >
          <Typography
            variant={textsize}
            sx={{
              fontWeight: 600,
              ...colorStyle,
              fontSize: textsize === "h2" ? "1.8rem" : "2rem",
            }}
          >
            {displayValue}
          </Typography>
          {unit && displayValue !== "N/A" && (
            <Typography
              component="span"
              sx={{
                ...colorStyle,
                opacity: 0.8,
                fontSize: "1rem",
                fontWeight: 500,
              }}
            >
              {unit}
            </Typography>
          )}
        </Box>
      </Stack>
    </Card>
  );
}
