import React from "react";
import { Grid } from "@mui/material";
import TemperatureGuague from "../Guages/Temperature";
import DeviceActionLogs from "../Cards/Alerts";
import HumidityGuague from "../Guages/Humidity";
import LastAlert from "../Cards/LastAlert";
import BatteryStatus from "../Cards/BatteryStatus";
import LastSeen from "../Cards/LastSeen";
import FrameList from "../FrameList";
import GraphBase from "../Graphs/GraphBase";
import DeviceMetric from "../Cards/DeviceMetric";

export default function WaterLeakTempHumidity(props) {
  let { isShare } = props;

  return (
    <>
      <Grid
        container
        spacing={2}
        alignItems={"stretch"}
        alignContent={"stretch"}
        sx={{ mt: 1 }}
      >
        <Grid item xs={12} md={!isShare ? 4 : 6} sx={{ mb: 2 }}>
          <LastSeen />
        </Grid>
        <Grid item xs={12} md={!isShare ? 4 : 6} sx={{ mb: 2 }}>
          <BatteryStatus />
        </Grid>
        {!isShare && (
          <Grid item xs={12} md={4} sx={{ mb: 2 }}>
            <LastAlert />
          </Grid>
        )}

        <Grid item xs={12} md={2} sx={{ mb: 2 }}>
          <DeviceMetric
            title="Water Leak Detected"
            value="water_leak"
            truefalse={true}
            trueV="Leak Detected"
            falseV="No Leaks Detected"
            icon="water"
          />
        </Grid>

        <Grid item xs={12} md={10} sx={{ mb: 2 }}>
          <GraphBase
            title="Water Leak"
            value="water_leak"
            truefalse={true}
            trueV="Yes"
            falseV="No"
            aspect={10}
          />
        </Grid>
        <Grid item xs={12} md={3} sx={{ mb: 2 }}>
          <TemperatureGuague />
        </Grid>
        <Grid item xs={12} md={9} sx={{ mb: 2 }}>
          <GraphBase title="Temperature" value="temperature" aspect={3.5} />
        </Grid>
        <Grid item xs={12} md={3} sx={{ mb: 2 }}>
          <HumidityGuague />
        </Grid>
        <Grid item xs={12} md={9} sx={{ mb: 2 }}>
          <GraphBase title="Humidity" value="humidity" />
        </Grid>

        <Grid item xs={12} md={!isShare ? 8 : 12} sx={{ mb: 2 }}>
          <FrameList />
        </Grid>

        {!isShare && (
          <Grid item xs={12} md={4} sx={{ mb: 2 }}>
            <DeviceActionLogs />
          </Grid>
        )}
      </Grid>
    </>
  );
}
