/*"ignition": None
"headingDeg": None,
"speedKmph": None
"tempC": None,
"runtime": None,
"distanceKm": None,*/

import React from "react";

import FrameList from "../FrameList";
import DeviceActionLogs from "../Cards/Alerts";
import { Grid } from "@mui/material";
import TimelineCard from "../Cards/timeline";
import DeviceInfo from "../Cards/DeviceInfo";
import { useSelector } from "react-redux";
import DeviceMetric from "../Cards/DeviceMetric";
import formatSpeed from "../../Functions/formatSpeed";
import formatTemperature from "../../Functions/formatTemperature";

export default function DigitalMatterG62(props) {
  const isMobile = useSelector((state) => state.isMobile);
  const { isShare } = props;
  return (
    <>
      {
        //history and graphs
      }
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12} md={12} sx={{ mb: 2 }}>
          <TimelineCard />
        </Grid>

        {isMobile && (
          <Grid item xs={12} md={12} sx={{ mb: 2 }}>
            <DeviceInfo />
          </Grid>
        )}

        <Grid item xs={12} md={4} sx={{ mb: 2 }}>
          <DeviceMetric
            value="ignition"
            title="Ignition Status"
            icon="car"
            iconSize="6x"
          />
        </Grid>

        <Grid item xs={12} md={4} sx={{ mb: 2 }}>
          <DeviceMetric
            value="speedKmph"
            title="Speed"
            icon="tachometer-alt"
            iconSize="6x"
            formatter={formatSpeed}
          />
        </Grid>

        <Grid item xs={12} md={4} sx={{ mb: 2 }}>
          <DeviceMetric
            value="tempC"
            title="Temperature"
            icon="thermometer-half"
            iconSize="6x"
            formatter={formatTemperature}
          />
        </Grid>

        <Grid item xs={12} md={4} sx={{ mb: 2 }}>
          <DeviceMetric
            value="runtime"
            title="Runtime"
            icon="clock"
            iconSize="6x"
          />
        </Grid>

        <Grid item xs={12} md={4} sx={{ mb: 2 }}>
          <DeviceMetric
            value="distanceKm"
            title="Distance"
            icon="road"
            iconSize="6x"
          />
        </Grid>

        <Grid item xs={12} md={4} sx={{ mb: 2 }}>
          <DeviceMetric
            value="headingDeg"
            title="Heading"
            icon="compass"
            iconSize="6x"
          />
        </Grid>

        <Grid item xs={12} md={!isShare ? 8 : 12} sx={{ mb: 2 }}>
          <FrameList />
        </Grid>
        {!isShare && (
          <Grid item xs={12} md={4} sx={{ mb: 2 }}>
            <DeviceActionLogs />
          </Grid>
        )}
      </Grid>
    </>
  );
}
