import * as React from "react";
import Map, {
  FullscreenControl,
  NavigationControl,
  ScaleControl,
  Marker,
} from "react-map-gl";
import { CONFIG } from "../../config";
import { Card, useTheme } from "@mui/material";
import mapboxgl from "mapbox-gl";
import WidgetEditOptions from "../widgetEditOptions";
import { formatDate } from "../../Functions/formatDate";
import { useSelector } from "react-redux";

mapboxgl.accessToken = CONFIG.mapboxAccessToken;

export default function MapWidget(props) {
  const devices = props.devices;
  const theme = useTheme();
  const mapRef = React.useRef(null);

  const { editMode, widget, dashboardId, refreshDashboard } = props;

  const isMobile = useSelector((state) => state.isMobile);
  const [mapType, setMapType] = React.useState("roadmap");

  const [selectedDevices, setSelectedDevices] = React.useState([]);

  const handleMapLoad = (e) => {
    mapRef.current = e.target;

    //first get widget.field, then parse it, get the selectedDevices and then set selectedDevices to be devices.filter
    let field = JSON.parse(widget.field);

    if (field.selectedDevices) {
      //each value will be the deviceid,true or deviceid,false
      let selectedDevices = Object.entries(field.selectedDevices);

      //each value is a string deviceid,true or deviceid,false, only want devicesids where the value is true

      let filter = [];

      selectedDevices.forEach((device) => {
        //split the string into an array
        let deviceArray = device[1].split(",");
        //if the second value is true, add the device to the filter
        if (deviceArray[1] === "true") {
          filter.push(parseInt(deviceArray[0]));
        }
      });

      let filtered = devices.filter((device) => filter.includes(device.id));

      setSelectedDevices(filtered);
    } else {
      setSelectedDevices(devices);
    }
  };

  const flyToDevice = (device, e) => {
    if (!mapRef.current) return;
    mapRef.current.flyTo({
      center: [device.lng, device.lat],
      zoom: 15,
      essential: true,
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
    setWidgetMenuOpen(false);
  };

  React.useEffect(() => {
    //get widget.field, json parse it and if mapType is satellite, set it to satellite
    if (widget && widget.field) {
      const field = JSON.parse(widget.field);
      if (field.MapType) {
        setMapType(field.MapType);
      }
    }
  }, [widget]);

  React.useEffect(() => {
    if (!mapRef.current) return;
    if (!selectedDevices.length) return;

    //when selectedDevices changes, fit to bounds, make sure maxZoom is 15
    if (selectedDevices.length > 0) {
      let bounds = new mapboxgl.LngLatBounds();
      selectedDevices.forEach((device) => {
        //check if lat and lng exist, and are not empty or 0 or null
        if (device.lat && device.lng) {
          bounds.extend([device.lng, device.lat]);
        }

        //check bounds is valid
        if (bounds._ne && bounds._sw) {
          mapRef.current.fitBounds(bounds, {
            padding: 50,
            maxZoom: 15,
            essential: true,
          });
        }
      });
    }
  }, [selectedDevices, isMobile, mapRef, devices]);

  const [widgetMenuOpen, setWidgetMenuOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  class settingsMenu {
    onAdd(map) {
      this._map = map;

      this._btn = document.createElement("button");

      this._btn.className = "mapboxgl-ctrl-icon mapbox-ctrl-settings";
      this._btn.title = "Widget Settings";
      this._btn.type = "button";
      this._btn["aria-label"] = "Widget Settings";
      this._btn.onclick = function () {
        setAnchorEl(this);
        setWidgetMenuOpen(!widgetMenuOpen);
      };

      this._container = document.createElement("div");
      this._container.className = "mapboxgl-ctrl-group mapboxgl-ctrl";
      this._container.appendChild(this._btn);

      return this._container;
    }

    onRemove() {
      this._container.parentNode.removeChild(this._container);
      this._map = undefined;
    }
  }

  const settings = new settingsMenu();

  if (editMode) {
    return (
      <Card
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <p>Map Widget</p>
      </Card>
    );
  }

  return (
    <>
      <Map
        mapboxApiAccessToken={CONFIG.mapboxAccessToken}
        mapStyle={
          mapType === "satellite"
            ? "mapbox://styles/bfgneil/clzjpuk4o00lc01qy302x2slj"
            : theme.palette.mode === "light"
            ? "mapbox://styles/mapbox/streets-v11"
            : "mapbox://styles/mapbox/dark-v10"
        }
        onLoad={(e) => {
          mapRef.current = e.target;
          handleMapLoad(e);
          e.target.addControl(settings, "top-left");
        }}
        className="widget-map"
      >
        <NavigationControl position="top-left" />
        <FullscreenControl position="top-left" />
        <ScaleControl position="bottom-right" />

        {selectedDevices.map((device) =>
          //if there is no lat or lng, don't render the marker
          !device.lat || !device.lng ? null : (
            //if time_updated is more than 48 hours ago, color the marker red
            <Marker
              key={device.id}
              longitude={device.lng}
              latitude={device.lat}
              color={
                new Date(device.time_updated) < new Date(Date.now() - 172800000)
                  ? "red"
                  : "green"
              }
              onClick={(e) => {
                flyToDevice(device, e);
                //open a popup with the device name, location and last updated time
                e.target.setPopup(
                  new mapboxgl.Popup({ offset: 25 }).setHTML(
                    `<h3>${device.name}</h3><p>${
                      device.last_location
                    }</p><p>Last Updated: ${formatDate(
                      device.time_updated
                    )}</p><p><a style="color:white;background-color:green;padding:10px;border-radius:10px;text-decoration:none;margin-top:10px;"
                     href="/device/${device.id}">View Device</a></p>`
                  )
                );
              }}
            />
          )
        )}
      </Map>
      <WidgetEditOptions
        anchorEl={anchorEl}
        handleClose={handleClose}
        widget={widget}
        widgetMenuOpen={widgetMenuOpen}
        dashboardId={dashboardId}
        refreshDashboard={refreshDashboard}
      />
    </>
  );
}
