import AirQuality from "../components/DeviceTypes/AirQuality";
import TempHumidity from "../components/DeviceTypes/TempHumidity";
import TrackerWithButton from "../components/DeviceTypes/TrackerWithButton";
import Distance from "../components/DeviceTypes/Distance";
import StickerTracker from "../components/DeviceTypes/StickerTracker";
import PeopleCounter from "../components/DeviceTypes/PeopleCounter";
import Temperature from "../components/DeviceTypes/Temperature";
import MotionWithTemp from "../components/DeviceTypes/MotionWithTempHumidity";
import DoorWindowWithTemp from "../components/DeviceTypes/DoorWindowWithTemp";
import WaterLeakTempHumidity from "../components/DeviceTypes/WaterLeakTempHumidity";
import AirQualityTVOC from "../components/DeviceTypes/AirQualityTVOC";
import PetTracker from "../components/DeviceTypes/PetTracker";
import Oyster3 from "../components/DeviceTypes/Oyster3";
import TrackerWithEvent from "../components/DeviceTypes/TrackerWithEvent";
import TektelicVivid from "../components/DeviceTypes/TektelicVivid";
import Tracker from "../components/DeviceTypes/Tracker";
import WaterMeterSensecore from "../components/DeviceTypes/WaterMeterSensecore";
import Power from "../components/DeviceTypes/Power";
import WaterLeakNBIOT from "../components/DeviceTypes/WaterLeak";
import IndoorAirQualityNBIOT from "../components/DeviceTypes/IndoorAirQualityNBIOT";
import TempHumidityNbIoT from "../components/DeviceTypes/TempHumidityNBIoT";
import DryContact from "../components/DeviceTypes/DryContact";
import DistanceNBIOT from "../components/DeviceTypes/MotionNBIOT";
import RobSound from "../components/DeviceTypes/RobSound";
import WaterFlow from "../components/DeviceTypes/WaterFlow";
import Rak4Button from "../components/DeviceTypes/Rak4Button";
import Lsn50v2_d20 from "../components/DeviceTypes/lsn50v2_d20";
import LHT52 from "../components/DeviceTypes/LHT52";
import DoorWindow from "../components/DeviceTypes/DoorWindow";
import GoIoTwiskaairhumiditypmqos from "../components/DeviceTypes/GoIoTwiskaairhumiditypmqos";
import DraginoLES01 from "../components/DeviceTypes/Dragino/DraginoLSE01";
import DraginoLsph01 from "../components/DeviceTypes/Dragino/DraginoLsph01";
import DraginoLlms01 from "../components/DeviceTypes/Dragino/DraginoLlms01";
import DraginoLSN50V2D20 from "../components/DeviceTypes/Dragino/Dragino-LSN50V2_d20";
import DraginoLSN50v2S31 from "../components/DeviceTypes/Dragino/DraginoLSN50v2S31";
import VutilityHotdrop from "../components/DeviceTypes/VutilityHotdrop";
import CustomPressure01 from "../components/DeviceTypes/CustomPressure";
import DigitalMatterG62 from "../components/DeviceTypes/DigitalMatterG62";
import DraginoLDS03A from "../components/DeviceTypes/Dragino/DraginoLDS03A";
import MokoSmartLW005 from "../components/DeviceTypes/LW005";
import IndoorAirQualityNBIOTBattery from "../components/DeviceTypes/IndoorAirQualityNBIOTBattery";
import DraginoLDS02 from "../components/DeviceTypes/Dragino/DraginoLDS02";
import BrowanTBDW100 from "../components/DeviceTypes/browan/TBDW100";
import Senseo8EnergyMonitor from "../components/DeviceTypes/Senso8/EnergyMonitor";
import Bolt2 from "../components/DeviceTypes/Bolt2";
import irisMold from "../components/DeviceTypes/irismold";
import Harmony from "../components/DeviceTypes/HarmonyAnalytica/Harmony";

export const DEVICE_TYPE_COMPONENTS = {
  1: Tracker,
  2: AirQuality,
  3: TempHumidity,
  4: TrackerWithButton,
  5: Distance,
  6: MotionWithTemp,
  7: StickerTracker,
  8: PeopleCounter,
  9: Temperature,
  10: MotionWithTemp,
  11: DoorWindowWithTemp,
  12: WaterLeakTempHumidity,
  13: AirQualityTVOC,
  14: PetTracker,
  15: Oyster3,
  16: TrackerWithEvent,
  17: Tracker, //was cayenneLPP
  18: TektelicVivid,
  19: WaterMeterSensecore,
  20: Distance,
  21: Power,
  22: WaterLeakNBIOT,
  23: IndoorAirQualityNBIOT,
  24: TempHumidityNbIoT,
  25: DryContact,
  26: DistanceNBIOT,
  27: RobSound,
  28: WaterFlow,
  29: Rak4Button,
  30: Lsn50v2_d20,
  31: LHT52,
  32: DoorWindow,
  33: GoIoTwiskaairhumiditypmqos,
  34: DraginoLES01,
  35: DraginoLsph01,
  36: DraginoLlms01,
  37: DraginoLSN50V2D20,
  38: DraginoLSN50v2S31,
  39: VutilityHotdrop,
  40: CustomPressure01,
  41: DigitalMatterG62,
  42: DraginoLDS03A,
  43: MokoSmartLW005,
  44: Bolt2,
  45: IndoorAirQualityNBIOTBattery,
  46: DraginoLDS02,
  47: BrowanTBDW100,
  48: Senseo8EnergyMonitor,
  49: irisMold,
  50: Harmony,
};
