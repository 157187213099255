import { useState, useCallback } from 'react';

export const useCollapsedState = () => {
  const [collapsed, setCollapsed] = useState(
    () => localStorage.getItem("sidebarCollapsed") === "true"
  );

  const handleCollapsed = useCallback(() => {
    setCollapsed(prev => {
      const newValue = !prev;
      localStorage.setItem("sidebarCollapsed", newValue);
      return newValue;
    });
  }, []);

  return { collapsed, handleCollapsed };
};