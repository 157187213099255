import React, { useEffect, useRef, useState } from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import fetchAssets from "../Functions/fetchAssets";
import PageTitle from "../components/PageTitle";
import { useTheme } from "@emotion/react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Stack,
} from "@mui/material";
import AssetMetricAddDialog from "../components/Dialogs/assetMetricaAdd";
import { Add } from "@mui/icons-material";
import AssetMetric from "../components/AssetMetric";
import AssetMap from "../components/Maps/AssetMap";
import fetchAssetHistory from "../Functions/fetchAssetHistory";

function Asset(props) {
  const { id } = useParams();
  const refreshDataInterval = useRef(null);

  const [addMetricDialogOpen, setAddMetricDialogOpen] = useState(false);

  const theme = useTheme();

  const assets = useSelector((state) => state.assets);

  const [asset, setAsset] = useState(null);

  const isMobile = useSelector((state) => state.isMobile);

  const selectedOrganisation = useSelector(
    (state) => state.selectedOrganisation
  );

  //refresh_interval
  const refresh_interval = 5;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!assets || assets.length === 0 || !id || !selectedOrganisation) {
      return;
    }

    try {
      //refresh data every 2 minutes
      refreshDataInterval.current = setInterval(
        () => {
          fetchAssets(selectedOrganisation.id);
        },
        //refredh interval in minutes to seconds
        60 * 1000
      );
    } catch (error) {
      console.log(error);
    }

    let findAsset = assets.find((asset) => parseInt(asset.id) === parseInt(id));
    if (findAsset) {
      setAsset(findAsset);
      setLoading(false);
    }
    return () => {
      clearInterval(refreshDataInterval.current);
    };
  }, [assets, id, selectedOrganisation, refresh_interval]);

  useEffect(() => {
    if (!asset) {
      return;
    }
    fetchAssetHistory(asset.id);
  }, [asset]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack
            direction="row"
            justifyContent={isMobile ? "flex-start" : "flex-end"}
            sx={{ mt: 1 }}
          >
            <PageTitle
              theme={theme}
              title={asset && asset.name ? asset.name : "Asset"}
              subTitle={asset && asset.description}
            />
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setAddMetricDialogOpen(true)}
            >
              <Add />
              Add Metric
            </Button>
          </Stack>
        </Grid>
        {
          //if it has a metric_type_id of 3, show map
        }
        {
          //filter out asset metrics for location
          asset && asset.metrics && asset.metrics.length > 0
            ? asset.metrics.map((metric, index) => {
                if (metric.metric_type_id === 3) {
                  return <AssetMap metric={metric} />;
                }
                return null;
              })
            : null
        }

        {asset && asset.metrics && asset.metrics.length > 0 ? (
          asset.metrics.map((metric, index) => {
            //if asset_metric_type_id = 3, dont show
            if (metric.metric_type_id === 3) {
              return null;
            }
            return <AssetMetric key={index} metric={metric} asset={asset} />;
          })
        ) : (
          <Grid item xs={3} sx={{ mt: 1 }}>
            <Card
              sx={{
                p: 2,
                textAlign: "center",
                color: theme.palette.text.secondary,
              }}
              onClick={() => setAddMetricDialogOpen(true)}
            >
              <CardHeader
                title="No Metrics Found"
                subheader="Click to add a metric"
              />
              <CardContent>
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >
                  <Add />
                  <span>Add a new metric</span>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
      <AssetMetricAddDialog
        open={addMetricDialogOpen}
        handleClose={() => setAddMetricDialogOpen(false)}
        asset={asset}
      />
    </>
  );
}

export default withAuthenticationRequired(Asset);
