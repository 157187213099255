import { Box, Card, CardContent, Tooltip, Typography } from "@mui/material";
import React, { useMemo } from "react";
import AlertLogDialog from "../Dialogs/AlertLog";
import { formatDate } from "../../Functions/formatDate";
import { useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";
import { useSelector } from "react-redux";
import { NotificationsActive } from "@mui/icons-material";

export default function LastAlert() {
  const [alertLogDialogOpen, setAlertLogDialogOpen] = React.useState(false);
  const { t } = useTranslation("common");
  const theme = useTheme();
  const deviceAlerts = useSelector((state) => state.deviceAlerts);

  const lastAlert = useMemo(
    () => (deviceAlerts && deviceAlerts.length > 0 ? deviceAlerts[0] : null),
    [deviceAlerts]
  );

  const toggleAlertLogDialogOpen = () => {
    setAlertLogDialogOpen(!alertLogDialogOpen);
  };

  return (
    <>
      <Card
        elevation={0}
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          background:
            theme.palette.mode === "dark"
              ? "linear-gradient(145deg, rgba(40,40,45,0.9) 0%, rgba(35,35,40,0.8) 100%)"
              : "linear-gradient(145deg, rgba(255,255,255,0.9) 0%, rgba(245,245,245,0.8) 100%)",
          backdropFilter: "blur(10px)",
          border: `1px solid ${
            theme.palette.mode === "dark"
              ? "rgba(255,255,255,0.1)"
              : "rgba(0,0,0,0.1)"
          }`,
          borderRadius: "16px",
          transition: "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
        }}
      >
        <CardContent>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <NotificationsActive
              sx={{
                color: theme.palette.text.secondary,
                opacity: 0.8,
              }}
            />
            <Typography
              variant="subtitle2"
              sx={{
                color: theme.palette.text.secondary,
                letterSpacing: "0.02em",
                fontWeight: 500,
                flexGrow: 1,
              }}
            >
              Last Alert
            </Typography>

            {lastAlert?.message ? (
              <Tooltip title={lastAlert.message}>
                <Typography
                  onClick={() => setAlertLogDialogOpen(true)}
                  sx={{
                    fontWeight: 600,
                    color: theme.palette.primary.main,
                    cursor: "pointer",
                    transition: "color 0.2s ease-in-out",
                    "&:hover": {
                      color: theme.palette.primary.dark,
                    },
                  }}
                >
                  {formatDate(lastAlert.time_created)}
                </Typography>
              </Tooltip>
            ) : (
              <Tooltip title={`Last Alert: ${t("common.none")}`}>
                <Typography
                  sx={{
                    fontWeight: 600,
                    color: theme.palette.text.primary,
                  }}
                >
                  {t("common.none")}
                </Typography>
              </Tooltip>
            )}
          </Box>
        </CardContent>
      </Card>
      <AlertLogDialog
        open={alertLogDialogOpen}
        handleClose={toggleAlertLogDialogOpen}
        lastAlert={lastAlert}
      />
    </>
  );
}
