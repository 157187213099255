import DeviceMetric from "./DeviceMetric";

export default function HasFlow(props) {
  return (
    <DeviceMetric
      title="Has Flow?"
      field={"HasFlowRate"}
      unit=""
      icon="water"
      trueFalse={true}
      trueValue="Yes"
      falseValue="No"
    />
  );
}
