import React from "react";
import { Grid } from "@mui/material";
import TemperatureGuague from "../Guages/Temperature";
import HumidityGuague from "../Guages/Humidity";

import LastAlert from "../Cards/LastAlert";
import LastSeen from "../Cards/LastSeen";
import DeviceActionLogs from "../Cards/Alerts";

import FrameList from "../FrameList";

import formatTemperature from "../../Functions/formatTemperature";
import SignalQuality from "../Cards/SignalQuality";
import C02Guague from "../Guages/c02";
import AQIGuague from "../Guages/AQI";
import GraphBase from "../Graphs/GraphBase";

export default function IndoorAirQualityNBIOTBattery(props) {
  let { isShare } = props;

  return (
    <>
      <Grid
        container
        spacing={2}
        alignItems={"stretch"}
        alignContent={"stretch"}
        sx={{ mt: 1 }}
      >
        <Grid item xs={12} lg={isShare ? 6 : 4} sx={{ mb: 2 }}>
          <LastSeen />
        </Grid>

        <Grid item xs={12} lg={isShare ? 6 : 4} sx={{ mb: 2 }}>
          <SignalQuality />
        </Grid>

        {!isShare && (
          <>
            <Grid item xs={12} lg={4} sx={{ mb: 2 }}>
              <LastAlert />
            </Grid>
          </>
        )}
        <Grid item xs={12} lg={3} sx={{ mb: 2 }}>
          <TemperatureGuague field="temp" />
        </Grid>
        <Grid item xs={12} lg={3} sx={{ mb: 2 }}>
          <HumidityGuague field="RH" />
        </Grid>
        <Grid item xs={12} lg={3} sx={{ mb: 2 }}>
          <C02Guague field="CO2" />
        </Grid>
        <Grid item xs={12} lg={3} sx={{ mb: 2 }}>
          <AQIGuague value="AQI" />
        </Grid>

        <Grid item xs={12} lg={6} sx={{ mb: 2 }}>
          <GraphBase
            title="Temperature"
            value="temp"
            formatter={formatTemperature}
            graphFormatter={formatTemperature}
          />
        </Grid>

        <Grid item xs={12} lg={6} sx={{ mb: 2 }}>
          <GraphBase title="Humidity" value="RH" />
        </Grid>

        <Grid item xs={12} lg={6} sx={{ mb: 2 }}>
          <GraphBase
            title="c02"
            value="CO2"
            formatter={(value) => value + " ppm"}
            graphFormatter={(value) => value + " ppm"}
            unit="ppm"
          />
        </Grid>

        <Grid item xs={12} lg={6} sx={{ mb: 2 }}>
          <GraphBase title="AQI" value="AQI" />
        </Grid>

        <Grid item xs={12} lg={!isShare ? 7 : 12}>
          <FrameList isShare={isShare} />
        </Grid>

        {!isShare && (
          <>
            <Grid item xs={12} lg={5}>
              <DeviceActionLogs />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}
