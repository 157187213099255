import { useCallback, useMemo } from "react";
import mapboxgl from "mapbox-gl";

export const useMapControls = (devices, organisation, mapRef) => {
  const filteredDevices = useMemo(
    () =>
      Array.isArray(devices)
        ? devices.filter((device) => device.lat && device.lng)
        : [],
    [devices]
  );

  const center = useMemo(() => {
    if (!organisation.center) return [0, 0];
    return typeof organisation.center === "string"
      ? organisation.center.split(",").map(Number)
      : organisation.center;
  }, [organisation.center]);

  const fitToHistory = useCallback(() => {
    const bounds = new mapboxgl.LngLatBounds();

    if (!filteredDevices.length) return;

    filteredDevices.forEach((device) => {
      bounds.extend([device.lng, device.lat]);
    });

    if (bounds.getNorthEast().distanceTo(bounds.getSouthWest()) < 500) {
      bounds.extend([
        bounds.getNorthEast().lng + 0.001,
        bounds.getNorthEast().lat + 0.001,
      ]);
      bounds.extend([
        bounds.getSouthWest().lng - 0.001,
        bounds.getSouthWest().lat - 0.001,
      ]);
    }

    mapRef.current?.fitBounds(bounds, {
      padding: 50,
      animate: false,
    });
  }, [filteredDevices, mapRef]);

  const initialViewState = useMemo(() => {
    if (!organisation.centerandzoom || organisation.centerandzoom === "false") {
      return {};
    }

    return {
      latitude: center[1] || 0,
      longitude: center[0] || 0,
      zoom: organisation.zoom !== "false" ? organisation.zoom : 10,
    };
  }, [organisation, center]);

  return {
    filteredDevices,
    center,
    fitToHistory,
    initialViewState,
  };
};
