import { Grid } from "@mui/material";
import React from "react";
import LastSeen from "../../Cards/LastSeen";
import BatteryStatus from "../../Cards/BatteryStatus";
import LastAlert from "../../Cards/LastAlert";
import TemperatureGuague from "../../Guages/Temperature";
import DeviceActionLogs from "../../Cards/Alerts";
import FrameList from "../../FrameList";
import PHGuague from "../../Guages/PHGuague";
import GraphBase from "../../Graphs/GraphBase";
import formatTemperature from "../../../Functions/formatTemperature";

export default function DraginoLsph01(props) {
  let { isShare } = props;

  return (
    <>
      <Grid
        container
        spacing={2}
        alignItems={"stretch"}
        alignContent={"stretch"}
        sx={{ mt: 1 }}
      >
        <Grid item xs={12} md={!isShare ? 4 : 6} sx={{ mb: 2 }}>
          <LastSeen />
        </Grid>
        <Grid item xs={12} md={!isShare ? 4 : 6} sx={{ mb: 2 }}>
          <BatteryStatus />
        </Grid>
        {!isShare && (
          <Grid item xs={12} md={4} sx={{ mb: 2 }}>
            <LastAlert />
          </Grid>
        )}

        <Grid item xs={12} md={3} sx={{ mb: 2 }}>
          <TemperatureGuague
            field="soil_temperature"
            title="Soil Temperature"
          />
        </Grid>

        <Grid item xs={12} md={9} sx={{ mb: 2 }}>
          <GraphBase
            title="Soil Temperature"
            value="soil_temperature"
            formatter={formatTemperature}
            graphFormatter={formatTemperature}
            aspect={3.5}
          />
        </Grid>
        <Grid item xs={12} md={3} sx={{ mb: 2 }}>
          <PHGuague value="soil_ph" title="Soil PH" />
        </Grid>

        <Grid item xs={12} md={9} sx={{ mb: 2 }}>
          <GraphBase
            value="soil_ph"
            title="Soil PH"
            unit="ph"
            min="0"
            max="14"
            aspect={3.5}
          />
        </Grid>

        <Grid item xs={12} md={isShare ? 12 : 7} sx={{ mb: 2 }}>
          <FrameList />
        </Grid>

        {!isShare && (
          <>
            <Grid item xs={12} md={5}>
              <DeviceActionLogs />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}
