import React, { useMemo } from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Grid, useTheme, Box } from "@mui/material";
import AddDeviceDialog from "../components/Dialogs/addDevice";
import DeviceCard from "../components/Cards/Device";
import AddDeviceCard from "../components/Cards/AddDevice";
import hasPermission from "../Functions/hasPermission";
import Hero from "../components/hero";
import { useScreenSize } from "../hooks/useScreenSize";
import { useDevicesState } from "../hooks/useDevicesState";
import { useDevicesPagination } from "../hooks/useDevicesPagination";
import { useTranslation } from "react-i18next";
import DevicesToolbar from "../components/DevicesToolbar";
import DevicesPagination from "../components/DevicesPagination";
import { useDevicesSelectors } from "../hooks/useDevicesSelectors";
import { useDeviceActions } from "../hooks/useDeviceActions";
import { useToolbarProps } from "../hooks/useToolbarProps";

const Devices = () => {
  const theme = useTheme();
  const { t } = useTranslation("common");
  const {
    selectedOrganisation,
    devices,
    user,
    userOrganisationRoles,
    isMobile,
  } = useDevicesSelectors();

  const { isXL, devicesPerPageOptions } = useScreenSize();
  const {
    currentPage,
    setCurrentPage,
    devicesPerPage,
    filter,
    setFilter,
    totalPages,
    handleDevicesPerPageChange,
    filteredAndPaginatedDevices,
  } = useDevicesPagination(devices, isXL);

  const { noDevices, addDeviceOpen, setAddDeviceOpen } = useDevicesState(
    devices,
    selectedOrganisation
  );

  const { handleRefresh, handleDeviceDialogClose } =
    useDeviceActions(setAddDeviceOpen);

  const canAddDevice = useMemo(
    () =>
      selectedOrganisation?.owner_id === user?.id ||
      hasPermission(userOrganisationRoles, user?.id, "add"),
    [selectedOrganisation, user, userOrganisationRoles]
  );

  const pageTitle = useMemo(
    () =>
      selectedOrganisation?.name
        ? `${selectedOrganisation.name} ${t("devices.title")}`
        : t("devices.title"),
    [selectedOrganisation, t]
  );

  const toolbarProps = useToolbarProps({
    theme,
    pageTitle,
    devices,
    filter,
    setFilter,
    devicesPerPage,
    handleDevicesPerPageChange,
    devicesPerPageOptions,
    setAddDeviceOpen,
    handleRefresh,
    canAddDevice,
    isMobile,
  });

  const paginationProps = useMemo(
    () => ({
      currentPage,
      totalPages,
      onPageChange: setCurrentPage,
      theme,
      t,
      showAllPages: devices?.length > devicesPerPage,
    }),
    [
      currentPage,
      totalPages,
      setCurrentPage,
      theme,
      t,
      devices?.length,
      devicesPerPage,
    ]
  );

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DevicesToolbar {...toolbarProps} />
        </Grid>
      </Grid>

      <Box sx={{ mt: 2 }}>
        <Grid container spacing={2}>
          <Hero />
          {filteredAndPaginatedDevices?.map((device) => (
            <DeviceCard key={device.id} device={device} />
          ))}
          {devices.length > 0 && <DevicesPagination {...paginationProps} />}
          {noDevices && <AddDeviceCard setAddDeviceOpen={setAddDeviceOpen} />}
        </Grid>
      </Box>

      <AddDeviceDialog
        open={addDeviceOpen}
        handleClose={handleDeviceDialogClose}
      />
    </>
  );
};

export default withAuthenticationRequired(Devices);
