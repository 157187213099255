import { Box, Card, CardContent, Typography, useTheme } from "@mui/material";
import { color } from "d3-color";
import { interpolateRgb } from "d3-interpolate";
import React, { useEffect, useState } from "react";
import LiquidFillGauge from "react-liquid-gauge";
import { useSelector } from "react-redux";
import { Water } from "@mui/icons-material";

const Gauge = ({ radius = 150, value = 0, ...props }) => {
  const startColor = "#0061f2";
  const endColor = "#5BE12C";
  const interpolate = interpolateRgb(startColor, endColor);
  const fillColor = interpolate(value / 100);
  const gradientStops = [
    {
      key: "0%",
      stopColor: color(fillColor).darker(0.5).toString(),
      stopOpacity: 1,
      offset: "0%",
    },
    {
      key: "50%",
      stopColor: fillColor,
      stopOpacity: 0.75,
      offset: "50%",
    },
    {
      key: "100%",
      stopColor: color(fillColor).brighter(0.5).toString(),
      stopOpacity: 0.5,
      offset: "100%",
    },
  ];

  return (
    <LiquidFillGauge
      {...props}
      width={radius * 2}
      height={radius * 2}
      value={value}
      percent="%"
      textSize={1}
      textOffsetX={0}
      textOffsetY={0}
      textRenderer={({ value, width, height, textSize, percent }) => {
        value = Math.round(value);
        const radius = Math.min(height / 2, width / 2);
        const textPixels = (textSize * radius) / 2;
        const valueStyle = {
          fontSize: textPixels,
        };
        const percentStyle = {
          fontSize: textPixels * 0.6,
        };

        return (
          <tspan>
            <tspan className="value" style={valueStyle}>
              {value}
            </tspan>
            <tspan style={percentStyle}>{percent}</tspan>
          </tspan>
        );
      }}
      riseAnimation
      waveAnimation
      waveFrequency={2}
      waveAmplitude={1}
      gradient
      gradientStops={gradientStops}
      circleStyle={{
        fill: fillColor,
      }}
      waveStyle={{
        fill: fillColor,
      }}
      textStyle={{
        fill: color("#444").toString(),
        fontFamily: "Arial",
      }}
      waveTextStyle={{
        fill: color("#fff").toString(),
        fontFamily: "Arial",
      }}
    />
  );
};

export default function Fluid(props) {
  const [percentFull, setPercentFull] = useState(0);

  const deviceHistory = useSelector((state) => state.deviceHistory);
  const deviceProfile = useSelector((state) => state.deviceProfile);

  const theme = useTheme();

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (!deviceHistory || deviceHistory.length === 0) {
        return;
      }
      try {
        //get data
        let jsonData = deviceHistory[deviceHistory.length - 1].data;
        //parse json data
        jsonData = JSON.parse(jsonData);

        //if deviceProfile is not null get min and max
        if (deviceProfile && deviceProfile.length) {
          let dp = JSON.parse(deviceProfile);

          //use min as 100% and max as 0%
          let percentFull = 100 - (jsonData.distance / dp.distance.max) * 100;

          //set the gauge
          setPercentFull(percentFull);
        }
      } catch (error) {
        console.log(error);
      }
    }
    return () => (mounted = false);
  }, [deviceHistory, deviceProfile]);

  return (
    <>
      <Card
        elevation={0}
        sx={{
          height: "100%",
          background:
            theme.palette.mode === "dark"
              ? "linear-gradient(145deg, rgba(40,40,45,0.9) 0%, rgba(35,35,40,0.8) 100%)"
              : "linear-gradient(145deg, rgba(255,255,255,0.9) 0%, rgba(245,245,245,0.8) 100%)",
          backdropFilter: "blur(10px)",
          border: `1px solid ${
            theme.palette.mode === "dark"
              ? "rgba(255,255,255,0.1)"
              : "rgba(0,0,0,0.1)"
          }`,
          borderRadius: "16px",
        }}
      >
        <CardContent>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              mb: 2,
            }}
          >
            <Water />
            <Typography
              variant="subtitle2"
              sx={{
                color: theme.palette.text.secondary,
                letterSpacing: "0.02em",
                fontWeight: 500,
              }}
            >
              Liquid Level
            </Typography>
          </Box>
          <Box
            sx={{
              //flex center align
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Gauge value={percentFull} />
          </Box>
        </CardContent>
      </Card>
    </>
  );
}
