import React, { useEffect, useState } from "react";
import { formatDate } from "../../Functions/formatDate";
import {
  Box,
  Card,
  CardContent,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  ArrowDownward,
  ArrowUpward,
  TrackChangesOutlined,
} from "@mui/icons-material";
import AlertLogDialog from "../../components/Dialogs/AlertLog";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import DeviceInfoBatteryLevel from "../DeviceInfoBatteryLevel";
import { useTheme } from "@emotion/react";

export default function DeviceInfo(props) {
  const [alertLogDialogOpen, setAlertLogDialogOpen] = useState(false);
  const { t } = useTranslation("common");

  const isMobile = useSelector((state) => state.isMobile);
  const deviceAlerts = useSelector((state) => state.deviceAlerts);

  const device = useSelector((state) => state.device);
  const [hidePanel, setHidePanel] = useState(false);

  const [lastAlert, setLastAlert] = useState("");
  const theme = useTheme();

  const toggleHidePanel = () => {
    //use local storage
    if (localStorage.getItem("hidePanel") === "true") {
      localStorage.setItem("hidePanel", "false");
      setHidePanel(false);
    } else {
      localStorage.setItem("hidePanel", "true");
      setHidePanel(true);
    }
  };

  function toggleAlertLogDialogOpen() {
    setAlertLogDialogOpen(!alertLogDialogOpen);
  }

  useEffect(() => {
    if (deviceAlerts && deviceAlerts.length > 0) {
      setLastAlert(deviceAlerts[0]);
    }
  }, [deviceAlerts]);

  useEffect(() => {
    if (localStorage.getItem("hidePanel") === "true") {
      setHidePanel(true);
    } else {
      setHidePanel(false);
    }
  }, []);

  return (
    <>
      <div
        className={
          isMobile ? "last-checkin-panel-mobile" : "last-checkin-panel"
        }
      >
        <Card
          elevation={0}
          sx={{
            height: "100%",
            background:
              theme.palette.mode === "dark"
                ? "linear-gradient(145deg, rgba(40,40,45,0.9) 0%, rgba(35,35,40,0.8) 100%)"
                : "linear-gradient(145deg, rgba(255,255,255,0.9) 0%, rgba(245,245,245,0.8) 100%)",
            backdropFilter: "blur(10px)",
            border: `1px solid ${
              theme.palette.mode === "dark"
                ? "rgba(255,255,255,0.1)"
                : "rgba(0,0,0,0.1)"
            }`,
            borderRadius: "16px",
            transition:
              "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
            margin: 0,
            marginBottom: "6px",
          }}
        >
          <CardContent sx={{ mb: 0, p: 0, pb: "10px", pt: 1, px: 2 }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
              }}
            >
              <TrackChangesOutlined
                sx={{
                  color: theme.palette.text.secondary,
                  opacity: 0.8,
                }}
              />
              <Typography
                sx={{
                  color: theme.palette.text.secondary,
                  letterSpacing: "0.02em",
                  flexGrow: 1,
                  fontSize: "1rem",
                }}
              >
                Current Status
              </Typography>
              <IconButton
                onClick={() => {
                  toggleHidePanel();
                }}
              >
                {hidePanel ? <ArrowDownward /> : <ArrowUpward />}
              </IconButton>
            </Box>
            {!hidePanel && (
              <Stack direction="column" spacing={2}>
                <Typography
                  variant="body1"
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: "3",
                    WebkitBoxOrient: "vertical",
                    letterSpacing: "0.02em",
                    pt: 1,
                  }}
                >
                  {
                    //get last record of deviceHistory, get data, json parse it, if it has geocode then show it
                    device &&
                      device.last_data &&
                      device.last_data &&
                      JSON.parse(device.last_data).geocode && (
                        <>{JSON.parse(device.last_data).geocode}</>
                      )
                  }
                </Typography>
                <Box sx={{ display: "flex" }}>
                  <Typography
                    variant="body1"
                    sx={{
                      flexGrow: 1,
                      color: theme.palette.text.secondary,
                      letterSpacing: "0.02em",
                      fontSize: "0.875rem",
                    }}
                  >
                    <strong>{t("common.last_updated")}:</strong>
                  </Typography>
                  <Typography variant="body1">
                    {device.time_updated
                      ? formatDate(device.time_updated)
                      : t("common.no_data")}
                  </Typography>
                </Box>
                <DeviceInfoBatteryLevel device={device} />
                <Box sx={{ display: "flex" }}>
                  <Typography
                    variant="body1"
                    sx={{
                      flexGrow: 1,
                      color: theme.palette.text.secondary,
                      letterSpacing: "0.02em",
                      fontSize: "0.875rem",
                    }}
                  >
                    <strong>{t("devices.last_alert")}:</strong>
                  </Typography>
                  {lastAlert && lastAlert.message ? (
                    <Tooltip
                      title={lastAlert.message}
                      onClick={() => {
                        setAlertLogDialogOpen(true);
                      }}
                    >
                      <Typography
                        color="primary"
                        className="cursor-pointer"
                        sx={{ fontSize: "0.875rem" }}
                      >
                        {formatDate(lastAlert.time_created)}
                      </Typography>
                    </Tooltip>
                  ) : (
                    <Typography sx={{ fontSize: "0.875rem" }}>
                      {t("common.none")}.
                    </Typography>
                  )}
                </Box>
                {
                  //if deviceHistory is not empty, get data, json parse it, if it has event_status !== "" then show it
                  device &&
                    device.last_data &&
                    JSON.parse(device.last_data).event_status !== undefined && (
                      <Box sx={{ display: "flex" }}>
                        <Typography
                          variant="body1"
                          sx={{ flexGrow: 1, fontSize: "0.875rem" }}
                        >
                          <strong>{t("devices.event_status")}:</strong>
                        </Typography>
                        <Typography sx={{ fontSize: "0.875rem" }}>
                          {JSON.parse(device.last_data).event_status ||
                            "No Event."}
                        </Typography>
                      </Box>
                    )
                }
                {
                  //if deviceHistory is not empty, get data, json parse it, if it has event !== "" then show it
                  device &&
                    device.last_data &&
                    JSON.parse(device.last_data).event !== undefined && (
                      <Box sx={{ display: "flex" }}>
                        <Typography
                          variant="body1"
                          sx={{ fontSize: "0.875rem", flexGrow: 1 }}
                        >
                          <strong>{t("devices.event_status")}:</strong>
                        </Typography>
                        <Typography sx={{ fontSize: "0.875rem" }}>
                          {JSON.parse(device.last_data).event || "No Event."}
                        </Typography>
                      </Box>
                    )
                }
                {
                  //if deviceHistory is not empty, get data, json parse it, if it has isLost true or false then show it
                  device &&
                    device.last_data &&
                    JSON.parse(device.last_data).is_lost !== undefined && (
                      <Box sx={{ display: "flex" }}>
                        <Typography
                          variant="body1"
                          sx={{ fontSize: "0.875rem", flexGrow: 1 }}
                        >
                          <strong>{t("devices.is_lost")}:</strong>
                        </Typography>
                        <Typography sx={{ fontSize: "0.875rem" }}>
                          {JSON.parse(device.last_data).isLost
                            ? t("common.yes")
                            : t("common.no")}
                        </Typography>
                      </Box>
                    )
                }
                <Box sx={{ pt: "0" }} />
              </Stack>
            )}
          </CardContent>
        </Card>
      </div>
      <AlertLogDialog
        open={alertLogDialogOpen}
        handleClose={() => toggleAlertLogDialogOpen()}
        lastAlert={lastAlert}
      />
    </>
  );
}
