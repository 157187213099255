import React, { useMemo, useState, useEffect } from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { motion, AnimatePresence } from "framer-motion";

// Components
import DeviceHeader from "../components/DeviceHeader";
import DeviceContent from "../components/DeviceContent";
import DateTimePickerDialog from "../components/Dialogs/DateTimePicker";
import LoadingDevice from "../components/LoadingDevice";

// Hooks and Utils
import { useDeviceData } from "../hooks/useDeviceData";
import setTitle from "../Functions/setTitle";

// Constants
import { DEVICE_TYPE_COMPONENTS } from "../constants/deviceTypes";

const DEFAULT_REFRESH_INTERVAL = 5;

const Device = () => {
  const { id } = useParams();

  // Redux State
  const isMobile = useSelector((state) => state.isMobile);
  const device = useSelector((state) => state.device);
  const dateRange = useSelector((state) => state.dateRange);

  // Local State
  const [openDateTimePicker, setOpenDateTimePicker] = useState(false);
  const refresh_interval =
    localStorage.getItem("refresh_interval") || DEFAULT_REFRESH_INTERVAL;

  // Custom Hooks
  const { loading } = useDeviceData(id, dateRange, refresh_interval);

  // Memoized Values
  const DeviceComponent = useMemo(
    () => DEVICE_TYPE_COMPONENTS[device?.device_type] || null,
    [device?.device_type]
  );

  // Effects
  useEffect(() => {
    if (device?.name) {
      setTitle(device.name);
    }
  }, [device?.name]);

  if (loading) return null;

  return (
    <AnimatePresence mode="wait">
      {loading ? (
        <LoadingDevice />
      ) : (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
        >
          <DeviceHeader id={id} />
          <DeviceContent
            device={device}
            isMobile={isMobile}
            DeviceComponent={DeviceComponent}
          />
          <DateTimePickerDialog
            open={openDateTimePicker}
            onClose={() => setOpenDateTimePicker(false)}
          />
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default withAuthenticationRequired(Device);
