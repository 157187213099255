import React, { memo } from "react";
import { Button, Grid } from "@mui/material";

const DevicesPagination = memo(
  ({ currentPage, totalPages, onPageChange, theme, t, showAllPages }) => (
    <Grid item xs={12}>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="outlined"
          sx={{ mr: 1 }}
          color={theme.palette.mode === "dark" ? "primary" : "secondary"}
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          {t("common.previous")}
        </Button>

        {showAllPages ? (
          Array.from(Array(totalPages), (_, i) => (
            <Button
              key={i}
              sx={{ mr: 1 }}
              variant="outlined"
              color={theme.palette.mode === "dark" ? "primary" : "secondary"}
              onClick={() => onPageChange(i + 1)}
              disabled={currentPage === i + 1}
            >
              {i + 1}
            </Button>
          ))
        ) : (
          <Button
            variant="outlined"
            color={theme.palette.mode === "dark" ? "primary" : "secondary"}
            disabled
            sx={{ mr: 1 }}
          >
            {currentPage}
          </Button>
        )}

        <Button
          variant="outlined"
          color={theme.palette.mode === "dark" ? "primary" : "secondary"}
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          {t("common.next")}
        </Button>
      </div>
    </Grid>
  )
);

export default DevicesPagination;
