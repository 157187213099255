import React from 'react';
import { motion } from 'framer-motion';
import { Box, Skeleton } from '@mui/material';

const LoadingDevice = () => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ duration: 0.2 }}
  >
    <Box sx={{ p: 2 }}>
      <Skeleton variant="rectangular" height={60} sx={{ mb: 2 }} />
      <Skeleton variant="rectangular" height={200} sx={{ mb: 2 }} />
      <Box sx={{ display: 'flex', gap: 2 }}>
        <Skeleton variant="rectangular" height={100} width="50%" />
        <Skeleton variant="rectangular" height={100} width="50%" />
      </Box>
    </Box>
  </motion.div>
);

export default LoadingDevice;