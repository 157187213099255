import React, { useMemo } from "react";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import {
  AddAlarm,
  CorporateFare,
  Dashboard,
  Description,
  Fence,
  Person,
  Router,
  Settings,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { useTranslation } from "react-i18next";

function SidebarLayout({ collapsed }) {
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation("common");

  // Memoize menu styles to prevent recalculation on each render
  const menuItemStyles = useMemo(
    () => ({
      button: ({ level }) => {
        const baseStyle = {
          color:
            theme.palette.mode === "dark"
              ? theme.palette.primary.main
              : "#000000de",
          "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: "black",
          },
        };

        if (level === 0) {
          return {
            ...baseStyle,
            backgroundColor:
              theme.palette.mode === "dark" ? "#191B1F" : "lightgray",
          };
        }

        if (level === 1) {
          return {
            ...baseStyle,
            backgroundColor:
              theme.palette.mode === "dark" ? "#3b4049" : "#cccccc",
            minWidth: "400px",
          };
        }
      },
    }),
    [theme.palette.mode, theme.palette.primary.main]
  );

  // Memoize sidebar background color
  const sidebarBgColor = useMemo(
    () => (theme.palette.mode === "dark" ? "#191B1F" : "lightgray"),
    [theme.palette.mode]
  );

  // Define menu items configuration to reduce repetition
  const menuItems = useMemo(
    () => [
      { path: "/", icon: <Router />, label: "common.devices" },
      { path: "/action", icon: <AddAlarm />, label: "common.actions" },
      { path: "/geofence", icon: <Fence />, label: "common.geofences" },
      { path: "/dashboard", icon: <Dashboard />, label: "common.dashboards" },
      { path: "/report", icon: <Description />, label: "common.reports" },
      { path: "/contact", icon: <Person />, label: "common.contacts" },
      {
        path: "/organisation",
        icon: <CorporateFare />,
        label: "common.organisations",
      },
      { path: "/account", icon: <Settings />, label: "common.account" },
    ],
    []
  );

  return (
    <Sidebar
      collapsed={collapsed}
      className="app"
      backgroundColor={sidebarBgColor}
    >
      <Menu menuItemStyles={menuItemStyles}>
        {menuItems.map((item, index) => (
          <MenuItem
            key={"sidebar" + index}
            onClick={() => navigate(item.path)}
            icon={item.icon}
          >
            {t(item.label)}
          </MenuItem>
        ))}
      </Menu>
    </Sidebar>
  );
}

export default React.memo(SidebarLayout);
