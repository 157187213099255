import { Grid } from "@mui/material";
import React from "react";
import LastSeen from "../../Cards/LastSeen";
import BatteryStatus from "../../Cards/BatteryStatus";
import LastAlert from "../../Cards/LastAlert";
import TemperatureGuague from "../../Guages/Temperature";
import DeviceActionLogs from "../../Cards/Alerts";
import FrameList from "../../FrameList";
import GraphBase from "../../Graphs/GraphBase";
import HumidityGuague from "../../Guages/Humidity";
import formatTemperature from "../../../Functions/formatTemperature";

export default function Harmony(props) {
  let { isShare } = props;

  /*
      moisture: float = None
    temperature: float = None
    a_moisture: float = None
    b_moisture: float = None
    voltageMv: int = None
    */

  return (
    <>
      <Grid
        container
        spacing={2}
        alignItems={"stretch"}
        alignContent={"stretch"}
        sx={{ mt: 1 }}
      >
        <Grid item xs={12} md={!isShare ? 4 : 6} sx={{ mb: 2 }}>
          <LastSeen />
        </Grid>
        <Grid item xs={12} md={!isShare ? 4 : 6} sx={{ mb: 2 }}>
          <BatteryStatus />
        </Grid>
        {!isShare && (
          <Grid item xs={12} md={4} sx={{ mb: 2 }}>
            <LastAlert />
          </Grid>
        )}

        <Grid item xs={12} md={3} sx={{ mb: 2 }}>
          <HumidityGuague field="moisture" title="Moisture" />
        </Grid>

        <Grid item xs={12} md={9} sx={{ mb: 2 }}>
          <GraphBase
            value="moisture"
            title="Moisture"
            unit="%"
            min="0"
            max="100"
            aspect={3.5}
          />
        </Grid>

        <Grid item xs={12} md={3} sx={{ mb: 2 }}>
          <TemperatureGuague field="temperature" title="Temperature" />
        </Grid>
        <Grid item xs={12} md={9} sx={{ mb: 2 }}>
          <GraphBase
            title="Temperature"
            value="temperature"
            formatter={formatTemperature}
            graphFormatter={formatTemperature}
            aspect={3.5}
            type="temperature"
          />
        </Grid>

        <Grid item xs={12} md={isShare ? 12 : 8} sx={{ mb: 2 }}>
          <FrameList />
        </Grid>

        {!isShare && (
          <>
            <Grid item xs={12} md={4}>
              <DeviceActionLogs />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}
