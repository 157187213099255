import React, { memo } from "react";
import { Stack, TextField, Button, Tooltip, MenuItem } from "@mui/material";
import { Add, Refresh, Search } from "@mui/icons-material";
import PageTitle from "./PageTitle";

const DevicesToolbar = memo(
  ({
    theme,
    title,
    subTitle,
    filter,
    onFilterChange,
    devicesPerPage,
    onDevicesPerPageChange,
    devicesPerPageOptions,
    onAddDevice,
    onRefresh,
    canAddDevice,
    isMobile,
    showControls,
  }) => (
    <Stack direction="row" alignItems="center" sx={{ mt: 2, mb: 2 }}>
      <PageTitle title={title} theme={theme} subTitle={subTitle} />

      {showControls && (
        <>
          <TextField
            label="Search Devices"
            value={filter}
            onChange={(e) => onFilterChange(e.target.value)}
            sx={{ mr: 1, width: "400px" }}
            InputProps={{
              startAdornment: (
                <Search
                  sx={{
                    color:
                      theme.palette.mode === "dark" ? "primary" : "secondary",
                  }}
                />
              ),
            }}
          />
          <TextField
            label="Devices Per Page"
            value={devicesPerPage}
            onChange={(e) => onDevicesPerPageChange(e.target.value)}
            sx={{ minWidth: "100px", mr: 1 }}
            select
          >
            {devicesPerPageOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </>
      )}

      {canAddDevice && (
        <Tooltip title="Add a Device" placement="top" arrow>
          <Button
            variant="outlined"
            color={theme.palette.mode === "dark" ? "primary" : "secondary"}
            sx={{ mr: 1, padding: 2 }}
            onClick={onAddDevice}
          >
            <Add />
          </Button>
        </Tooltip>
      )}

      <Tooltip title="Refresh Devices" placement="top" arrow>
        <Button
          variant="outlined"
          color={theme.palette.mode === "dark" ? "primary" : "secondary"}
          sx={{ padding: 2 }}
          onClick={onRefresh}
        >
          <Refresh />
        </Button>
      </Tooltip>
    </Stack>
  )
);

export default DevicesToolbar;
