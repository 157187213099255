import { useTheme } from "@emotion/react";
import React, { useEffect } from "react";
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";
import { useSelector } from "react-redux";
import { formatDate, formatDateOnly } from "../../Functions/formatDate";
import { Card, CardContent, CardHeader } from "@mui/material";
import formatTemperature from "../../Functions/formatTemperature";

export default function WaterMinMaxAmbinetGraph(props) {
  const [history, setHistory] = React.useState([]);
  const [min, setMin] = React.useState(0);
  const [max, setMax] = React.useState(0);

  const isMobile = useSelector((state) => state.isMobile);

  const deviceHistory = useSelector((state) => state.deviceHistory);
  const theme = useTheme();

  useEffect(() => {
    let tempHistory = [];
    deviceHistory.forEach((dataObject) => {
      let jsonData = dataObject.data;
      jsonData = JSON.parse(jsonData);
      let time_created = dataObject.time_created;

      //roudn to 1 decimal place
      let minLookup = Math.round(jsonData.water_temp_min * 10) / 10;
      let maxLookup = Math.round(jsonData.water_temp_max * 10) / 10;
      let ambientLookup = Math.round(jsonData.ambient_temp * 10) / 10;

      if (minLookup < min) {
        setMin(jsonData.min);
      }

      if (min === 0) {
        setMin(jsonData.min);
      }

      if (maxLookup > max) {
        setMax(jsonData.max);
      }

      tempHistory.push({
        date: time_created,
        min: minLookup,
        max: maxLookup,
        ambient: ambientLookup,
      });
    });
    setHistory(tempHistory);

    return () => {
      setHistory([]);
    };
  }, [deviceHistory, max, min]);

  return (
    <Card sx={{ height: "100%" }}>
      <CardHeader
        title={"Temperature History"}
        titleTypographyProps={{
          variant: "h5",
          color: theme.palette.mode === "light" ? "#000" : "#616161",
        }}
      />
      <CardContent>
        <ResponsiveContainer aspect={isMobile ? 2 : 5}>
          <AreaChart
            data={history}
            margin={{ top: 0, right: 30, left: -15, bottom: 0 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <defs>
              <linearGradient id="min" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={"blue"} stopOpacity={0.8} />
                <stop offset="95%" stopColor={"blue"} stopOpacity={0} />
              </linearGradient>
              <linearGradient id="max" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={"green"} stopOpacity={0.8} />
                <stop offset="95%" stopColor={"green"} stopOpacity={0} />
              </linearGradient>
              <linearGradient id="ambient" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={"red"} stopOpacity={0.8} />
                <stop offset="95%" stopColor={"red"} stopOpacity={0} />
              </linearGradient>
            </defs>
            <XAxis
              dataKey="date"
              type={"category"} //format date to be more readable
              tickFormatter={(value) => {
                return formatDateOnly(value);
              }}
            />
            <YAxis yAxisId="1" y1={min} y2={max} domain={[min, max]} />

            <Tooltip
              contentStyle={{
                backgroundColor: theme.palette.background.default,
              }}
              cursor={false}
              labelFormatter={(label) => {
                return formatDate(label);
              }}
              //format label to capitalize first letter of each word and format value to use celsius symbol and format date

              formatter={(value, name) => {
                return [
                  formatTemperature(value),
                  name === "min" ? "Min" : name === "max" ? "Max" : "Ambient",
                ];
              }}
            />
            <Area
              yAxisId="1"
              dataKey="ambient"
              stroke="red"
              animationDuration={300}
              fill="url(#ambient)"
              strokeWidth={2}
              dominantBaseline={"middle"}
            />
            <Area
              yAxisId="1"
              dataKey="min"
              stroke="blue"
              animationDuration={300}
              fill="url(#min)"
              strokeWidth={2}
              dominantBaseline={"middle"}
            />
            <Area
              yAxisId="1"
              dataKey="max"
              stroke={"green"}
              animationDuration={300}
              fill="url(#max)"
              strokeWidth={2}
              dominantBaseline={"middle"}
            />
          </AreaChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
}
