import { putData } from "../API/Put";

const changeDeviceName = async (device_id, device_name) => {
  try {
    let response = putData(`/device/${device_id}/name`, { name: device_name });
    return response;
  } catch (error) {
    console.log(error);
  }
};

export default changeDeviceName;
