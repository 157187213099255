import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import setTitle from "../Functions/setTitle";

export const useDevicesState = (devices, selectedOrganisation) => {
  const [noDevices, setNoDevices] = useState(false);
  const [addDeviceOpen, setAddDeviceOpen] = useState(false);
  const { t } = useTranslation("common");

  useEffect(() => {
    setNoDevices(!devices?.length);

    if (selectedOrganisation?.name !== undefined) {
      setTitle(t("devices.title"), selectedOrganisation);
    }
  }, [devices, selectedOrganisation, t]);

  return {
    noDevices,
    addDeviceOpen,
    setAddDeviceOpen,
  };
};
