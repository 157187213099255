import { useState, useEffect } from "react";

const XL_BREAKPOINT = 2048;
const DEVICE_COUNT_MAP = {
  xl: { 10: "9", 20: "18", 50: "36", 100: "72" },
  regular: { 9: "10", 18: "20", 36: "50", 72: "100" },
};

export const useScreenSize = () => {
  const [isXL, setIsXL] = useState(() => window.innerWidth > XL_BREAKPOINT);
  const [devicesPerPageOptions, setDevicesPerPageOptions] = useState([
    10, 20, 50, 100,
  ]);

  useEffect(() => {
    const handleResize = () => setIsXL(window.innerWidth > XL_BREAKPOINT);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const options = isXL ? [9, 18, 36, 72] : [10, 20, 50, 100];
    setDevicesPerPageOptions(options);
  }, [isXL]);

  const convertDevicesPerPage = (value) => {
    const map = isXL ? DEVICE_COUNT_MAP.xl : DEVICE_COUNT_MAP.regular;
    return map[value] || value;
  };

  return { isXL, devicesPerPageOptions, convertDevicesPerPage };
};
