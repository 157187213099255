import { useTheme } from "@emotion/react";
import React, { useEffect } from "react";
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";
import { formatDate, formatDateOnly } from "../../Functions/formatDate";
import fetchData from "../../API/Fetch";
import WidgetCard from "./WidgetCard";
import moment from "moment";

export default function GraphWidget(props) {
  //const devices = useSelector((state) => state.devices);
  const { widget, isShare, editMode, dashboardId, refreshDashboard } = props;
  const theme = useTheme();

  const [title, setTitle] = React.useState("");
  const [values, setValues] = React.useState({});
  const [history, setHistory] = React.useState({});
  // const [date, setDate] = React.useState("N/A");

  const days = 7;

  const colours = [
    //red, green blue,  purple, orange, pink, teal, brown, grey
    theme.palette.primary.main,
    "#FF0000",
    "#008000",
    "#0000FF",

    "#800080",
    "#FFA500",
    "#FFC0CB",
    "#008080",
    "#A52A2A",
  ];

  useEffect(() => {
    async function fetchValues() {
      if (widget && widget.field) {
        let field = JSON.parse(widget.field);
        setTitle(field["Title"]);
        let valueFields = field["Values"] || [];

        if (!valueFields || !valueFields.length) return;

        //each valueFields will be an id and a column, get all the new id's in a list

        let deviceIds = valueFields.map((vf) => {
          return vf.split(",")[0];
        });
        deviceIds = [...new Set(deviceIds)];

        try {
          const historyData = await Promise.all(
            deviceIds.map((deviceId) =>
              fetchData(`/frame/days/${deviceId}/${days}`)
            )
          );

          //now, only take the values that are in the valueFields
          let newHistoryData = {};

          valueFields.forEach((vf) => {
            let deviceId = vf.split(",")[0];
            let column = vf.split(",")[1];
            newHistoryData[column] = [];
            historyData.forEach((data) => {
              if (data && data.length) {
                data.forEach((d) => {
                  if (d && d.data) {
                    let jsonData = JSON.parse(d.data);
                    if (jsonData[column]) {
                      newHistoryData[column].push({
                        Date: d.time_created,
                        [column]: jsonData[column],
                      });
                    }
                  }
                });
              }
            });
          });

          //merge and flattern the data so its one date and each field in an array
          let mergedData = [];

          newHistoryData[Object.keys(newHistoryData)[0]].forEach((d) => {
            let newD = {
              Date: d.Date,
            };
            Object.keys(newHistoryData).forEach((key) => {
              newD[key] = newHistoryData[key].find((d2) => {
                return d2.Date === d.Date;
              })[key];
            });

            mergedData.push(newD);
          });

          setValues(
            valueFields.map((vf) => {
              let deviceId = vf.split(",")[0];
              let column = vf.split(",")[1];
              return {
                value: column,
              };
            })
          );
          setHistory(mergedData);
        } catch (error) {
          console.error("Error fetching history data:", error);
        }
      }
    }

    fetchValues();
  }, [widget]);

  return (
    <WidgetCard
      widget={widget}
      refreshDashboard={refreshDashboard}
      editMode={editMode}
      isShare={isShare}
      dashboardId={dashboardId}
      title={title}
      date={moment().toISOString()}
    >
      {
        <ResponsiveContainer width="100%" aspect={2}>
          <AreaChart
            data={history}
            margin={{ top: 0, right: 30, left: 0, bottom: 0 }}
          >
            <CartesianGrid
              strokeDasharray="3 3"
              stroke={
                theme.palette.mode === "dark"
                  ? "rgba(255,255,255,0.1)"
                  : "rgba(0,0,0,0.1)"
              }
            />
            <defs>
              {values &&
                values.length &&
                values.map((value, index) => (
                  <linearGradient
                    key={index}
                    id={`color${index}`}
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="1"
                  >
                    <stop
                      offset="5%"
                      stopColor={colours[index]}
                      stopOpacity={0.8}
                    />
                    <stop
                      offset="95%"
                      stopColor={colours[index]}
                      stopOpacity={0.4}
                    />
                  </linearGradient>
                ))}
            </defs>
            <XAxis
              dataKey="Date"
              type={"category"}
              tickFormatter={(value) => {
                return formatDateOnly(value);
              }}
            />
            <YAxis type="number" allowDataOverflow={true} yAxisId={1} />
            <Tooltip
              contentStyle={{
                backgroundColor: theme.palette.background.default,
              }}
              cursor={false}
              labelFormatter={(label) => {
                return formatDate(label);
              }}
            />
            {values &&
              values.length &&
              values.map((value, index) => (
                <Area
                  key={index}
                  yAxisId={1}
                  dataKey={value.value}
                  stroke={colours[index]}
                  animationDuration={300}
                  fill={`url(#color${index})`}
                  strokeWidth={2}
                  dominantBaseline={"middle"}
                />
              ))}
          </AreaChart>
        </ResponsiveContainer>
      }
    </WidgetCard>
  );
}
