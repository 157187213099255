import React from "react";
import { Grid } from "@mui/material";
import LastAlert from "../Cards/LastAlert";
import BatteryStatus from "../Cards/BatteryStatus";
import LastSeen from "../Cards/LastSeen";
import DeviceActionLogs from "../Cards/Alerts";
import TemperatureGuague from "../Guages/Temperature";
import DoorOpenCard from "../Cards/DoorOpen";
import FrameList from "../FrameList";
import formatTemperature from "../../Functions/formatTemperature";
import GraphBase from "../Graphs/GraphBase";

export default function DoorWindowWithTemp(props) {
  let { isShare } = props;

  return (
    <>
      <Grid
        container
        spacing={2}
        alignItems={"stretch"}
        alignContent={"stretch"}
        sx={{ mt: 1 }}
      >
        <Grid item xs={12} md={!isShare ? 4 : 6} sx={{ mb: 2 }}>
          <LastSeen />
        </Grid>
        <Grid item xs={12} md={!isShare ? 4 : 6} sx={{ mb: 2 }}>
          <BatteryStatus />
        </Grid>
        {!isShare && (
          <Grid item xs={12} md={4} sx={{ mb: 2 }}>
            <LastAlert />
          </Grid>
        )}

        <Grid item xs={12} md={3} sx={{ mb: 2 }}>
          <DoorOpenCard />
        </Grid>

        <Grid item xs={12} md={9} sx={{ mb: 2 }}>
          <GraphBase
            title="Door Open"
            value="door_open"
            truefalse={true}
            trueV="Open"
            falseV="Closed"
          />
        </Grid>

        <Grid item xs={12} md={3} sx={{ mb: 2 }}>
          <TemperatureGuague />
        </Grid>
        <Grid item xs={12} md={9} sx={{ mb: 2 }}>
          <GraphBase
            title="Temperature"
            value="temperature"
            formatter={formatTemperature}
            graphFormatter={formatTemperature}
          />
        </Grid>

        <Grid item xs={12} md={!isShare ? 7 : 12} sx={{ mb: 2 }}>
          <FrameList isShare={isShare} />
        </Grid>

        {!isShare && (
          <>
            <Grid item xs={12} md={5} sx={{ mb: 2 }}>
              <DeviceActionLogs />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}
