import { useTheme } from "@emotion/react";
import { Box, Card, CardContent, Typography } from "@mui/material";
import React, { useEffect } from "react";
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";
import { useSelector } from "react-redux";
import { formatDate, formatDateOnly } from "../../Functions/formatDate";
import formatTemperature from "../../Functions/formatTemperature";
import formatSpeed from "../../Functions/formatSpeed";
import { Equalizer } from "@mui/icons-material";

export default function MultiGraphBase(props) {
  const [history, setHistory] = React.useState([]);

  const {
    values,
    value_titles,
    title,
    unit = "",
    min,
    max,
    type,
    trimValue = false,
    truefalse,
    useIsXL = true,
    trueV = "True",
    falseV = "False",
    trimNumber = 1,
    aspect = 10,
    formatter = null,
    graphValueFormatter = (value) => value,
  } = props;

  const deviceHistory = useSelector((state) => state.deviceHistory);
  const isMobile = useSelector((state) => state.isMobile);
  const theme = useTheme();

  useEffect(() => {
    if (!deviceHistory || deviceHistory.length === 0) {
      return;
    }

    try {
      //grab all data objects from deviceHistory, parse them, and add them to history
      let tempHistory = [];
      deviceHistory.forEach((dataObject) => {
        let jsonData = dataObject.data;
        jsonData = JSON.parse(jsonData);
        let time_created = dataObject.time_created;

        let deviceFrame = {
          Date: time_created,
        };

        values.forEach((field) => {
          let fieldValue = jsonData[field.value];

          if (Array.isArray(fieldValue)) {
            fieldValue = fieldValue[0];
          }

          if (
            fieldValue !== undefined &&
            fieldValue !== null &&
            fieldValue !== ""
          ) {
            if (trimValue && typeof fieldValue === "string") {
              fieldValue = Number(fieldValue.slice(0, -trimNumber));
            }

            if (type === "temperature") {
              fieldValue = formatTemperature(fieldValue, true);
            }

            if (field.value === "speedKmph") {
              fieldValue = formatSpeed(fieldValue, false);
            }

            deviceFrame[field.value] = fieldValue;
          }
        });

        tempHistory.push(deviceFrame);
      });

      setHistory(tempHistory);
    } catch (error) {
      console.log(error);
    }
  }, [
    deviceHistory,
    values,
    value_titles,
    useIsXL,
    min,
    max,
    type,
    trimValue,
    truefalse,
    trueV,
    falseV,
    trimNumber,
    formatter,
    graphValueFormatter,
  ]);

  const colours = [
    //red, green blue,  purple, orange, pink, teal, brown, grey
    theme.palette.primary.main,
    "#FF0000",
    "#008000",
    "#0000FF",

    "#800080",
    "#FFA500",
    "#FFC0CB",
    "#008080",
    "#A52A2A",
  ];

  return (
    <Card
      elevation={0}
      sx={{
        height: "100%",
        background:
          theme.palette.mode === "dark"
            ? "linear-gradient(145deg, rgba(40,40,45,0.9) 0%, rgba(35,35,40,0.8) 100%)"
            : "linear-gradient(145deg, rgba(255,255,255,0.9) 0%, rgba(245,245,245,0.8) 100%)",
        backdropFilter: "blur(10px)",
        border: `1px solid ${
          theme.palette.mode === "dark"
            ? "rgba(255,255,255,0.1)"
            : "rgba(0,0,0,0.1)"
        }`,
        borderRadius: "16px",
      }}
    >
      <CardContent>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            mb: 2,
          }}
        >
          <Equalizer
            sx={{
              color: theme.palette.text.secondary,
              opacity: 0.8,
            }}
          />
          <Typography
            variant="subtitle2"
            sx={{
              color: theme.palette.text.secondary,
              letterSpacing: "0.02em",
              fontWeight: 500,
            }}
          >
            {title + " History"}
          </Typography>
        </Box>
        <ResponsiveContainer width="100%" aspect={!isMobile ? aspect : 2}>
          <AreaChart
            data={history}
            margin={{ top: 0, right: 30, left: 0, bottom: 0 }}
          >
            <CartesianGrid
              strokeDasharray="3 3"
              stroke={
                theme.palette.mode === "dark"
                  ? "rgba(255,255,255,0.1)"
                  : "rgba(0,0,0,0.1)"
              }
            />
            <defs>
              {values.map((value, index) => (
                <linearGradient
                  key={index}
                  id={`color${index}`}
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="1"
                >
                  <stop
                    offset="5%"
                    stopColor={colours[index]}
                    stopOpacity={0.8}
                  />
                  <stop
                    offset="95%"
                    stopColor={colours[index]}
                    stopOpacity={0.4}
                  />
                </linearGradient>
              ))}
            </defs>
            <XAxis
              dataKey="Date"
              type={"category"}
              tickFormatter={(value) => {
                return formatDateOnly(value);
              }}
            />
            <YAxis
              type="number"
              domain={[min, max]}
              allowDataOverflow={true}
              yAxisId={1}
            />
            <Tooltip
              contentStyle={{
                backgroundColor: theme.palette.background.default,
              }}
              cursor={false}
              labelFormatter={(label) => {
                return formatDate(label);
              }}
              formatter={(value, name) => {
                name = values.find((v) => v.value === name).title;
                return [value + unit, name];
              }}
            />
            {values.map((value, index) => (
              <Area
                key={index}
                yAxisId={1}
                dataKey={value.value}
                stroke={colours[index]}
                animationDuration={300}
                fill={`url(#color${index})`}
                strokeWidth={2}
                dominantBaseline={"middle"}
              />
            ))}
          </AreaChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
}
