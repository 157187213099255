import React from "react";
import { Grid } from "@mui/material";
import LastAlert from "../Cards/LastAlert";
import BatteryStatus from "../Cards/BatteryStatus";
import LastSeen from "../Cards/LastSeen";
import DeviceActionLogs from "../Cards/Alerts";
import CountCard from "../Cards/count";
import PeopleCounterGraph from "../Graphs/PeopleCounter";
import FrameList from "../FrameList";

export default function PeopleCounter(props) {
  let { isShare } = props;

  return (
    <>
      <Grid
        container
        spacing={2}
        alignItems={"stretch"}
        alignContent={"stretch"}
        sx={{ mt: 1 }}
      >
        <Grid item xs={12} md={!isShare ? 4 : 6} sx={{ mb: 2 }}>
          <LastSeen />
        </Grid>
        <Grid item xs={12} md={!isShare ? 4 : 6} sx={{ mb: 2 }}>
          <BatteryStatus />
        </Grid>
        {!isShare && (
          <Grid item xs={12} md={4} sx={{ mb: 2 }}>
            <LastAlert />
          </Grid>
        )}
        <Grid item xs={12} md={3} sx={{ mb: 2 }}>
          <CountCard />
        </Grid>

        <Grid item xs={12} md={9} sx={{ mb: 2 }}>
          <PeopleCounterGraph />
        </Grid>

        <Grid item xs={12} md={!isShare ? 7 : 12} sx={{ mb: 2 }}>
          <FrameList />
        </Grid>

        {!isShare && (
          <Grid item xs={12} md={5}>
            <DeviceActionLogs />
          </Grid>
        )}
      </Grid>
    </>
  );
}
