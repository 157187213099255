import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import DeviceMetric from "./DeviceMetric";

export default function IsLostCard() {
  const deviceHistory = useSelector((state) => state.deviceHistory);
  const [isLost, setIsLost] = React.useState("No Event");

  useEffect(() => {
    if (!deviceHistory || deviceHistory.length === 0) {
      return;
    }

    try {
      let lastData = deviceHistory[deviceHistory.length - 1];
      let jsonData = lastData.data;
      jsonData = JSON.parse(jsonData);
      setIsLost(jsonData.isLost);
    } catch (error) {
      console.log(error);
    }
  }, [deviceHistory]);

  return (
    <DeviceMetric
      title="Is Lost?"
      value={isLost !== "" ? (isLost === true ? "True" : "False") : "No Status"}
      trueFalse={true}
      trueValue="True"
      falseValue="False"
      icon="paw"
    />
  );
}
