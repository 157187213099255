import GaugeBase from "./GaugeBase";
import { WaterDrop } from "@mui/icons-material";
import { useTheme } from "@emotion/react";

export default function SoilMoistureGuague() {
  const defaultArch = [
    {
      limit: 40,
      color: "#EA4228",
    },

    {
      limit: 50,
      color: "#F5CD19",
    },

    {
      limit: 80,
      color: "#5BE12C",
    },
    {
      limit: 90,
      color: "#F5CD19",
    },
    {
      limit: 100,
      color: "#EA4228",
    },
  ];

  const theme = useTheme();

  return (
    <GaugeBase
      title="Soil Moisture"
      field="soil_moisture"
      icon={
        <WaterDrop
          sx={{
            color: theme.palette.text.secondary,
            opacity: 0.8,
          }}
        />
      }
      defaultArch={defaultArch}
      min={0}
      max={100}
      unit="%"
    />
  );
}
