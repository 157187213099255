import { Card, Tooltip, Typography, useTheme } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

export default function DeviceCardPanel(props) {
  const isMobile = useSelector((state) => state.isMobile);
  const { title, content } = props;

  const theme = useTheme();
  return (
    <Tooltip
      sx={{ height: "100%" }}
      title={title + ": " + content}
      position="top"
      arrow
    >
      <Card
        sx={{
          backgroundColor:
            theme.palette.mode === "dark" ? "#1f1f1f" : "#f0f0f0",
          p: 1,
          mb: 2,
        }}
      >
        <Typography
          variant="body1"
          sx={{
            color: theme.palette.text.secondary,
            letterSpacing: "0.02em",
            fontSize: "0.7rem",
          }}
        >
          {title}
        </Typography>

        <Typography
          variant="p"
          noWrap={isMobile ? false : true}
          sx={{
            fontSize: "1rem",
            mt: 2,
            pr: 1,
            color: "textSecondary",
            fontWeight: 500,
          }}
        >
          {content}
        </Typography>
      </Card>
    </Tooltip>
  );
}
